import React, { useContext, useEffect, useMemo, useRef } from 'react';

import Controls from './Controls';
import { useThreeDStreaming } from './threeDStreaming';
import H3 from '../common/texts/H3';
import { ThreeDStateContext } from '../../store/threeDState/ThreeDStateProvider';
import { t } from '../../utils';
import { ThreeDStateAction } from '../../store/threeDState/interfaces';
import Paper from '../common/Paper/Paper';
import { P } from '../common/texts';

import { useOutsideClickListener } from '../../hooks/useOutsideClickListener';
import { GlobalStateContext } from '../../store/globalState/GlobalStateProvider';
import { GlobalStateAction } from '../../store/globalState/interfaces';
import HubspotContactForm from '../HubSpotComponents/HubSpotContactForm';
import { ThreeDContainer, Video } from './threeDStream.styles';
import { OptionsMenu } from '../OptionsMenu/OptionsMenu';
import { PoweredByLabel } from './PoweredByLabel';
import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import Instructions from '../Tutorial/Instructions/Instructions';
import {LoadingInfoBar, TopInfoBar} from './SnackBar';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import useEventLogger from '../../analytics/useEventLogger';
import DecorationControls from "../Decorations/DecorationControls";
import ContentMenu from "../OptionsMenu/ContentMenu";

const usePlayVideo = (videoElement: HTMLVideoElement | null, videoSrc: MediaStream | null) => {
  useEffect(() => {
    if (videoElement && videoSrc) {
      videoElement.srcObject = videoSrc;
      videoElement.play();
    }
  }, [videoSrc, videoElement]);
};

interface ThreeDStreamProps {
  children?: React.ReactNode;
  isRunningInIframe: boolean;
}

const ThreeDStream: React.FC<ThreeDStreamProps> = ({ children, isRunningInIframe }) => {
  const { logActions } = useEventLogger();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { globalState, dispatch: globalStateDispatch } = useContext(GlobalStateContext);
  const { threeDState, dispatch } = useContext(ThreeDStateContext);
  const {
    videoSrc,
    move,
    apartmentReady,
    loadingMessage,
    amountOfMaterials,
    currentMaterialNbr,
    errorMessage,
    resetCamera,
    selectTheme,
    previewMaterial,
    showControls,
    floors,
    isLayoutView,
    selectFloor,
    takePicture,
    saveDecorations,
    createNewLayout,
    rotateDecoration,
    sendPosition
  } = useThreeDStreaming(videoRef, () => {
    dispatch({
      type: ThreeDStateAction.RESET_STORE
    });
    globalStateDispatch({
      type: GlobalStateAction.SET_HAS_THREE_D_STARTED_ACTION,
      data: false
    });
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const SHOW_HUBSPOT_FORM = Boolean(process.env.REACT_APP_THREE_D_SHOWHUBSPOTFORM === "true");

  const HUBSPOTFORM_TIMEOUT = Number(process.env.REACT_APP_THREE_D_HUBSPOTFORM_TIMEOUT || null);

  const startHubSpotTimer = () => {
    const hubspotTimerID = setTimeout(showHubSpotForm, HUBSPOTFORM_TIMEOUT);
  };

  const showHubSpotForm = () => {
    dispatch({
      type: ThreeDStateAction.SET_OPEN_HUBSPOTFORM,
      data: true
    });
  };

  const handleDismissInstructions = (lastStep: boolean) => {
    if (lastStep) {
      logActions('tutorial_finished', 'Tutorial Finished', 'tutorial_1');
    } else {
      logActions('tutorial_skipped', 'Tutorial skipped', 'tutorial_2');
    }
    logActions(
      'apartment_layout_visible',
      'Apartment Layout Visible after tutorial',
      'apartmentLayout_1'
    );
    window.dispatchEvent(new Event('instructionsClosed'));
    dispatch({ type: ThreeDStateAction.DISMISS_INSTRUCTIONS });
  };

  /* Commented out the activation of hubspot form, as this was done for demo only. Will be taken into use at some point,
     after implementing the form modal and sending its data to hubspot API instead of using the embedded form 
     from hubspot url */
  useEffect(() => {
    if (SHOW_HUBSPOT_FORM && threeDState.instructionsDismissed) {
      if (!globalState.token) {
        startHubSpotTimer();
      }
    }
  }, [threeDState.instructionsDismissed]);

  usePlayVideo(videoRef.current, videoSrc);

  const themesAndMaterialsClickListenerRef = useRef<HTMLDivElement | null>(null);

  useOutsideClickListener(themesAndMaterialsClickListenerRef, () => {
    if (threeDState.themesAndMaterialsOpen)
      dispatch({
        type: ThreeDStateAction.TOGGLE_THEMES_AND_MATERIALS_OPEN
      });
  });

  const showThemes =
    !globalState.tenantSettings.gb4dUxModes ||
    globalState.tenantSettings.gb4dUxModes.some((mode) => mode.showThemes);
  const showMaterials =
    !globalState.tenantSettings.gb4dUxModes ||
    globalState.tenantSettings.gb4dUxModes.some((mode) => mode.showMaterials);

  const themesAndMaterialsEnabled = showThemes || (showMaterials && globalState.token) || globalState.tenantSettings.gb4dEnableDecorating;

  const stylesAndMaterialsButtonRef = useRef<HTMLButtonElement | null>(null);
  //const materialsLoading = amountOfMaterials !== currentMaterialNbr;
  //const showLoadingMessage = loadingMessage;
  const instructionsDismissed = threeDState.instructionsDismissed;
  const topViewText = globalState.startParameters.isMobile
    ? t('instructions.topViewMobile')
    : t('instructions.topViewDesktop');

  // useMemo here because the component listens to a lot of state/context changes
  return useMemo(
    () => (
      <ThreeDContainer>
        <Video
          autoPlay
          playsInline
          apartmentReady={apartmentReady}
          instructionsDismissed={instructionsDismissed}
          ref={videoRef}
        />
        <DecorationControls rotateDecoration={rotateDecoration} moveDecoration={sendPosition}/>
        {!isSmallScreen && (
          <>
            {globalState.tenantSettings.gb4dShowBranding && (
              <PoweredByLabel showIndicativeText={true} />
            )}
            {!isRunningInIframe && <LanguageSelect />}
          </>
        )}
        {apartmentReady && !threeDState.openHubSpotForm && (
          <>
            {move && (
              <>
                {!isSmallScreen && globalState.tenantSettings.gb4dShowBranding && (
                  <PoweredByLabel showIndicativeText={true} />
                )}
                <Controls
                  onHold={move}
                  showControls={showControls}
                  inProgress={threeDState.downloadingPicture}
                  {...{
                    takePicture,
                    resetCamera,
                    floors,
                    isLayoutView,
                    selectFloor,
                    isSmallScreen,
                    instructionsDismissed
                  }}
                />
              </>
            )}
            {showControls && themesAndMaterialsEnabled && (<ContentMenu {...{ selectTheme, previewMaterial, saveDecorations, createNewLayout  }}/>
              //<OptionsMenu {...{ stylesAndMaterialsButtonRef, selectTheme, previewMaterial, saveDecorations, createNewLayout  }} />
            )}
          </>
        )}
        {(!apartmentReady || !instructionsDismissed) && (
          <>
            <Instructions {...{ handleDismissInstructions }} disabled={!apartmentReady} />
          </>
        )}
        {apartmentReady && instructionsDismissed && (
            <TopInfoBar isVisible={!showControls} text={topViewText} allowClose={true} />
        )}
        {children}
        <LoadingInfoBar />
        {threeDState.openHubSpotForm && (
          <Paper variants="contentCenter mobileFullScreen transparent" style={{ zIndex: 999 }}>
            <H3 variants="withMarginBottom" style={{ textAlign: 'center' }}>
              {t('hubspotForm.title')}
            </H3>
            <P
              variants="xs withMarginBottom"
              style={{ textAlign: 'center', margin: theme.spacing(3) }}
            >
              {t('hubspotForm.instructionsParagraph1')}
            </P>
            <HubspotContactForm />
            <Button
              onClick={() => {
                dispatch({
                  type: ThreeDStateAction.SET_OPEN_HUBSPOTFORM,
                  data: false
                });
              }}
            >
              {t('hubspotForm.closeButton')}
            </Button>
          </Paper>
        )}
      </ThreeDContainer>
    ),
    [
      threeDState.downloadingPicture,
      move,
      apartmentReady,
      errorMessage,
      threeDState.instructionsDismissed,
      threeDState.themesAndMaterialsOpen,
      loadingMessage,
      amountOfMaterials,
      currentMaterialNbr,
      children,
      dispatch,
      resetCamera,
      selectTheme,
      previewMaterial,
      showControls,
      floors,
      isLayoutView,
      selectFloor,
      themesAndMaterialsEnabled,
      takePicture,
      threeDState.openHubSpotForm
    ]
  );
};

export default ThreeDStream;
