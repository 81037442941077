import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styleAccordionStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      padding: theme.spacing(0),
    },
    listItem: {
      padding: theme.spacing(0),
    },
    accordion: {
      width: 'auto',
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.accordionBackground.default,
      borderStyle: 'none',
      boxShadow: 'none',
      borderRadius: '10px !important',
      '&:before': {
        display: 'none' // This removes the extra line on top of accordion
      },
    },
    accordionSummary: {
      margin: 0,
    },
    accordionHeading: { 
      fontSize: theme.typography.pxToRem(15),
      flexBasis: 'auto',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    grid: {
      flexGrow: 1,
      justifyContent: 'left',
        padding: `0 ${theme.spacing(2)}px`,
        paddingBottom: theme.spacing(2),
    },
    subPackageViewContainer: {
      width: '100%'
    },
    returnButton: {
      color: theme.palette.buttons.default
    },
      checkMark: {
          fontSize: '2rem', //.5
          position: 'relative',
          top: '50%',
          marginTop: '-1rem', //Half of the icon size .25
          //marginRight: '25%',
          color: theme.palette.checkIcons.default
      }
  }),
);

export default styleAccordionStyles