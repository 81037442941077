import React, {useContext} from 'react'
import {DialogStateContext} from "../../store/dialogState/DialogStateProvider";
import {Button, Grid, Typography} from "@material-ui/core";
import {DialogStateAction} from "../../store/dialogState/interfaces";
import LayoutDialogStyles from "./SelectBaseLayoutDialog.styles";
import SelectionButton from "../common/SelectionButton";
import {t} from "../../utils";
import useEventLogger from "../../analytics/useEventLogger";

interface layoutProps{
    options: layoutOption[];
    onSelect: (id?: number) => void;
}

export interface layoutOption{
    id?: number;
    caption: string;
    imageUrl?: string;    
}

// Prompt user to choose a base layout for their own decoration
const SelectBaseLayoutDialog: React.FC<layoutProps> = ({onSelect, options}) => {
    
    const { logActions } = useEventLogger();
    const {dispatch: dialogDispatch} = useContext(DialogStateContext);
    
    const classes = LayoutDialogStyles();
    
    // Call to finish custom dialog action
    const OnDialogActionFinished = () => {
        dialogDispatch({type: DialogStateAction.CUSTOM_DIALOG, data: undefined})
    }
    
    const CreateGridItems = () => {
        if(!options.length)
        {
            return null;
        }
        return options.map(o => {
            return <Grid key={o.id} item xs={6}>
                {CreateSelectionButton(o.caption, o.id, o.imageUrl)}
            </Grid>
        });
    }
    
    const CreateSelectionButton = (caption: string, id?: number, imageUrl?: string) => {
        return <SelectionButton isSelected={false} 
                                selectCaption={caption} 
                                selectionImageUrl={imageUrl}
                                onSelect={() => {
                                    logActions('layout_created',
                                        id ? 'default layout' : 'empty layout',
                                        'layout_created')
                                    onSelect(id);
                                    OnDialogActionFinished();
                                }
        }/>
    }
    
    return(<div className={classes.root}>
        <Typography className={classes.text} variant={"h4"}>{t('decoration.dialog.header')}</Typography>
        <Typography className={classes.text} variant={"body1"}>{t('decoration.dialog.description')}</Typography>
        <Grid container spacing={2}>
            {CreateGridItems()}
        </Grid>
        <Button className={classes.closeButton} onClick={OnDialogActionFinished}>
            <Typography variant={'button'}>{t('decoration.dialog.cancel')}</Typography>
        </Button>
    </div>)
}

export default SelectBaseLayoutDialog;