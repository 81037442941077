import { Grid } from '@material-ui/core';
import React from 'react';
import actionButtonContainerStyles from './ActionButtonContainer.styles';

interface ActionButtonContainerProps {
  buttons: React.ReactNode[];
  isLayoutView: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const ActionButtonContainer: React.FC<ActionButtonContainerProps> = ({
  buttons,
  isLayoutView,
}: ActionButtonContainerProps) => {
  const classes = actionButtonContainerStyles();

  return (
      <Grid container spacing={1} className={isLayoutView ? classes.layoutView : classes.actionButtonContainer} >
        {buttons}
      </Grid>
  );
};
