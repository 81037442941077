import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const OptionsStyles = makeStyles((theme: Theme) => {
        return createStyles({
            pageContainer: {
                overflow: 'hidden',
                overflowY: 'auto',
            },
            contentOptions: {
                border: '1px solid #EAECF0',
                borderStyle: 'none !important',
                boxShadow: 'none !important',
                borderRadius: '10px !important',
                padding: `${theme.spacing(2)}px`,
                background: theme.palette.accordionBackground.default,
                marginBottom: theme.spacing(1),
            },
            accordion:{
                marginTop: theme.spacing(1),
                padding: `0 ${theme.spacing(2)}px`,
                width: '100%',
                backgroundColor: theme.palette.accordionBackground.default,
                borderStyle: 'none !important',
                boxShadow: 'none !important',
                borderRadius: '10px !important',
                '&:before': {
                    display: 'none' // This removes the extra line on top of accordion
                },
                '& .Mui-Expanded': {
                    display: 'none' // This removes the extra line on top of accordion
                },
            },
            settings: {
                background: theme.palette.accordionBackground.default,
                padding: `0 ${theme.spacing(2)}px`,
            },
            optionCard: {
                background: 'white !important'
            },
            accordionSummary: {
                padding: 0,
                height: 'fit-content',
                minHeight: '0 !important'
            },
            summaryText: {
                fontWeight: 'bold',
            }
        })
    })


export default OptionsStyles;