import i18n from "i18next"
import axios, { AxiosResponse } from "axios"

export const TURN_SERVERS_URL = process.env.REACT_APP_TURN_SERVERS_URL

const headers = { "accept-language": i18n.language || "en" }

const turnServersInstance = axios.create({
  baseURL: TURN_SERVERS_URL,
  headers,
  timeout: 5000,
})

export const getTurnServers = (): Promise<
  AxiosResponse<{
    servers: [
      { Address: string; Password: string; Pool: string; Username: string }
    ]
  }>
> => turnServersInstance.get("")
