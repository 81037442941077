import {DecorationInputState, DecoState, DecoStateAction, DecoStateActionType} from "./interfaces";

const DecoStateReducer = (
    decoState: DecoState,
    action: DecoStateActionType
): DecoState =>{
    
    switch (action.type)
    {
        case DecoStateAction.DECO_OPTIONS:
            return {
                ...decoState,
                decorationOptions: action.data
            }
        case DecoStateAction.LAYOUT_OPTIONS: {
            const a = action.data?.find(l => l.apartmentId && !l.userId) ?? action.data?.find(l => l.groupId)
            const u = action.data?.find(l => l.userId && !l.isDirty)

            return {
                ...decoState,
                layoutOptions: action.data,
                selectedLayout: u ? u : a,
                isInitiating: true
            }
        }
        case DecoStateAction.SELECT_DECO: {
            const deco =
                decoState.decorationOptions.find(d => d.id === action?.data?.materialId)
            const isSelected = action?.data != null;
            // Determine correct input state
            const is = isSelected || decoState.decorationInput == DecorationInputState.LOADING ? decoState.decorationInput : DecorationInputState.IDLE;            
            return {
                ...decoState,
                isDecorationSelected: isSelected,
                selectedDecoration: deco,
                selectedDecorationIdentifier: action?.data?.identifier,
                loadDecoration: undefined,
                decoPositionX: action.data?.x ?? 0,
                decoPositionY: action.data?.y ?? 0,
                decorationInput: is,
                isInitiating: false
            }
        }
        case DecoStateAction.PLACE_DECO:
            
            if(!decoState.selectedDecorationIdentifier)
            {
                return decoState;
            }
            
            return {
                ...decoState,
                decorationInput: DecorationInputState.IDLE,
                decoPositionX: action.data?.x ?? 0,
                decoPositionY: action.data?.y ?? 0,                
            }
        case DecoStateAction.LOAD_DECO:
            return {
                ...decoState,
                loadDecoration: action.data,
                decorationInput: DecorationInputState.LOADING,
                isInitiating: false
            }
        case DecoStateAction.DECO_INPUT:
            
            if(decoState.decorationInput == DecorationInputState.LOADING && !decoState.selectedDecorationIdentifier)
            {
                return decoState;
            }
            
            return {
                ...decoState,
                decorationInput: action.data
            }
        case DecoStateAction.SELECT_LAYOUT:
            return {
                ...decoState,
                selectedLayout: action.data,
                isInitiating: false
            }
        case DecoStateAction.LAYOUT_CREATED:
            return {
                ...decoState,
                newLayoutId: action.data
            }
        case DecoStateAction.CHANGE_PAGE:
            return {
                ...decoState,
                activeDecoTab: action.data
            }
        case DecoStateAction.TUTORIAL_STATE:
            return {
                ...decoState,
                tutorialShown: action.data
            }
    }
    
    throw new Error('Bad deco action!');
}

export default DecoStateReducer;