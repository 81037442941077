import React from "react";
import {Typography} from "@material-ui/core";
import DecorationTutorialStyles from "./DecorationTutorial.styles";

interface props{
    imageUrl: string,
    pageHeader: string,
    pageContent: string
}

const TutorialPage: React.FC<props> = ({imageUrl, pageHeader, pageContent}) => {
    
    const classes = DecorationTutorialStyles();   
    return(<>
        <img className={classes.image}  src={`${imageUrl}`} alt={'tutorial image'}/>
        <Typography className={classes.headerText} variant={"h5"}>{pageHeader}</Typography>
        <Typography className={classes.contentText}>{pageContent}</Typography>
    </>)
}

export default TutorialPage;