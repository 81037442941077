import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


const SettingsStyles = makeStyles((theme: Theme) => {
    return createStyles({
        accordionItem: {
            marginBottom: theme.spacing(2)
        },
        toggleButton:{
            display: 'flex',
            flexDirection: 'row',
            overflow: 'hidden',
            
            justifyContent: 'space-between',
            alignItems: 'center',
            
            cursor: 'pointer'
        },
        buttonContent:{
            display: 'flex',
            flexDirection: 'column',            
        },
        checkbox: {
            borderRadius: '100%'
        },
        settingText: {
            fontWeight: 'bold'
        },
        settingsButton: {
            display: 'flex',
            flexDirection: 'row',

            background: "white",
            
            width: '100%',
            height: 'fit-content',

            border: '1px solid #EAECF0',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderRadius: '8px',

            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(1),

            padding: '8px',
            paddingRight: '6px',
            paddingLeft: '6px',

            '&:hover': {
                backgroundColor: 'white',
                filter: 'brightness(0.9)',
            }
        }
    })
})

export default SettingsStyles;