import { GlobalStateContext } from "./../store/globalState/GlobalStateProvider"
import { useContext, useEffect } from "react"
import { getStyles } from "../axios"
import { GetStylesParams } from "../axios/instances/interfaces"
import {
  StylesStateAction,
  StylesStateActionType,
} from "../store/stylesState/interfaces"
import { StylesStateContext } from "../store/stylesState/StylesStateProvider"

interface StylesFetcherReturnValue {
  fetchStyles: () => void
  triggerFetchStyles: () => void
}

export const composeDoFetch =
  (params: GetStylesParams, dispatch: React.Dispatch<StylesStateActionType>) =>
  async (): Promise<void> => {
    dispatch({ type: StylesStateAction.SET_FETCHING_STYLES, data: true })
    const response = await getStyles(params)
    dispatch({
      type: StylesStateAction.SET_STYLES,
      data: response.data.data.packageSets,
    })

    dispatch({ type: StylesStateAction.SET_FETCHING_STYLES, data: false })
  }

export const useStylesFetcher = (): StylesFetcherReturnValue => {
  const { globalState } = useContext(GlobalStateContext)
  const { stylesState, dispatch } = useContext(StylesStateContext)

  if (
    !globalState.startParameters.apartmentId ||
    !globalState.startParameters.api ||
    !globalState.startParameters.company ||
    !globalState.startParameters.projectId ||
    !globalState.startParameters.schema
  )
    throw new Error("Start parameters are missing in styles list.")

  const params: GetStylesParams = {
    apartmentId: globalState.startParameters.apartmentId,
    apiUrl: globalState.startParameters.api,
    organisationId: globalState.startParameters.company,
    projectId: globalState.startParameters.projectId,
    tenantId: globalState.startParameters.schema,
    token: globalState.token || undefined,
  }

  const doFetch = composeDoFetch(params, dispatch)
  const triggerFetchStyles = () => {
    doFetch()
  }

  useEffect(() => {
    if (!stylesState.stylesFetched) doFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { fetchStyles: () => doFetch(), triggerFetchStyles }
}

export default useStylesFetcher
