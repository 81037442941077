import React, { Dispatch, useMemo, useReducer } from "react"
import stylesStateReducer from "./stylesStateReducer"
import initialStylesState from "./initialStylesState"
import {
  StylesStateReducerType,
  StylesState,
  StylesStateActionType,
} from "./interfaces"

export const StylesStateContext = React.createContext<{
  stylesState: StylesState
  dispatch: Dispatch<StylesStateActionType>
}>({
  stylesState: initialStylesState,
  dispatch: () => initialStylesState,
})

const { Provider } = StylesStateContext

interface StylesStateProviderProps {
  children: React.ReactNode
}

const StylesStateProvider: React.FC<StylesStateProviderProps> = ({
  children,
}) => {
  const [stylesState, dispatch] = useReducer<StylesStateReducerType>(
    stylesStateReducer,
    initialStylesState
  )

  const contextValue = useMemo(() => ({ stylesState, dispatch }), [
    stylesState,
    dispatch,
  ])

  return <Provider value={contextValue}>{children}</Provider>
}

export default StylesStateProvider
