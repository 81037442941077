import axios, {AxiosResponse} from "axios";
import {useContext, useEffect} from "react";
import {GlobalStateContext} from "../../store/globalState/GlobalStateProvider";
import {createConfig} from "../utils";
import {DecorationResponse, LayoutResponse} from "./interfaces";
import {DecoStateContext} from "../../store/decoState/DecoStateProvider";
import {DecoStateAction} from "../../store/decoState/interfaces";
import {StartUrlParametersInterface} from "../../store/globalState/interfaces";

const apiUrl = process.env.REACT_APP_DECORATION_API_URL
const headers = {"accept-language": "en"}
export const apiInstance = axios.create({headers})

const useDecoFetcher = (fetch: (d: DecorationResponse) => void): void =>{

    const {company, schema, projectId, apartmentId, authToken} = useContext(GlobalStateContext).globalState.startParameters;
    const getDecorations = ():Promise<AxiosResponse<DecorationResponse>> =>
        apiInstance.get(`${apiUrl}/v0/organizations/${company}/tenants/${schema}/projects/${projectId}/apartments/${apartmentId}/decorations`,
            createConfig({ token: authToken, headers })
        );
    
    useEffect(() => {

        const fetcher = async () => {            
            const result = await getDecorations();
            fetch(result.data)
        }
        fetcher();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
}

const useLayoutFetcher = (fetch: (d: LayoutResponse) => void): void => {

    const {globalState} = useContext(GlobalStateContext);
    
    useEffect(() => {        
        const fetcher = async () => {
            const result = await getLayouts(globalState.startParameters);
            fetch(result.data);
        }
        fetcher();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
}

async function getLayouts({ company, schema, projectId, apartmentId, authToken}: StartUrlParametersInterface)
{
    const getLayouts = ():Promise<AxiosResponse<LayoutResponse>> =>
        apiInstance.get(`${apiUrl}/v0/organisations/${company}/tenants/${schema}/projects/${projectId}/apartments/${apartmentId}/layouts`,
            createConfig({token: authToken, headers}));
    
    return await getLayouts();    
}

export {useDecoFetcher, useLayoutFetcher, getLayouts};
