import {makeStyles, createStyles, Theme} from "@material-ui/core/styles";

const DialogStyles = makeStyles((theme: Theme) => {
    return createStyles({
        dialogRoot:{
            display: 'flex',
            
            width: '100%',
            height: '100%',

            backdropFilter: 'blur(5px)',
            backgroundColor: 'rgba(0,0,0,0.5)',
            
            zIndex: 100,
            
            justifyContent: 'center',
            alignContent: 'center',
            flexWrap: 'wrap',
            
            position: "absolute",
            top: '0',
            left: '0',
        }
    })
})
export default DialogStyles;