import { mediaHeightLDown } from "./../../../styled/mediaQueries"
import { TextTheme } from "../../../styled/interfaces"
import { mediaMediumDown, mediaQuery } from "../../../styled/mediaQueries"

const createTextStyles = (textStyles: TextTheme, lines?: number): string => `
  margin: 0;
  font-size: calc(${textStyles.fontSize} * var(--size-factor));
  ${textStyles.unscaledFont ? `font-size: ${textStyles.fontSize};` : ""}
  font-weight: ${textStyles.fontWeight};
  color: ${textStyles.color};
  text-align: ${textStyles.textAlign};
  transition: color 0.3s ease-in-out;
  ${
    lines
      ? `
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  text-overflow: ellipsis;
  overflow: hidden;
  `
      : ""
  }

  ${
    textStyles.marginBottom
      ? `
    --mb-factor: 1;
    margin-bottom: calc(${textStyles.marginBottom} * var(--mb-factor));

    ${mediaQuery(mediaMediumDown)}{
      --mb-factor: 0.5;
    }
    ${mediaQuery(mediaHeightLDown)} {
      --mb-factor: 0.25;
    }
  `
      : ""
  }

  ${
    textStyles.hoverEffect
      ? `
    cursor: pointer;

    &:hover {
      color: ${textStyles.colorHover};
    }
  `
      : ""
  }
`

export default createTextStyles
