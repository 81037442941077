import { ThemeOptions } from '@material-ui/core';
import { CustomThemeType, PaletteType } from './types';

export const defaultColors: PaletteType = {
  primary: '#B7835F',
  secondary: '#EFEEE4',
  default: '#05061B',
  accordionBackground: '#F2F4F7',
  lockIcon: '#05061B',
  styleCardPreviewBackground: '#f7f7f7'
};

// const defaultFont = ['Helvetica Neue', 'sans-serif'].join(',');

export const customBreakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
  mobileMenu: 1100,
  mobilePhasesBar: 768
};

const baseTheme = (themeOverrides: CustomThemeType = {}) => {
  const { palette, typography } = themeOverrides;
  const colors = {
    ...defaultColors,
    ...palette
  };

  return {
    palette: {
      primary: {
        main: colors.primary
      },
      secondary: {
        main: colors.secondary
      },
      buttons:{
        default: colors.primary,
        secondary: colors.secondary
      },
      accordionBackground: {
        default: colors.accordionBackground
      },
      styleCard: {
        default: colors.styleCardPreviewBackground
      },
      progressBar: {
        dark: colors.primary,
        light: colors.secondary
      },
      checkIcons: {
        default: colors.primary
      },
      lockIcon: {
        default: colors.lockIcon
      },
      textColor: {
        main: colors.primary,
        dark: colors.default,
      }
    },
  } as ThemeOptions;
};

export default baseTheme;
