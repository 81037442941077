import en from "./en/main.json"
import fi from "./fi/main.json"
import sv from "./sv/main.json"
import ru from "./ru/main.json"
import de from "./de/main.json"
import nl from "./nl/main.json"
import et from "./et/main.json"
import sk from "./sk/main.json"
import cs from "./cs/main.json"
import nb from "./nb/main.json"

const languages = { en, fi, sv, ru, de, nl, et, sk, cs, nb }

export default languages
