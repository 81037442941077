import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const LayoutOptionsPageStyles = makeStyles((theme: Theme) => {
    return createStyles({
        page:{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: 'fit-content',
            maxHeight: '100%',
            boxSizing: 'border-box',

            background: theme.palette.accordionBackground.default,
            border: '1px solid #EAECF0',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderRadius: '8px',
            padding: theme.spacing(2),
        },
        contentHeader: {
            fontWeight: 'bold'
        },
        gridContainer: {
            height: '100% !important',
        },
        grid: {
            display: 'flex',
            overflow: 'hidden',
            overflowY: 'auto',
            height: 'fit-content',
            maxHeight: '100%',
            marginTop: theme.spacing(2),
        },
        gridItem:{
            width: '100%',
            //minWidth: '250px',
        },
        gridContent: {
            width: '100%',
            background: 'white !important'
        },
    })
});

export default LayoutOptionsPageStyles;