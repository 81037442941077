import React, {useContext, useEffect} from "react";
import {DecoStateContext} from "../../store/decoState/DecoStateProvider";
import {DecorationInputState, DecoStateAction} from "../../store/decoState/interfaces";

interface props{
    sendPosition: (x: number, y: number) => void;
}

const DecorationPositionTool: React.FC<props> = ({sendPosition}: props) => {
    
    const {dispatch} = useContext(DecoStateContext);
    
    useEffect(() => {
        
        const pointerMove = (event: PointerEvent) => {
            sendPosition(event.x, event.y);
        }
        const pointerUp = () => {
            dispatch({type: DecoStateAction.DECO_INPUT, data: DecorationInputState.IDLE});
        }
        
        
        window.addEventListener('pointermove', pointerMove);
        window.addEventListener('pointerdown', pointerMove);
        window.addEventListener('pointerup', pointerUp);
        return(() =>{
            window.removeEventListener('pointermove', pointerMove);
            window.removeEventListener('pointerdown', pointerMove);
            window.removeEventListener('pointerup', pointerUp);
        })
        
    }, [])
    
    return(<></>)
}

export default DecorationPositionTool;