import i18next, { TOptions } from "i18next"
import { getStartParameters } from "./StartUrlParameters"

const params = getStartParameters()

export const t = (s: string, options?: TOptions): string => {
  const translation = i18next.t(
    [`${params.company}.${s}`, `main.${s}`],
    options
  )

  if (translation === undefined) console.warn("Missing translation:", s)

  return translation ?? s
}

export const makeId = (length: number): string => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  return Array(length)
    .fill("")
    .map(() => characters.charAt(Math.floor(Math.random() * characters.length)))
    .join("")
}

export const sleep = (ms: number): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}
