import {Accordion, AccordionSummary, Grid, List, ListItem, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, {useContext, useEffect} from 'react';
import {t} from '../../../utils';
import {Bundle, BundleState, Room} from '../../../axios/instances/interfaces';
import {StylesStateAction} from "../../../store/stylesState/interfaces"
import {StylesStateContext} from "../../../store/stylesState/StylesStateProvider"
import roomAccordionStyles from './RoomAccordion.styles';
import ProductButton from "../../common/ProductButton";

import LockIcon from '@material-ui/icons/Lock';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {MENULEVEL} from "./MaterialSelectionMenu";

interface RoomAccordionProps {
  /**
   * Data
   */
  room: Room | null;
  bundles: Bundle[];
  isExpanded?: boolean;
  handleMenuLevelChange: (level: number) => void;
}

const RoomAccordion: React.FC<RoomAccordionProps> = ({room, bundles, isExpanded, handleMenuLevelChange}) => {

  const { dispatch } = useContext(StylesStateContext)
  const generalAppearance = t('theming.generalAppearance').toString().toUpperCase()

  const getRoomBundles = (bundles: Bundle[], roomId: number): Bundle[] => {
    return bundles.filter(
      bundle => bundle.roomIds.includes(roomId) && bundle.showInRoomLevel
    )
  }
  const getCommonBundles = (bundles: Bundle[]): Bundle[] => {
    return bundles.filter(bundle => bundle.showInApartmentLevel)
  }

  const availableBundlesForRoom = room ? getRoomBundles(bundles, room.id) : getCommonBundles(bundles)

  const handleAccordionState = () => {
    !isExpanded ?
      dispatch({
      type: StylesStateAction.SET_OPEN_ROOM,
      data: room ? room.id : null
    }) : 
      dispatch({
        type: StylesStateAction.SET_OPEN_ROOM,
        data: -1
    })
  }

  useEffect(() => {
    return () =>
      dispatch({
        type: StylesStateAction.RESET_PREVIEW,
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = roomAccordionStyles();
  return (
    <Accordion className={classes.accordion} expanded={isExpanded} onChange={handleAccordionState}>
      <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Typography className={classes.accordionHeading} >{room ? room.name : generalAppearance}</Typography> 
          </ListItem>
          {isExpanded &&
            <ListItem className={classes.listItem}>
              <Typography className={classes.secondaryHeading} >{t('theming.selectRoomMaterials')}</Typography>  
            </ListItem>
          }
        </List>
      </AccordionSummary> 
      <Grid item container className={classes.grid} xs={12} >
        {availableBundlesForRoom.map((bundle, id) => {
          
          const isLocked = bundle.state === BundleState.LOCKED || bundle.state === BundleState.CONFIRMED;
          
          return <ProductButton key={id} 
                                productName={bundle.name} 
                                productImageUrl={bundle.selectedMaterial?.imageUrlSmall} 
                                productDescription={bundle.selectedMaterial?.name}
                                onClicked={() => {
                                  if(!isLocked) {
                                    dispatch({type: StylesStateAction.SELECT_BUNDLE, data: bundle})
                                    handleMenuLevelChange(MENULEVEL.BUNDLE_LEVEL);
                                  }
                                }}
                                icon={(isLocked ? <LockIcon className={classes.lockIcon}/> : <ChevronRight className={classes.chevron}/>)}
          />
          //return <RoomBundleCard key={id} room={room} bundle={bundle} handleMenuLevelChange={handleMenuLevelChange} />
        })}
      </Grid>
    </Accordion>
  )
}

export default RoomAccordion;