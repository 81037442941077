import {DecorationInputState, DecoState} from "./interfaces";

const initialState: DecoState ={
    isDecorationSelected: false,
    decoPositionX: 0, decoPositionY: 0,
    selectedDecoration: undefined,
    loadDecoration: undefined,
    decorationInput: DecorationInputState.IDLE,
    selectedLayout: undefined,
    decorationOptions: [],
    layoutOptions: [],
    isInitiating: true,
    activeDecoTab: 0,
    tutorialShown: false
};

export default initialState;