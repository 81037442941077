import {DecoData, DecoPosition, Decoration, Layout} from "../../axios/instances/interfaces";

export interface DecoState{
    selectedDecoration?: Decoration,
    selectedDecorationIdentifier?: string,
    isDecorationSelected: boolean,
    loadDecoration?: Decoration,
    decorationInput?: DecorationInputState,
    selectedLayout?: Layout,
    decorationOptions: Decoration[],
    layoutOptions: Layout[],
    decoPositionX: number,
    decoPositionY: number,
    isInitiating: boolean, // Prevent some messages from triggering when loading options
    newLayoutId?: number, // Id of a newly created user layout
    activeDecoTab: number,
    tutorialShown: boolean,
}

export enum DecoStateAction{
    SELECT_DECO,
    PLACE_DECO,
    LOAD_DECO,
    DECO_INPUT,
    SELECT_LAYOUT,
    DECO_OPTIONS,
    LAYOUT_OPTIONS,
    LAYOUT_CREATED,
    CHANGE_PAGE,
    TUTORIAL_STATE
}

export enum DecorationInputState{
    IDLE,
    MOVE,
    ROTATE,
    DELETE,
    LOADING
}

export interface SelectDecoAction{
    type: DecoStateAction.SELECT_DECO
    data?: DecoData
}

export interface LoadDecoAction{
    type: DecoStateAction.LOAD_DECO
    data?: Decoration
}

export interface InputDecoAction{
    type: DecoStateAction.DECO_INPUT
    data: DecorationInputState
}

export interface SelectLayoutAction{
    type: DecoStateAction.SELECT_LAYOUT
    data?: Layout
}

export interface GetDecoOptionsAction{
    type: DecoStateAction.DECO_OPTIONS
    data: Decoration[]
}

export interface GetLayoutOptionsAction{
    type: DecoStateAction.LAYOUT_OPTIONS
    data: Layout[]
}

export interface PlaceDecorationAction{
    type: DecoStateAction.PLACE_DECO
    data?: DecoPosition
}

export interface LayoutCreatedAction{
    type: DecoStateAction.LAYOUT_CREATED
    data?: number
}

export interface ChangePageAction{
    type: DecoStateAction.CHANGE_PAGE,
    data: number
}

export interface TutorialStateAction{
    type: DecoStateAction.TUTORIAL_STATE,
    data: boolean
}

export type DecoStateActionType = 
    SelectDecoAction |
    LoadDecoAction |
    InputDecoAction |
    SelectLayoutAction |
    GetDecoOptionsAction | 
    GetLayoutOptionsAction |
    PlaceDecorationAction |
    LayoutCreatedAction |
    ChangePageAction | 
    TutorialStateAction;

export type DecoStateReducerType = (
    state: DecoState,
    action: DecoStateActionType
) => DecoState;