export const widthXSmall = "468px"
export const widthSmall = "576px"
export const widthMedium = "768px"
export const widthLarge = "1024px"
export const widthXLarge = "1200px"

export const mediaXSmallUp = `(min-width: ${widthXSmall})`
export const mediaSmallUp = `(min-width: ${widthSmall})`
export const mediaMediumUp = `(min-width: ${widthMedium})`
export const mediaLargeUp = `(min-width: ${widthLarge})`
export const mediaXLargeUp = `(min-width: ${widthXLarge})`

export const mediaXSmallDown = `(max-width: calc(${widthXSmall} - 0.02px))`
export const mediaSmallDown = `(max-width: calc(${widthSmall} - 0.02px))`
export const mediaMediumDown = `(max-width: calc(${widthMedium} - 0.02px))`
export const mediaLargeDown = `(max-width: calc(${widthLarge} - 0.02px))`
export const mediaXLargeDown = `(max-width: calc(${widthXLarge} - 0.02px))`

export const heightXSmall = "400px"
export const heightSmall = "540px"
export const heightMedium = "620px"
export const heightLarge = "680px"
export const heightXLarge = "800px"

export const mediaHeightXSUp = `(min-height: ${heightXSmall})`
export const mediaHeightSUp = `(min-height: ${heightSmall})`
export const mediaHeightMUp = `(min-height: ${heightMedium})`
export const mediaHeightLUp = `(min-height: ${heightLarge})`
export const mediaHeightXLUp = `(min-height: ${heightXLarge})`

export const mediaHeightXSDown = `(max-height: calc(${heightXSmall} - 0.02px))`
export const mediaHeightSDown = `(max-height: calc(${heightSmall} - 0.02px))`
export const mediaHeightMDown = `(max-height: calc(${heightMedium} - 0.02px))`
export const mediaHeightLDown = `(max-height: calc(${heightLarge} - 0.02px))`
export const mediaHeightXLDown = `(max-height: calc(${heightXLarge} - 0.02px))`

export const mediaLandscape = `(orientation: landscape)`
export const mediaPortrait = `(orientation: portrait)`

export const mediaQuery = (text: string): string => `@media screen and ${text}`
