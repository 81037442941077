import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

  const progressBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepIndicator: {
      width: '10px',
      height: '10px',
      margin: theme.spacing(1),
      borderRadius: '50%',
      display: 'inline-block',
      '@media screen and (max-height: 600px) and (orientation: landscape)': { 
        marginTop: 0,
        padding: 0
      },
    },
  }),
);

export default progressBarStyles