import { ThreeDState } from "./interfaces"

const initialThreeDState: ThreeDState = {
  instructionsDismissed: false,
  settingsOpen: false,
  themesAndMaterialsOpen: false,
  downloadingPicture: false,
  openHubSpotForm: false
}

export default initialThreeDState
