import { StartUrlParameters } from "./../utils/StartUrlParameters"
import { Dispatch, useEffect } from "react"
import { login, tokenExchange } from "../axios"
import {
  GlobalState,
  GlobalStateAction,
  GlobalStateActionType,
} from "../store/globalState/interfaces"

const useLogin = (
  dispatch: Dispatch<GlobalStateActionType>,
  globalState: GlobalState
): void => {
  const { password, username, authToken, accessToken, api, schema, company } =
    globalState.startParameters

  useEffect(() => {
    if (api && schema) {
      const doLogin = async () => {
        if (password && username) {
          const response = await login(api, username, password, schema)
          dispatch({
            type: GlobalStateAction.SET_TOKEN,
            data: response.data.token,
          })
          window.sessionStorage.setItem(
            StartUrlParameters.authToken,
            response.data.token
          )
        } else if (accessToken && company) {
          const response = await tokenExchange(
            api,
            company,
            schema,
            accessToken
          )
          dispatch({
            type: GlobalStateAction.SET_TOKEN,
            data: response.data.data.token,
          })
          window.sessionStorage.setItem(
            StartUrlParameters.authToken,
            response.data.data.token
          )
        } else if (authToken) {
          dispatch({
            type: GlobalStateAction.SET_TOKEN,
            data: authToken,
          })
        }
      }
      doLogin()
    }
  }, [
    password,
    username,
    accessToken,
    authToken,
    api,
    schema,
    company,
    dispatch,
  ])
}

export default useLogin
