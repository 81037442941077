import initialThreeDState from "./initialThreeDState"
import {
  ThreeDState,
  ThreeDStateAction,
  ThreeDStateActionType,
} from "./interfaces"

const threeDStateReducer = (
  threeDState: ThreeDState,
  action: ThreeDStateActionType
): ThreeDState => {
  switch (action.type) {
    case ThreeDStateAction.DISMISS_INSTRUCTIONS:
      return {
        ...threeDState,
        instructionsDismissed: true,
      }
    case ThreeDStateAction.TOGGLE_SETTINGS_OPEN:
      return {
        ...threeDState,
        settingsOpen: !threeDState.settingsOpen,
      }
    case ThreeDStateAction.TOGGLE_THEMES_AND_MATERIALS_OPEN:
      return {
        ...threeDState,
        themesAndMaterialsOpen: !threeDState.themesAndMaterialsOpen,
      }
    case ThreeDStateAction.RESET_STORE:
      return initialThreeDState
    case ThreeDStateAction.SET_DOWNLOADING_PICTURE:
      return { ...threeDState, downloadingPicture: action.data }
    case ThreeDStateAction.SET_OPEN_HUBSPOTFORM:
      return { ...threeDState, openHubSpotForm: action.data }
    default:
      throw new Error(`Bad reducer action.`)
  }
}

export default threeDStateReducer
