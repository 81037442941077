import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const DecorationSelectionStyles = makeStyles((theme: Theme) => {
    return createStyles({
        accordion:{
            marginTop: theme.spacing(1),
            width: '100%',
            backgroundColor: theme.palette.accordionBackground.default,
            borderStyle: 'none !important',
            boxShadow: 'none !important',
            borderRadius: '10px !important',
            '&:before': {
                display: 'none' // This removes the extra line on top of accordion
            },
            '& .Mui-Expanded': {
                display: 'none' // This removes the extra line on top of accordion
            },
        },
        accordionContent: {
            display: 'flex',
            flexDirection: 'column'
        },
        accordionHeading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: 'auto',
            flexShrink: 0,
        },
        pageContent:{
            maxHeight: '100%',
            overflow: 'auto',
            overflowY: 'auto',
            pointerEvents: 'all',
        }
    })
})

export default DecorationSelectionStyles;