import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { MoveType } from './threeDStreaming';
import { ReactComponent as TurnLeftIcon } from '../../images/icons/arrow_left_turn_icon.svg';
import { ReactComponent as TurnRightIcon } from '../../images/icons/arrow_right_turn_icon.svg';
import { ReactComponent as UpIcon } from '../../images/icons/arrow_up_icon.svg';
import { ReactComponent as DownIcon } from '../../images/icons/arrow_down_icon.svg';
import { ReactComponent as RightIcon } from '../../images/icons/arrow_right_icon.svg';
import { ReactComponent as LeftIcon } from '../../images/icons/arrow_left_icon.svg';
import { MovementButton } from './MovementButton';
import movementButtonContainerStyles from './MovementButtonContainer.styles';
import useEventLogger from '../../analytics/useEventLogger';

interface MovementButtonContainerProps {
  onHold: (type: MoveType, stop?: boolean) => void;
}
/**
 * Primary UI component for user interaction
 */
export const MovementButtonContainer: React.FC<MovementButtonContainerProps> = ({
  onHold
}: MovementButtonContainerProps) => {
  const classes = movementButtonContainerStyles();
  const useHold: (
    onHold: (moveType: MoveType, stop?: boolean) => void
  ) => (type: MoveType) => void = (onHold) => {
    const { logActions } = useEventLogger();
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
    const [moveType, setMoveType] = useState<MoveType | null>(null);
    const [userMovesWithControlButtons, setUserMovesWithControlButtons] = useState(false);

    useEffect(() => {
      const stopHold = () => {
        if (intervalId) clearInterval(intervalId);
        setIntervalId(null);
        if (moveType) {
          setMoveType(null);
        }
      };

      window.addEventListener('mouseup', stopHold);
      window.addEventListener('touchend', stopHold);
      window.addEventListener('dragstart', stopHold);

      return () => {
        if (intervalId) {
          clearInterval(intervalId);
          setIntervalId(null);
        }

        window.removeEventListener('mouseup', stopHold);
        window.removeEventListener('touchend', stopHold);
        window.removeEventListener('dragstart', stopHold);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intervalId]);

    const startHold: (MoveType: MoveType) => void = (MoveType) => {
      setMoveType(MoveType);
      setIntervalId(setInterval(() => onHold(MoveType), 50));
      if (!userMovesWithControlButtons) {
        logActions('move_with_ui_controlbuttons', 'User moves using UI buttons', 'movement_2');
        setUserMovesWithControlButtons(true);
      }
    };
    return startHold;
  };

  const startHold = useHold(onHold);

  const createActions = (type: MoveType, onHold: (type: MoveType, stop?: boolean) => void) => ({
    onMouseDown: () => onHold(type),
    onTouchStart: () => onHold(type)
  });

  const movementButtons = [
    <MovementButton
      key="arrowTurnLeft"
      icon={<TurnLeftIcon className={classes.iconStyle} />}
      adjustBrightness={true}
      {...createActions(MoveType.ROTATE_LEFT, startHold)}
    />,
    <MovementButton
      key="arrowUp"
      icon={<UpIcon className={classes.iconStyle} />}
      {...createActions(MoveType.UP, startHold)}
    />,
    <MovementButton
      key="arrowTurnRight"
      icon={<TurnRightIcon className={classes.iconStyle} />}
      adjustBrightness={true}
      {...createActions(MoveType.ROTATE_RIGHT, startHold)}
    />,
    <MovementButton
      key="arrowLeft"
      icon={<LeftIcon className={classes.iconStyle} />}
      {...createActions(MoveType.LEFT, startHold)}
    />,
    <MovementButton
      key="arrowDown"
      icon={<DownIcon className={classes.iconStyle} />}
      {...createActions(MoveType.DOWN, startHold)}
    />,
    <MovementButton
      key="arrowRight"
      icon={<RightIcon className={classes.iconStyle} />}
      {...createActions(MoveType.RIGHT, startHold)}
    />
  ];

  return (
    <Grid container spacing={1} className={classes.buttonContainer}>
      {movementButtons}
    </Grid>
  );
};
