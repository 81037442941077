import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React, { Dispatch, useContext } from 'react';
import { GlobalStateContext } from '../../../store/globalState/GlobalStateProvider';
import {
  GlobalStateAction,
  SetHasThreeDStartedAction
} from '../../../store/globalState/interfaces';
import { t } from '../../../utils';
import Logo from '../../Logo/Logo';
import { PoweredByLabel } from '../../ThreeDStream/PoweredByLabel';
import welcomeStyles from './Welcome.styles';
import useEventLogger from '../../../analytics/useEventLogger';

export const composeOnStart =
  (dispatch: Dispatch<SetHasThreeDStartedAction>): (() => void) =>
  () => {
    dispatch({
      type: GlobalStateAction.SET_HAS_THREE_D_STARTED_ACTION,
      data: true
    });
  };

const Welcome: React.FC = () => {
  const { globalState, dispatch } = useContext(GlobalStateContext);
  const classes = welcomeStyles();
  const { logActions } = useEventLogger();

  const startClicked = () => {
    dispatch({
      type: GlobalStateAction.SET_HAS_THREE_D_STARTED_ACTION,
      data: true
    });
    logActions('start_clicked', 'Welcome_Screen', 'start_1');
  }
  
  return (
    <>
      {globalState.tenantSettings.gb4dShowBranding && <PoweredByLabel />}
      <Paper className={classes.root}>
        <Grid container className={classes.gridContainer}>
          <Grid className={classes.gridItem}>
            <Logo />
          </Grid>
          <Grid className={classes.gridItem}>
            <Typography variant="h4">{t('welcomePage.title')}</Typography>
          </Grid>
          <Grid className={classes.gridItem}>
            <Typography variant="h5">{globalState.apartmentInfo.name}</Typography>
          </Grid>
          <Grid className={classes.gridItem}>
            <Typography>{t('welcomePage.titleDescription')}</Typography>
          </Grid>
          <Grid className={classes.gridItem}>
            <Button
              variant="contained"
              color="primary"
              className={classes.primaryButton}
              onClick= {startClicked}
            >
              {t('welcomePage.buttonText')}
            </Button>
          </Grid>
          <Grid className={classes.gridItem}>
            <Typography className={classes.descriptionText}>
              {t('welcomePage.threeDDescription1')}
            </Typography>
          </Grid>
          <Grid className={classes.descriptionTextGridItem}>
            <Typography className={classes.descriptionText}>
              {t('welcomePage.threeDDescription2')}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Welcome;
