import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyAXX_D9rBdrc1Uvf-tDQ6qDKTkeG6C0xsM',
  authDomain: 'gbuilder-hd3d.firebaseapp.com',
  databaseURL: 'https://gbuilder-hd3d.firebaseio.com',
  projectId: 'gbuilder-hd3d',
  storageBucket: 'gbuilder-hd3d.appspot.com',
  messagingSenderId: '362788248021',
  appId: '1:362788248021:web:b1048e454933d1cea1c55c',
  measurementId: 'G-E1J0TFPSDL'
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const logActionsLegacy = (eventType: string, contentType: string, contentId: string) => {
  logEvent(analytics, eventType.toString(), {
    content_type: contentType,
    content_id: contentId
  });
};
