import React from "react";
import NavigationBarStyles from "./NavigationBar.styles";
import {Button, Typography} from "@material-ui/core";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import {t} from "../../../utils";

export interface navigationContent {
    icon?: JSX.Element,
    iconOnRight?: boolean,
    caption?: string,
    action: () => void,
}

interface props{
    onReturn: () => void;
    customContent?: navigationContent[]
}

const NavigationBar: React.FC<props> = ({onReturn, customContent}:props) => {
    
    const classes = NavigationBarStyles();
    
    const defaultContent = () => {
        
        return <Button className={classes.navButton} onClick={() => onReturn()}>
            <ArrowBackIos/>
            <Typography variant={'button'}>{t('navigation.toStart')}</Typography>
        </Button>

    }
    
    const renderCustomContent = () => {
        let index = 0;
        return customContent?.map(c => {
            index ++;
            return <Button key={index} className={classes.navButton} onClick={c.action}>
                {!c.iconOnRight && c.icon}
                {c.caption && <Typography variant={'button'}>{c.caption}</Typography>}
                {c.iconOnRight && c.icon}
            </Button>
        });
    }
    
    return (<div className={classes.bar}>
        {customContent ? renderCustomContent() : defaultContent()}
    </div>)
}

export default NavigationBar;