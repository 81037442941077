import styled from "styled-components"
import { TextTheme, ThemeInterface, Variants } from "../../../styled/interfaces"
import { getVariantStyles } from "../../../styled/utils"
import createTextStyles from "./createTextStyles"

export const P = styled.p<{
  variants?: Variants
  theme: ThemeInterface
  lines?: number
}>(props => {
  const variants: Variants =
    typeof props.variants !== "string"
      ? {
          s: true,
          ...(props.variants || {}),
        }
      : `s ${props.variants}`

  const textStyles = getVariantStyles<TextTheme>(props.theme.text, variants)

  return createTextStyles(textStyles, props.lines)
})

export default P
