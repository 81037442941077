import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const stylesAndMaterialsBarStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      background: 'white',
      border: '1px solid #EAECF0',
      boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '4px',
      height: '60px',
      borderRadius: '8px',
      justifyContent: 'center',
      gap: '8px',
    },
    OptionsBar: {
      boxShadow: 'none',
      marginBottom: theme.spacing(1),
      border: '1px solid #e5e4e4',
      borderRadius: '10px',
      //padding: '4px !important',
      height: 'fit-content !important',
      minHeight: '54px',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        boxShadow: 'none',
        marginBottom: theme.spacing(1),
        border: '1px solid #e5e4e4',
        borderRadius: '10px',
        //padding: '4px !important',
        height: 'fit-content !important',
        minHeight: '108px', // 2x tab height
      }
    },
    tabsContainer: {
      display: 'flex',
      width: '100%',
      overflow: 'auto',
    },
    tab: {
      transition: '0.2s',
      height: '52px',
      width: '-webkit-fill-available',
      minWidth: '125px',
      background: 'white',
      border: 'none',
      color: theme.palette.text.secondary,
      '&:hover': {
        background: theme.palette.primary.main,
        filter: 'opacity(0.3)',
        color: 'white'
      }
    },
    selectedTab: {
      background: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        background: theme.palette.primary.main,
        color: 'white'
      },
      '&>div>div>img': {
        filter: 'brightness(0) invert(1)'
      }
    },
  });
});

export default stylesAndMaterialsBarStyles