import { Dispatch, useEffect } from "react"
import { getTenantSettings } from "../axios"
import {
  GlobalState,
  GlobalStateAction,
  GlobalStateActionType,
} from "../store/globalState/interfaces"

const useTenantSettingsFetcher = (
  dispatch: Dispatch<GlobalStateActionType>,
  globalState: GlobalState
): void => {
  const { api, schema, company } = globalState.startParameters

  useEffect(() => {
    const fetcher = async () => {
      if (schema && company && api) {
        const tenantSettingsResponse = await getTenantSettings(
          api,
          company,
          schema,
          globalState.token
        )

        dispatch({
          type: GlobalStateAction.SET_TENANT_SETTINGS,
          data: tenantSettingsResponse.data.data,
        })
      }
    }

    fetcher()
  }, [api, schema, company, globalState.token, dispatch])
}

export default useTenantSettingsFetcher
