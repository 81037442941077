import { Grid, useTheme } from "@material-ui/core";
import React from "react";
import progressBarStyles from "./ProgressBar.styles";

interface ProgressBarProps {
  currentStep: number;
  amountOfSteps: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({currentStep, amountOfSteps}) => {
  const theme = useTheme();
  const classes = progressBarStyles();
  const inactiveStepColor = theme.palette.progressBar.light
  const currentStepColor =  theme.palette.progressBar.dark
  const progressBarSteps = [];

  for (let index = 0 ; index < amountOfSteps ; index++) {
    const backGroundColor = currentStep !== index ? inactiveStepColor : currentStepColor
    progressBarSteps.push(<Grid item key={index} className={classes.stepIndicator} style={{backgroundColor: backGroundColor}} data-testid='progressbarStep'/>)
  }

  return <>{progressBarSteps}</>
};

export default ProgressBar;
