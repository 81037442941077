import React, { Dispatch, useMemo, useReducer } from "react"
import dialogStateReducer from "./dialogStateReducer"
import initialDialogState from "./initialDialogState"
import {
  DialogStateReducerType,
  DialogState,
  DialogStateActionType,
} from "./interfaces"

export const DialogStateContext = React.createContext<{
  dialogState: DialogState
  dispatch: Dispatch<DialogStateActionType>
}>({ dialogState: initialDialogState, dispatch: () => initialDialogState })

const { Provider } = DialogStateContext

interface DialogStateProviderProps {
  children: React.ReactNode
}

const DialogStateProvider: React.FC<DialogStateProviderProps> = ({
  children,
}) => {
  const [dialogState, dispatch] = useReducer<DialogStateReducerType>(
    dialogStateReducer,
    initialDialogState
  )

  const contextValue = useMemo(
    () => ({ dialogState, dispatch }),
    [dialogState, dispatch]
  )

  return <Provider value={contextValue}>{children}</Provider>
}

export default DialogStateProvider
