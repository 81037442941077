
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core"
import React from "react"
import { t } from "../../../utils";
import subPageStyles from "./SubPage.styles";

interface SubPageProps {
  image?: string;
  paragraphText: string;
}

const SubPage: React.FC<SubPageProps> = ({image, paragraphText}) => {
  const classes = subPageStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={image}
        data-testid='subPageImage'
      />
      <CardContent className={classes.cardContentRoot}>
        <Typography gutterBottom variant="h4" component="h2">
          {t('instructions.gettingStartedTitle')}
        </Typography>
        <Typography variant='body2'>{paragraphText}</Typography>
      </CardContent>
    </Card>
  )
}

export default SubPage
