import styled from "styled-components"
import { TextTheme, ThemeInterface, Variants } from "../../../styled/interfaces"
import { getVariantStyles } from "../../../styled/utils"
import createTextStyles from "./createTextStyles"

export const Pre = styled.pre(
  (props: {
    theme: ThemeInterface
    variants?: Variants
    children?: React.ReactChildren | string
  }) => {
    const variants: Variants =
      typeof props.variants !== "string"
        ? {
            small: true,
            ...(props.variants || {}),
          }
        : `small ${props.variants}`

    const textStyles = getVariantStyles<TextTheme>(props.theme.text, variants)

    return createTextStyles(textStyles)
  }
)

export default Pre
