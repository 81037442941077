import React, {Dispatch, useMemo, useReducer} from "react";
import {DecoState, DecoStateAction, DecoStateActionType, DecoStateReducerType} from "./interfaces";
import InitialState from "./InitialState";
import DecoStateReducer from "./DecoStateReducer";
import {useDecoFetcher, useLayoutFetcher} from "../../axios/instances/tdApi";

export const DecoStateContext = React.createContext<{
    decoState: DecoState,
    dispatch: Dispatch<DecoStateActionType>
}>({
    decoState: InitialState,
    dispatch: () => InitialState
})

const {Provider} = DecoStateContext;

interface props{
    children: React.ReactNode;
}
const DecoStateProvider: React.FC<props> = ({
    children,
    }) => {
    
    const [decoState, dispatch] = useReducer<DecoStateReducerType>(
        DecoStateReducer,
        InitialState
    )    
    const contextValue = useMemo(() => 
            ({decoState, dispatch}),
        [decoState, dispatch]);

    // Load initial static values
    useDecoFetcher((d) => {
        dispatch({type: DecoStateAction.DECO_OPTIONS, data: d.data})
    })
    useLayoutFetcher((l) => {
        dispatch({type: DecoStateAction.LAYOUT_OPTIONS, data: l.data})
    })
    
    return <Provider value={contextValue}>{children}</Provider>
}

export default DecoStateProvider;