import {ThreeDStateAction} from './../../store/threeDState/interfaces';
import {ThreeDStateContext} from './../../store/threeDState/ThreeDStateProvider';
import {DialogStateContext} from './../../store/dialogState/DialogStateProvider';
import {SnackbarStateContext} from './../../store/snackbarState/SnackbarStateProvider';
import {GlobalStateAction, SnackbarOptions} from './../../store/globalState/interfaces';
import {useCallback, useContext, useEffect, useState} from 'react';
import {registerKeyboardEvents, registerMouseEvents} from './controlsUtils';
import {sleep, t} from '../../utils';
import {SnackbarStateAction, SnackbarStateActionType} from '../../store/snackbarState/interfaces';
import {getStorageParams} from '../../utils/StartUrlParameters';
import {DialogState, DialogStateAction, DialogStateActionType} from '../../store/dialogState/interfaces';
import {format} from 'date-fns';
import {ThreeDStateActionType} from '../../store/threeDState/interfaces';
import {StylesStateContext} from '../../store/stylesState/StylesStateProvider';
import {GlobalStateContext} from '../../store/globalState/GlobalStateProvider';
import {DecoStateContext} from "../../store/decoState/DecoStateProvider";
import {logActionsLegacy} from '../../analytics/analyticsUtils';
import {DecoData, DecoPosition, Decoration, Layout} from "../../axios/instances/interfaces";
import {DecorationInputState, DecoStateAction} from "../../store/decoState/interfaces";

const wsSignalingUrl = process.env.REACT_APP_WS_SIGNALING_URL;

/* TODO remove explicit anys */

const THREE_D_TIMEOUT = Number(process.env.REACT_APP_THREE_D_TIMEOUT || 5 * 60 * 1000);

export enum ControlType {
  CAMERA_RESET = 0,
  ROTATE_LEFT = 1,
  UP = 2,
  ROTATE_RIGHT = 3,
  LEFT = 4,
  DOWN = 5,
  RIGHT = 6,
  SCREENSHOT = 7
}

export enum MoveType {
  ROTATE_LEFT = 1,
  UP = 2,
  ROTATE_RIGHT = 3,
  LEFT = 4,
  DOWN = 5,
  RIGHT = 6
}

export enum FloorChangeDirection {
  UP = 1,
  DOWN = -1
}

enum CameraModes {
  Build, // obsolete
  Decorate, // obsolete
  FreeRoam, // first person
  DiveCamera // Static camera that dives to selected position clicked
}

enum InputChannelEvent {
  APARTMENT_READY = 0,
  CAMERA_MODE = 1,
  MATERIALS_LOADED = 2,
  DOWNLOAD_PROGRESS = 3,
  MULTI_FLOOR = 4
}

interface InputChannelApartmentReadyMessage {
  id: InputChannelEvent.APARTMENT_READY;
}

interface WebSocketCameraChangedMessage {
  data: {
    cameraMode: CameraModes;
  };
  to: string;
  type: 'cameraChanged';
}

interface InputChannelMaterialsLoadedMessage {
  id: InputChannelEvent.MATERIALS_LOADED;
}

interface InputChannelDownloadMessage {
  id: InputChannelEvent.DOWNLOAD_PROGRESS;
  completed: number;
  allDownloads: number;
}

interface InputChannelMultiFloorMessage {
  id: InputChannelEvent.MULTI_FLOOR;
  floorCount: number;
}

type InputChannelMessageType =
  | InputChannelApartmentReadyMessage
  | InputChannelMaterialsLoadedMessage
  | InputChannelDownloadMessage
  | InputChannelMultiFloorMessage;

interface ThreeDStreamingClientOptions {
  onDisconnect?: Array<() => void>;
  setVideoSrc: (newSrc: MediaStream) => void;
  setApartmentReady: (apartmentReady: boolean) => void;
  setErrorMessage: (message: string) => void;
  setLoadingMessage: (message?: string) => void;
  setAmountOfMaterials: (amountOfMaterials: number) => void;
  setCurrentMaterialNbr: (currentMaterial: number) => void;
  videoElementRef: React.RefObject<HTMLVideoElement | null>;
  displayUserMessage: (options: Omit<SnackbarOptions, 'id'>) => void;
  setShowControls: (show: boolean) => void;
  setFloors: (floors: number) => void;
  setLayoutView: (isLayoutView: boolean) => void;
  setDecoration: (data?: DecoData) => void;
  setDecorationPlacement: (data?: DecoPosition) => void;
  showDownloadPictureDialog: (dialog: Partial<DialogState>) => void;
  onLayoutUpdated: (id?: number) => void;
}

class ThreeDStreamingClient {
  private onDisconnect: Array<() => void> = [];
  private setVideoSrc: (newSrc: MediaStream) => void;
  private setApartmentReady: (apartmentReady: boolean) => void;
  private setLoadingMessage: (message?: string) => void;
  private setAmountOfMaterials: (amountOfMaterials: number) => void;
  private setCurrentMaterialNbr: (currentMaterial: number) => void;
  private setErrorMessage: (message: string) => void;
  private setShowControls: (show: boolean) => void;
  private setFloors: (floors: number) => void;
  private setLayoutView: (isLayoutView: boolean) => void;
  private setDecoration: (data: DecoData | undefined) => void;
  private setDecorationPlacement: (data: DecoPosition | undefined) => void;
  private onLayoutUpdated: (id?: number) => void;
  private displayUserMessage: (options: Omit<SnackbarOptions, 'id'>) => void;
  private showDownloadPictureDialog: ((dialog: Partial<DialogState>) => void) | null = null;
  private videoElementRef: React.RefObject<HTMLVideoElement | null> | null = null;

  private myPeerId = '';
  private peerConnection: RTCPeerConnection | null = null;
  public inputChannel: null | RTCDataChannel = null;
  private timeoutId: number | null = null;
  private queueTimer: number | null = null;
  private dlTimer: number | null = null;
  
  private downloads = 0;
  private completed = 0;
  
  private peerConnectionConfig: RTCConfiguration = {
    iceServers: [],
    iceTransportPolicy: 'relay'
  };
  private websocket: WebSocket | null = null;
  private isWsOpen = false;

  constructor(options: ThreeDStreamingClientOptions) {
    this.onDisconnect = options.onDisconnect || [];
    this.setVideoSrc = options.setVideoSrc;
    this.setApartmentReady = options.setApartmentReady;
    this.setLoadingMessage = options.setLoadingMessage;
    this.setErrorMessage = options.setErrorMessage;
    this.setAmountOfMaterials = options.setAmountOfMaterials;
    this.setCurrentMaterialNbr = options.setCurrentMaterialNbr;
    this.videoElementRef = options.videoElementRef || null;
    this.displayUserMessage = options.displayUserMessage;
    this.setShowControls = options.setShowControls;
    this.setFloors = options.setFloors;
    this.setLayoutView = options.setLayoutView;
    this.setDecoration = options.setDecoration;
    this.setDecorationPlacement = options.setDecorationPlacement;
    this.showDownloadPictureDialog = options.showDownloadPictureDialog;
    this.onLayoutUpdated = options.onLayoutUpdated;
  }

  public start = async () => {
    this.startTimer();

    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      event.returnValue = '';
    });

    window.addEventListener('unload', (event) => {
      if (this.isWsOpen) this.disconnect();
    });

    window.addEventListener('instructionsClosed', (event) => {
      this.registerControls();
    });

    this.displayUserMessage({
      message: t('threeDStatus.launchingThreeD'),
      time: 5000
    });

    await this.setupWSConnection();

    while (!this.isWsOpen) {
      await sleep(100);
    }

    this.websocket?.send(
      JSON.stringify({
        type: 'peers'
      })
    );
    
    if(!this.queueTimer)
    {
      this.queueTimer = window.setTimeout(() => {
        this.setLoadingMessage(t("threeDStatus.youAreQueueing"))        
      }, 500)
    }
  };

  private async setupInputChannel() {
    if (this.peerConnection) {
      this.inputChannel = this.peerConnection.createDataChannel('inputChannel');
      this.inputChannel.onmessage = async (message) => {
        const data: InputChannelMessageType = JSON.parse(message.data);
        switch (data.id) {
          case InputChannelEvent.DOWNLOAD_PROGRESS:

            this.downloads = data.allDownloads;
            this.completed = data.completed;
            // This message can be triggered hundreds of times per second, so trigger the update at intervals to preserve performance.
            if(!this.dlTimer)
            {
              // Initial message
              this.setLoadingMessage(`${t('threeDStatus.downloadingMaterials')} ${this.completed} / ${this.downloads}`)
              // Intervals
              this.dlTimer = window.setInterval(() => {
                this.setLoadingMessage(`${t('threeDStatus.downloadingMaterials')} ${this.completed} / ${this.downloads}`)                
              }, 100)
            }
            
            // Finalize message
            if(this.downloads <= this.completed)
            {
              window.clearInterval(this.dlTimer);
              this.dlTimer = null;
              // Final message before clearing
              this.setLoadingMessage(`${t('threeDStatus.downloadingMaterials')} ${this.completed} / ${this.downloads}`)
              window.setTimeout(() => {this.setLoadingMessage(undefined);}, 3000);              
            }
            break;
          case InputChannelEvent.MATERIALS_LOADED:
            this.displayUserMessage({
              message: t('threeDStatus.downloadedMaterials'),
              time: 3000
            });
            break;
          default:
            break;
        }
      };
      this.inputChannel.onopen = async () => {
        await sleep(100);
        this.websocket?.send(
          JSON.stringify({
            type: 'message',
            connectionId: this.myPeerId,
            data: {
              location: `?${new URLSearchParams(
                getStorageParams() as Record<string, string>
              ).toString()}`
            }
          })
        );
      };
    } else {
      throw new Error('Trying to setup inputChannel without peer connection.');
    }
  }

  private async setupPeerConnection() {
    this.peerConnection = new RTCPeerConnection(this.peerConnectionConfig);
    this.peerConnection.ontrack = this.ontrack.bind(this);
    this.peerConnection.onicecandidate = (e) => {
      if (e.candidate) {
        this.websocket?.send(
          JSON.stringify({
            type: 'candidate',
            from: this.myPeerId,
            data: {
              candidate: e.candidate.candidate,
              sdpMLineIndex: e.candidate.sdpMLineIndex,
              sdpMid: e.candidate.sdpMid,
              connectionId: this.myPeerId
            }
          })
        );
      }
    };
    this.peerConnection.addTransceiver('video', { direction: 'recvonly' });
  }

  private async setupWSConnection() {
    if (!wsSignalingUrl)
      throw new Error('Missing environment variable: wsSignalingUrl (WebSocket Signaling Url)');

    this.websocket = new WebSocket(`${wsSignalingUrl}`);
    this.websocket.onopen = () => {
      this.isWsOpen = true;
    };

    this.websocket.onclose = () => {
      this.quit();
      this.isWsOpen = false;
    };

    this.websocket.onmessage = (event) => {
      const msg = JSON.parse(event.data);
      switch (msg.type) {
        case 'answer':
          this.addSessionDescription({ ...msg, type: 'answer' });
          break;
        case 'candidate':
          this.onGotCandidate(msg);
          break;
        case 'peers':
          if(this.queueTimer)
          {
            window.clearTimeout(this.queueTimer);
          }
          this.setLoadingMessage(t('threeDStatus.loadingYourHome'));
          this.onPeer(msg);
          break;
        case 'connect':
          this.peerConnectionConfig.iceServers?.push({
            urls: msg.turnConfig.Address,
            credential: msg.turnConfig.Password,
            username: msg.turnConfig.Username,
            credentialType: 'password'
          } as RTCIceServer);
          this.handleSignalConnect();
          break;
        case 'error':
          switch (msg.data.userMessage) {
            case 'connection_error':
              this.setErrorMessage(t('error.connection_error'));
              console.error(msg.data.devMessage);
              break;
            case 'project_error':
              this.setErrorMessage(t('error.project_error'));
              console.error(msg.data.devMessage);
              break;
            case 'flow_error':
              console.error(msg.data.devMessage);
              break;
            default:
              console.log('error from websocket');
              break;
          }
          break;
        case 'ping':
          this.replyToKeepAliveQuery();
          break;
        case 'streamReady':
          this.displayUserMessage({
            message: t('threeDStatus.apartmentReady'),
            time: 3000
          });
          this.setApartmentReady(true);
          this.setFloors(msg.data.floorCount);
          break;
        case 'screenshot':
          this.showDownloadPictureDialog?.({
            file: {
              file: msg.data,
              name: `HD3D-screenshot-${format(new Date(), 'dd-MM-yyyy_kk-mm')}.png`
            },
            title: t('pictureReadyForDownload'),
            show: true
          });
          break;
        case 'cameraChanged':
          this.handleCameraChange(msg);
          break;
        case 'decoSelected': {
          const deco = msg.data;
          
          if(!deco)
          {
            this.setDecoration(undefined);
            return;
          }
          
          this.setDecoration({
            materialId: deco?.materialId,
            identifier: deco?.identifier,
            ...this.adjustPlacement(deco)
          });
          }
          break;
        case 'decoPlacementUpdated': {
          const placement = msg.data;          
          if(!placement)
          {
            this.setDecorationPlacement(undefined);
            return;
          }          
          
          this.setDecorationPlacement(this.adjustPlacement(placement))
        }
        break;
        case 'newLayoutCreated':{
          const newLayoutId = msg.data;
          this.onLayoutUpdated(newLayoutId);
        }
          break;
        case 'layoutSaved':{
          this.displayUserMessage({
            message: t('decoration.layout.saving'),
            time: 5000
          })
        }
        break;
        case 'message':
          switch (msg.contentType) {
            case 'message': // contentType : message is currently (26.1.2022) identifier for token_expired. Will be changed to contentType : token_expired
            case 'token_expired':
              this.displayUserMessage({
                message: t('threeDStatus.guestLinkTokenExpired'),
                time: 5000
              });
              break;
            default:
              console.log('Valid contentType for message not found');
              break;
          }
          break;
        default:
          console.log(msg);
          break;
      }
    };
  }

  private onPeer(msg: { connectionId: string; type: string }) {
    const connectionId = msg.connectionId;
    this.myPeerId = connectionId;

    this.websocket?.send(JSON.stringify({ type: 'connect', connectionId: connectionId }));
  }

  private async handleSignalConnect() {
    await this.setupPeerConnection();
    await this.setupInputChannel();
    await this.onConnect();
  }

  private async onConnect() {
    if (this.peerConnection) {
      const offer = await this.peerConnection.createOffer();
      const desc = new RTCSessionDescription({ sdp: offer.sdp, type: 'offer' });
      this.peerConnection.setLocalDescription(desc);
      this.setLoadingMessage(t('threeDStatus.loadingYourHome'));

      while (!this.isWsOpen) {
        await sleep(100);
      }
      this.websocket?.send(
        JSON.stringify({
          type: 'offer',
          from: this.myPeerId,
          data: {
            sdp: offer.sdp,
            connectionId: this.myPeerId
          }
        })
      );
    }
  }

  public quit() {
    this.disconnect();
    if (this.timeoutId) window.clearTimeout(this.timeoutId);
  }

  public resetCamera() {
    try {
      this.websocket?.send(
        JSON.stringify({
          type: 'cameraChanged',
          data: {
            cameraMode: ControlType.CAMERA_RESET
          }
        })
      );
      logActionsLegacy('apartment_layout_visible', 'Apartment Layout Visible', 'apartmentLayout_2');
      this.setLayoutView(true);
    } catch (e) {
      this.displayUserMessage({
        message: t('threeDStatus.resetCameraError'),
        isError: true,
        time: 5000
      });
    }
  }

  public handleCameraChange(msg: WebSocketCameraChangedMessage) {
    this.setShowControls(msg.data?.cameraMode !== CameraModes.DiveCamera);
    if (msg.data?.cameraMode === CameraModes.DiveCamera) {
      this.setLayoutView(false);
    } else if (msg.data?.cameraMode === CameraModes.FreeRoam) {
      logActionsLegacy('enter_apartment', 'User Clicked inside apartment', 'apartment_1');
    }
  }
  
  public adjustPlacement(data: DecoPosition)
  {    
    const clientRect = this.videoElementRef?.current?.getBoundingClientRect();
    if(clientRect) {
      const videoWidth = this.videoElementRef?.current?.videoWidth ?? 1920;
      const scale = clientRect.width / videoWidth;
      const x = (data.x * scale) + clientRect.left;
      const y = (data.y * scale) + clientRect.top;
      return {x, y}
    }
    return { x: data.x, y: data.y };
  }
  
  public takePicture() {
    try {
      this.websocket?.send(
        JSON.stringify({
          type: 'screenshot'
        })
      );
    } catch (e) {
      this.displayUserMessage({
        message: t('threeDStatus.takePictureError'),
        isError: true,
        time: 5000
      });
    }
  }

  public move(controlType: MoveType) {
    try {
      const data = new DataView(new ArrayBuffer(3));
      data.setUint8(0, 4);
      data.setInt16(1, controlType, true);
      this.sendInputChannelData(data);
    } catch (e) {
      this.displayUserMessage({
        message: t('threeDStatus.moveError'),
        isError: true,
        time: 5000
      });
    }
  }
  
  public sendAngle(angle: number)
  {
    const data = new DataView(new ArrayBuffer(5))
    data.setUint8(0, 6);
    data.setFloat32(1, angle, true);
    this.sendInputChannelData(data);
  }
  
  public sendPosition(x: number, y: number)
  {
    // Adjust position to stream view
    const videoElement = this.videoElementRef?.current;
    if(!videoElement)
    {
      return;
    }
    // Video element sizes
    const client = videoElement.getBoundingClientRect();    
    const scale = client.width / 1920; // TODO: make client size dynamic
    const originX = client.left;
    const originY = client.top;
    // Adjusted positions
    const ax = (x - originX) / scale;
    const ay = videoElement.videoHeight - (y - originY) / scale;
    
    const data = new DataView(new ArrayBuffer(5))
    data.setUint8(0, 7);
    data.setUint16(1, ax, true);
    data.setUint16(3, ay, true);
    this.sendInputChannelData(data);
  }

  public ChangeToDecoMode(deco: Decoration){
    const msg = JSON.stringify({
      id: 0,
      data: {
        materialId: deco.id,
        assetGuid: deco.assetGuid,
        systemLabels: deco.systemLabels
      }
    });
    this.sendInputChannelData(msg)
  }
  
  public ChangeDecoInput(input: DecorationInputState, identifier?: string){
    console.log(`Identifier is: ${identifier}`)
    const msg = JSON.stringify({
      id: 1,
      data: {
        input: input,
        identifier: identifier
      }
    })
    this.sendInputChannelData(msg);
  }
  
  public selectTheme(themeId: number) {
    this.websocket?.send(
      JSON.stringify({
        type: 'themeChange',
        id: 1,
        data: {
          id: themeId
        }
      })
    );
  }

  public selectMaterial(materialId: string, bundleId: number) {
    this.websocket?.send(
      JSON.stringify({
        type: 'materialChange',
        data: {
          bundleId: bundleId,
          assetGuid: materialId
        }
      })
    );
  }

  public loadDecoration(deco: Decoration){
    this.websocket?.send(JSON.stringify({
      type: 'loadDeco',
      data: deco
    }))
  }
  
  public moveDecoration(input: DecorationInputState){
    this.websocket?.send(JSON.stringify({
      type: 'moveDeco',
      data: input,
    }))
  }
  
  public selectLayout(layout?: Layout){
    if(this.websocket?.readyState === this.websocket?.OPEN) {
      this.websocket?.send(JSON.stringify({
        type: 'layoutSelected',
        data: layout
      }))
    }
  }
  
  public saveLayout(){
    if(this.websocket?.readyState === this.websocket?.OPEN){
      this.websocket?.send(JSON.stringify({
        type: 'saveLayout',
      }))
    }
  }
  
  public createLayout(id?: number) {
    if(this.websocket?.readyState === this.websocket?.OPEN){
      this.websocket?.send(JSON.stringify({
        type: 'createLayout',
        data: {
          from: id // Create new layout using layout with this id as base. Undefined to start from scratch
        }
      }))
    }
  }
  
  public setCameraMode(mode: number){
    if(this.websocket?.readyState === this.websocket?.OPEN) {
      this.websocket?.send(JSON.stringify({
        type: 'cameraChanged',
        data: {cameraMode: mode}
      }))
    }
  }
  
  public selectFloor(floorChangeDirection: FloorChangeDirection) {
    if(this.websocket?.readyState === this.websocket?.OPEN) {
      this.websocket?.send(
        JSON.stringify({
          type: 'storeyChange',
          data: {
            floorIndex: null,
            direction: floorChangeDirection
          }
        })
      );
    }
  }

  private disconnect() {
    if (this.isWsOpen)
      this.websocket?.send(
        JSON.stringify({
          type: 'disconnect',
          connectionId: this.myPeerId
        })
      );
    this.isWsOpen = false;
    this.onDisconnect.forEach((onDisconnectFunc) => onDisconnectFunc());
  }

  private replyToKeepAliveQuery = () => {
    if (this.isWsOpen)
      this.websocket?.send(
        JSON.stringify({
          type: 'pong',
          connectionId: this.myPeerId
        })
      );
  };

  private async addSessionDescription(msg: {
    from: string;
    data: { sdp: string; polite: boolean };
    type: RTCSdpType;
  }) {
    const description = new RTCSessionDescription({
      sdp: msg.data.sdp,
      type: msg.type
    });
    await this.peerConnection?.setRemoteDescription(description);
  }

  private async onGotCandidate(msg: { data: RTCIceCandidate }) {
    const iceCandidate = new RTCIceCandidate({
      candidate: msg.data.candidate,
      sdpMid: msg.data.sdpMid,
      sdpMLineIndex: msg.data.sdpMLineIndex
    });

    try {
      await this.peerConnection?.addIceCandidate(iceCandidate);
    } catch (e) {
      console.error(e);
    }
  }

  private registerControls() {
    const videoElement = this.videoElementRef?.current;

    if (videoElement) {
      registerMouseEvents(this.sendInputChannelData.bind(this), videoElement);
      registerKeyboardEvents(this.sendInputChannelData.bind(this));
    }
  }

  private sendInputChannelData(data: ArrayBuffer | string | DataView) {
    if (this.inputChannel?.readyState === 'open') this.inputChannel.send(data as ArrayBuffer);
  }

  private startTimer() {
    if (!this.timeoutId) {
      window.addEventListener('mousemove', this.resetTimer.bind(this), false);
      window.addEventListener('mousedown', this.resetTimer.bind(this), false);
      window.addEventListener('keypress', this.resetTimer.bind(this), false);
      window.addEventListener('touchmove', this.resetTimer.bind(this), false);
    }

    this.timeoutId = window.setTimeout(this.disconnect.bind(this), THREE_D_TIMEOUT);
  }

  private resetTimer() {
    if (this.timeoutId) window.clearTimeout(this.timeoutId);
    this.startTimer();
  }

  private ontrack(event: RTCTrackEvent) {
    this.setVideoSrc?.(event.streams[0]);
  }
}

const composeDisplayUserMessage =
  (dispatch: React.Dispatch<SnackbarStateActionType>) =>
  (snackbarOptions: Omit<SnackbarOptions, 'id'>) =>
    dispatch({
      type: SnackbarStateAction.SHOW_SNACKBAR_ALERT,
      data: snackbarOptions
    });

const composeDownloadPictureDialog =
  (
    dispatch: React.Dispatch<DialogStateActionType>,
    threeDDispatch: React.Dispatch<ThreeDStateActionType>
  ) =>
  (dialog: Partial<DialogState>) => {
    dispatch({ type: DialogStateAction.OPEN_DIALOG, data: dialog });
    threeDDispatch({
      type: ThreeDStateAction.SET_DOWNLOADING_PICTURE,
      data: false
    });
  };

interface UseThreeDStreamingReturnInterface {
  videoSrc: MediaStream | null;
  move: ((type: MoveType, stop?: boolean) => void) | null;
  apartmentReady: boolean;
  loadingMessage?: string;
  amountOfMaterials: number;
  currentMaterialNbr: number;
  errorMessage: string;
  resetCamera: () => void;
  selectTheme: (themeId: number) => void;
  previewMaterial: (materialId: string, bundleId: number) => void;
  showControls: boolean;
  floors: number;
  isLayoutView: boolean;
  selectFloor: (floorChangeDirection: FloorChangeDirection) => void;
  takePicture: () => void;
  saveDecorations: () => void;
  createNewLayout: (id?: number) => void;
  rotateDecoration: (angle: number) => void;
  sendPosition: (x: number, y: number) => void;
}

export const useThreeDStreaming = (
  videoElementRef: React.RefObject<HTMLVideoElement | null>,
  onDisconnect?: () => void
): UseThreeDStreamingReturnInterface => {
  const [videoSrc, setVideoSrc] = useState<MediaStream | null>(null);
  const [apartmentReady, setApartmentReady] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [floors, setFloors] = useState(1);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);
  const [isLayoutView, setLayoutView] = useState<boolean>(true);
  const [amountOfMaterials, setAmountOfMaterials] = useState<number>(0);
  const [currentMaterialNbr, setCurrentMaterialNbr] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [deco, setDecoration] = useState<DecoData | undefined>(undefined)
  const [placement, setDecorationPlacement] = useState<DecoPosition | undefined>(undefined);
  const [newLayoutId, onLayoutUpdated] = useState<number | undefined>()

  const { dispatch } = useContext(SnackbarStateContext);
  const { dispatch: dialogDispatch } = useContext(DialogStateContext);
  const { dispatch: threeDDispatch } = useContext(ThreeDStateContext);
  const { stylesState } = useContext(StylesStateContext);
  const { decoState,dispatch: decoDispatch } = useContext(DecoStateContext);
  const { dispatch: snackDispatch } = useContext(SnackbarStateContext)
  const { globalState, dispatch: globalStateDispatch } = useContext(GlobalStateContext);
  const displayUserMessage = composeDisplayUserMessage(dispatch);
  const showDownloadPictureDialog = composeDownloadPictureDialog(dialogDispatch, threeDDispatch);

  const threeDStreamingClient = new ThreeDStreamingClient({
    setVideoSrc,
    setApartmentReady,
    videoElementRef,
    setLoadingMessage,
    setAmountOfMaterials,
    setCurrentMaterialNbr,
    setErrorMessage,
    displayUserMessage,
    setShowControls,
    setFloors,
    setLayoutView,
    setDecoration,
    setDecorationPlacement,
    onLayoutUpdated,
    showDownloadPictureDialog,
    onDisconnect: onDisconnect ? [onDisconnect] : []
  });

  const move = useCallback(
    (controlType: MoveType) => threeDStreamingClient.move(controlType),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threeDStreamingClient.move]
  );

  const resetCamera = useCallback(
    () => threeDStreamingClient.resetCamera(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threeDStreamingClient.resetCamera]
  );

  const rotateDecoration = useCallback((angle: number) => {
    threeDStreamingClient.sendAngle(angle);
  },[threeDStreamingClient.sendAngle])
  
  const sendPosition = useCallback((x: number, y: number) => {
    threeDStreamingClient.sendPosition(x,y);
  }, [threeDStreamingClient.sendPosition])
  
  const selectTheme = useCallback(
    (themeId: number) => threeDStreamingClient.selectTheme(themeId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threeDStreamingClient.selectTheme]
  );

  const previewMaterial = useCallback(
    (materialId: string, bundleId: number) =>
      threeDStreamingClient.selectMaterial(materialId, bundleId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threeDStreamingClient.selectMaterial]
  );

  const selectFloor = useCallback(
    (floorChangeDirection: FloorChangeDirection) =>
      threeDStreamingClient.selectFloor(floorChangeDirection),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threeDStreamingClient.selectFloor]
  );

  const takePicture = useCallback(
    () => {
      threeDStreamingClient.takePicture();
      threeDDispatch({
        type: ThreeDStateAction.SET_DOWNLOADING_PICTURE,
        data: true
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threeDStreamingClient.takePicture]
  );
  
  const loadDecoration = useCallback((deco: Decoration) => {
    threeDStreamingClient.ChangeToDecoMode(deco);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  const moveDecoration = useCallback((input: DecorationInputState, identifier?: string) => {
    threeDStreamingClient.ChangeDecoInput(input, identifier)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  const selectLayout = useCallback((layout?: Layout) => {
    threeDStreamingClient.selectLayout(layout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const setCameraMode = useCallback((mode: number) => {
    threeDStreamingClient.setCameraMode(mode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const saveDecorations = useCallback(() => {
    threeDStreamingClient.saveLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const createNewLayout = useCallback((id?: number) =>
  {
    threeDStreamingClient.createLayout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    decoDispatch({
      type: DecoStateAction.LAYOUT_CREATED,
      data: newLayoutId
    })
  }, [newLayoutId])
  
  useEffect(() => {
    threeDStreamingClient?.start();
    return () => threeDStreamingClient?.quit?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!globalState.token && apartmentReady) setLoadingMessage('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apartmentReady]);

  useEffect(() => {
    if (stylesState.selectedBundle?.previewedMaterial) {
      previewMaterial(
        stylesState.selectedBundle.previewedMaterial.representation.assetDbUuid,
        stylesState.selectedBundle.id
      );
    } else if (!stylesState.selectedBundle) {
      const bundle = stylesState.bundles?.find(
        (b) => b.previewedMaterial?.id && b.previewedMaterial?.id !== b.selectedMaterial?.id
      );
      if (bundle?.selectedMaterial)
        previewMaterial(bundle.selectedMaterial.representation.assetDbUuid, bundle.id);
    }
  }, [
    stylesState.bundles,
    stylesState.selectedBundle,
    stylesState.selectedBundle?.previewedMaterial,
    previewMaterial
  ]);
  
  useEffect(() => {
    if(decoState.decorationInput !== undefined)
    {
      moveDecoration(decoState.decorationInput, decoState.selectedDecorationIdentifier)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decoState.decorationInput])

  useEffect(() => {
    if(decoState.loadDecoration)
    {
      loadDecoration(decoState.loadDecoration);
      // Set loaded deco to empty. Server will respond once this has loaded, and it's set to selected then.
      //decoDispatch({type: DecoStateAction.LOAD_DECO, data: undefined})
    }
  },[decoState.loadDecoration])
  
  useEffect(() => {
    if(decoState.isInitiating)
    {
      return;
    }
    selectLayout(decoState.selectedLayout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[decoState.selectedLayout])
  
  useEffect(() => {
    
    if(globalState.cameraMode !== undefined) {
      setCameraMode(globalState.cameraMode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[globalState.cameraMode])
  
  useEffect(() => {
    if(showControls)
    {
      globalStateDispatch({
        type: GlobalStateAction.SET_CAMERA_MODE, 
        data: globalState.previousCameraMode
      })
    }
  }, [showControls])
  
  useEffect(() => {
    decoDispatch({type: DecoStateAction.SELECT_DECO, data: deco})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[deco])
  
  useEffect(() => {
    decoDispatch({type: DecoStateAction.PLACE_DECO, data: placement}, )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placement])
  
  useEffect(() => {
    const isError = Boolean(errorMessage);
    const message = isError ?  errorMessage : loadingMessage;    
    snackDispatch({
      type: SnackbarStateAction.SHOW_SNACKBAR_ALERT,
      data: {message: message, isError: isError}
    });   
  }, [loadingMessage, errorMessage])
  
  return {
    videoSrc,
    move,
    apartmentReady,
    loadingMessage,
    amountOfMaterials,
    currentMaterialNbr,
    errorMessage,
    resetCamera,
    selectTheme,
    previewMaterial,
    showControls,
    floors,
    isLayoutView,
    selectFloor,
    takePicture,
    saveDecorations,
    createNewLayout,
    rotateDecoration,
    sendPosition
  };
};
