import React, {useState} from "react";
import SettingsStyles from "./Settings.styles";
import {Typography} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

interface props{
    label: string;
    caption?: string;
    action: () => void;
    checked: boolean;
}

const SettingsCheckbox: React.FC<props> = ({label,action, caption, checked}:props) => {
    
    const [isChecked, setChecked] = useState<boolean>(checked);
    
    const classes = SettingsStyles();
    
    const onClick = () => {
        setChecked(!isChecked);
        action();
    }
    
    return(<div className={classes.toggleButton} onClick={onClick}>
        <div className={classes.buttonContent}>
            <Typography className={classes.settingText} >{label}</Typography>
            {caption && <Typography variant={'body2'}>{caption}</Typography>}
        </div>
        <Checkbox className={classes.checkbox}
            checked={isChecked}
            disableRipple
            disableFocusRipple
        />
    </div>)
}

export default SettingsCheckbox;