import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

interface props{
    movementKeysVisible?: boolean
}
const controlsStyles = ({movementKeysVisible}:props) => makeStyles((theme: Theme) =>  
  createStyles({
    iconStyle: {
      height: "50px", 
      width: "50px",
      color: 'black',
      [theme.breakpoints.down('sm')]: {
        height: '40px',
        width: '40px'
      }
    },
    controls: {
      zIndex: 50,
      display: 'inline-flex',
      width: 'auto', // amount of buttons * button width + margins  
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      position: 'absolute',
      backgroundColor: 'transparent',
        pointerEvents: 'none'
    },
    smallScreenControls: {
      zIndex: 50,
      marginBottom: theme.spacing(2),
        marginRight: movementKeysVisible ? undefined : theme.spacing(2),
      backgroundColor: 'transparent',
      alignSelf: 'end',
      justifyContent: !movementKeysVisible ? 'end' : 'center',
      // To iOS & Safari
      '@media not all and (min-resolution:.001dpcm)': {
        position: 'absolute',
        bottom: theme.spacing(1),
      }, 
        pointerEvents: 'none'
    },
    smallScreenGridItemLeft:{
      alignSelf: 'end',
      // To iOS & Safari
      '@media not all and (min-resolution:.001dpcm)': {
        position: 'absolute',
        bottom: theme.spacing(0),
        left: theme.spacing(1)
      }
    },
    smallScreenGridItemRight:{
      alignSelf: 'end',
      // To iOS & Safari
      '@media not all and (min-resolution:.001dpcm)': {
        position: 'absolute',
        bottom: theme.spacing(0),
        right: theme.spacing(1)
      }
    },
    actions: {
      alignSelf: 'end',
      marginRight: theme.spacing(4),
      zIndex: 100,
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        zIndex: 100
      }
    },
    layoutViewControls: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      position: 'absolute'
    }
  }),
);

export default controlsStyles