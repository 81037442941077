import { useContext, useEffect } from "react"
import { getExtraCost } from "../axios"
import { GetExtraCostParams } from "../axios/instances/interfaces"
import { GlobalStateContext } from "../store/globalState/GlobalStateProvider"
import {
  StylesStateAction,
  StylesStateActionType,
} from "../store/stylesState/interfaces"
import { StylesStateContext } from "../store/stylesState/StylesStateProvider"

interface ExtraCostFetcherReturnValue {
  fetchExtraCostForApartment: () => void
}

export const composeDoFetch =
  (params: GetExtraCostParams, dispatch: React.Dispatch<StylesStateActionType>) =>
  async (): Promise<void> => {
    dispatch({ type: StylesStateAction.SET_FETCHING_EXTRACOST, data: true })
    const response = await getExtraCost(params)
    dispatch({
      type: StylesStateAction.SET_EXTRACOST,
      data: response.data.data.cost,
    })
    dispatch({ type: StylesStateAction.SET_FETCHING_EXTRACOST, data: false })
  }

export const useExtraCostFetcher = (): ExtraCostFetcherReturnValue => {
  const { globalState } = useContext(GlobalStateContext)
  const { stylesState, dispatch } = useContext(StylesStateContext)

  if (
    !globalState.startParameters.apartmentId ||
    !globalState.startParameters.api ||
    !globalState.startParameters.company ||
    !globalState.startParameters.projectId ||
    !globalState.startParameters.schema
  )
    throw new Error("Start parameters are missing in extraCosts fetcher.")
  // if (!globalState.token) throw new Error("Token missing in extraCosts fetcher.")

  const params: GetExtraCostParams = {
    apartmentId: globalState.startParameters.apartmentId,
    apiUrl: globalState.startParameters.api,
    organisationId: globalState.startParameters.company,
    projectId: globalState.startParameters.projectId,
    tenantId: globalState.startParameters.schema,
    token: globalState.token,
  }

  const doFetch = composeDoFetch(params, dispatch)

  useEffect(() => {
    if (globalState.token)
      doFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stylesState.selectedBundle, stylesState.packageSets])

  return { fetchExtraCostForApartment: () => doFetch() }
}

export default useExtraCostFetcher
