
export interface SettingsState{
    movementKeysVisible: boolean,
    ignoreDecorationTutorial: boolean,
}

export enum SettingsStateAction{
    SET_MOVEMENT_KEY_STATE,
    SET_IGNORE_DECORATION_TUTORIAL_STATE
}

// Local storage keys
export enum SettingsKey{
    MOVEMENT_KEY_STATE = "MOVEMENT_KEY_STATE",
    SET_IGNORE_DECORATION_TUTORIAL_STATE = "SET_IGNORE_DECORATION_TUTORIAL_STATE"
}

export interface SetMovementKeyAction{
    type: SettingsStateAction.SET_MOVEMENT_KEY_STATE,
    data: boolean,
}

export interface SetDecorationTutorialAction{
    type: SettingsStateAction.SET_IGNORE_DECORATION_TUTORIAL_STATE,
    data: boolean,
}

export type SettingsStateActionType = 
    SetMovementKeyAction | 
    SetDecorationTutorialAction;

export type SettingsStateReducerType = (state: SettingsState, action: SettingsStateActionType) => SettingsState;