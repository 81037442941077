import { GlobalStateContext } from '../store/globalState/GlobalStateProvider';
import { useContext, useEffect, useState } from 'react';
import { PaletteType } from '../store/themes/types';

export const useGetCustomizationSettings = () => {
  const [parsedSettings, setParsedSettings] = useState<PaletteType>();
  const [assets, setAssets] = useState<{
    logo: string;
    headerImg: string;
  }>();
  const { globalState } = useContext(GlobalStateContext);
  const customizationSettings = globalState.tenantSettings?.themeCustomizationSettings;

  useEffect(() => {
    if (customizationSettings) {
      setParsedSettings({
        primary: customizationSettings?.palette?.main || '#B7835F',
        secondary: customizationSettings?.palette?.secondary || '#EFEEE4',
        default: customizationSettings?.palette?.navigationBar || '#05061B',
        accordionBackground: customizationSettings?.palette?.accordionBackground || '#F2F4F7',
      });
      setAssets(customizationSettings.assets);
    }
  }, [customizationSettings]);

  return { parsedSettings, assets };
}