import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const instructionsStyles = makeStyles((theme: Theme) =>  
  createStyles({
    root: {
      width: '30%',
      maxWidth: '810px',
      minWidth: '400px',
      borderRadius: theme.spacing(2),
      borderStyle: 'none',
      boxShadow: 'none',
      zIndex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      '@media screen and (max-height: 600px) and (orientation: landscape)': { 
        width: '100%',
        height: '100%'
      },
    },
    instructionsContainer: {
      '@media screen and (max-height: 600px) and (orientation: landscape)': { 
        height: '100%',
        marginTop: 0,
        padding: 0
      },
    },
    stepIndicatorGriditem: {
      padding: 0,
      margin: 0,
      alignContent: 'center',
      textAlign: 'center',
      '@media screen and (max-height: 600px) and (orientation: landscape)': {
        height: '10px'
      },
    },
    gridItem: {
      marginTop: theme.spacing(3),
      alignContent: 'center',
      textAlign: 'center',
      '@media screen and (max-height: 600px) and (orientation: landscape)': { 
        marginTop: 0,
        padding: 0
      },
    },
    buttonContainer: {
      '@media screen and (max-height: 600px) and (orientation: landscape)': { 
        alignSelf: 'flex-end'
      },
    },
  }),
);

export default instructionsStyles