export enum ThreeDStateAction {
  DISMISS_INSTRUCTIONS = "DISMISS_INSTRUCTIONS",
  TOGGLE_SETTINGS_OPEN = "TOGGLE_SETTINGS_OPEN",
  TOGGLE_THEMES_AND_MATERIALS_OPEN = "TOGGLE_THEMES_AND_MATERIALS_OPEN",
  RESET_STORE = "RESET_STORE",
  SET_DOWNLOADING_PICTURE = "SET_DOWNLOADING_PICTURE",
  SET_OPEN_HUBSPOTFORM = "SET_OPEN_HUBSPOTFORM",
}

export interface ThreeDState {
  instructionsDismissed: boolean
  settingsOpen: boolean
  themesAndMaterialsOpen: boolean
  downloadingPicture: boolean
  openHubSpotForm: boolean
}

export interface DismissInstructionsAction {
  type: ThreeDStateAction.DISMISS_INSTRUCTIONS
}

export interface ToggleSettingsOpenAction {
  type: ThreeDStateAction.TOGGLE_SETTINGS_OPEN
}

export interface ToggleStylesOpenAction {
  type: ThreeDStateAction.TOGGLE_THEMES_AND_MATERIALS_OPEN
}

export interface ResetStoreAction {
  type: ThreeDStateAction.RESET_STORE
}

export interface SetDownloadingPictureAction {
  type: ThreeDStateAction.SET_DOWNLOADING_PICTURE
  data: boolean
}

export interface SetOpenHubSpotForm {
  type: ThreeDStateAction.SET_OPEN_HUBSPOTFORM
  data: boolean
}

export type ThreeDStateActionType =
  | DismissInstructionsAction
  | ToggleSettingsOpenAction
  | ToggleStylesOpenAction
  | ResetStoreAction
  | SetDownloadingPictureAction
  | SetOpenHubSpotForm

export type ThreeDStateReducerType = (
  state: ThreeDState,
  action: ThreeDStateActionType
) => ThreeDState
