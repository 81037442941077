import { Grid, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { t } from '../../../utils';
import InstructionButtons from './InstructionButtons';
import ProgressBar from './ProgressBar';
import SubPage from './SubPage';
import firstSubPageImage from '../../../images/tutorial/subPage1Image.png';
import secondSubPageImage from '../../../images/tutorial/subPage2Image.png';
import thirdSubPageImage from '../../../images/tutorial/subPage3Image.png';
import instructionsStyles from './Instructions.styles';
import useEventLogger from '../../../analytics/useEventLogger';

interface InstructionsProps {
  handleDismissInstructions: (lastStep: boolean) => void;
  disabled?: boolean;
}

const Instructions: React.FC<InstructionsProps> = ({ handleDismissInstructions, disabled }) => {
  const { logActions } = useEventLogger();
  const classes = instructionsStyles();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const amountOfSteps = 3;

  useEffect(() => {
    logActions('tutorial_begin', 'Tutorial', 'tutorial_0');
  }, []);

  const handleProceedToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const currentSubPage = () => {
    switch (currentStep) {
      case 0:
        return (
          <SubPage
            paragraphText={t('instructions.instructionsParagraph1')}
            image={firstSubPageImage}
          />
        );
      case 1:
        return (
          <SubPage
            paragraphText={t('instructions.instructionsParagraph2')}
            image={secondSubPageImage}
          />
        );
      case 2:
        return (
          <SubPage
            paragraphText={t('instructions.instructionsParagraph3')}
            image={thirdSubPageImage}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2} className={classes.instructionsContainer}>
        <Grid item xs={12} className={classes.gridItem}>
          {currentSubPage()}
        </Grid>
        <Grid item xs={12} className={classes.stepIndicatorGriditem}>
          <ProgressBar currentStep={currentStep} amountOfSteps={amountOfSteps} />
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          <InstructionButtons
            {...{ currentStep, handleDismissInstructions, handleProceedToNextStep, disabled }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Instructions;
