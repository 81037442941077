import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const languageSelectStyles = makeStyles((theme: Theme) => {
  return createStyles({
    languageSelect: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      backgroundColor: 'white',
      borderRadius: theme.spacing(1),
      '& .MuiSelect-icon':{
        margin: '8px'
      },
      [theme.breakpoints.down('sm')]: {
        height: '70px',
        width: '70px',
        margin: theme.spacing(1),
        textAlign: 'center',
        '& .MuiSelect-icon':{
          margin: 'auto',
          display: 'none'
        }, 
        '& .MuiIconButton-root':{
          fontSize: '2.5rem'
        }, 
      },
      zIndex: 50
    },
    root: {
      display: 'flex',
    },
    icon: { 
      color: 'black',
      padding: theme.spacing(1.5)
    },
    langName: { 
      color: 'black', 
      paddingRight: theme.spacing(4),
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      marginLeft: 0
   },
  });
});

export default languageSelectStyles;