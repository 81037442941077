import deepmerge from "deepmerge"
import { combineMerge } from "../utils/combineMerge"

interface Variant<Type> {
  variants?: Partial<{
    [x: string]: Variant<Type>
  }>
}

const variantsFromString = (variantsString: string): string[] =>
  variantsString?.split?.(" ") || []

const variantsFromObject = (variantsObj: Record<string, boolean>): string[] =>
  Object.entries(variantsObj).reduce((pre, [key, value]) => {
    if (value) pre.push(key)
    return pre
  }, [] as string[])

export const createVariantsArray = (
  variants: string | Record<string, boolean>
): string[] => {
  return typeof variants === "string"
    ? variantsFromString(variants)
    : variantsFromObject(variants)
}

/**
 * Gets variant styles from theme object containing variants property
 *
 * Variants will override eachother based on the order they are defined
 *
 * Returns base if variants is empty or undefined
 *
 * Variants can be either string (eg. "large hideOnMobile" )
 * or object of variant-boolean pairs. (eg. { large: true, hideOnMobile: true })
 */
export const getVariantStyles = <Type extends Variant<Type>>(
  base?: Type,
  variants?: string | Record<string, boolean>
): Type => {
  if (!base) return {} as Type

  const variantsArray = variants ? createVariantsArray(variants) : null

  if (!variantsArray?.length || !base.variants) return base

  const usedVariants = Object.entries(base.variants)
    .sort((a, b) =>
      variantsArray.indexOf(a[0]) > variantsArray.indexOf(b[0]) ? 1 : -1
    )
    .reduce((pre, [key, value]) => {
      if (variantsArray.includes(key) && value) pre.push(value)

      return pre
    }, [] as Variant<Type>[])

  return deepmerge.all([base, ...usedVariants], {
    arrayMerge: combineMerge,
  }) as Type
}
