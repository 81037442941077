import { createStyles, IconButton, makeStyles, Paper, Theme } from '@material-ui/core';

import React, { forwardRef } from 'react';
import { MoveType } from './threeDStreaming';

interface MovementButtonProps {
  /**
  * Button icon
  */
  icon: React.ReactNode;
  /**
  * Icon rotation in degrees
  */
  adjustBrightness?: boolean;
  onClick?: (type: MoveType) => void;
}

/**
 * Primary UI component for user interaction
 */
export const MovementButton: React.FC<MovementButtonProps> = forwardRef<HTMLButtonElement, MovementButtonProps>(
  (
    {
      icon,
      adjustBrightness, 
      onClick,
      ...controlButtonActions
    }, ref
  ) => {
    MovementButton.displayName = 'MovementButton';

    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        buttonBackground: {
          width: '70px',
          height: '70px',
          margin: theme.spacing(1),
          userSelect: 'none',
          [theme.breakpoints.down('sm')]: {
            width: '60px',
            height: '60px',
            margin: theme.spacing(0.25),
            marginBottom: theme.spacing(0.5)
          },
          borderRadius: theme.spacing(2)
        },
        button: {
          width: '100%',
          height: '100%',
          borderRadius: '15%',
          display: 'inline-table',
          backgroundColor: 'white',
          userSelect: 'none',
          filter: adjustBrightness ? 'brightness(0.8)' : undefined,
          '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(0.7)'
          },
        },
      }),
    );
    const classes = useStyles();

    return (
      <Paper className={classes.buttonBackground}>
        <IconButton className={classes.button} ref={ref} {...controlButtonActions} >
          {icon}
        </IconButton>
      </Paper>
    );
  }
)
