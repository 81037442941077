import React, {useContext, useEffect} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core";
import SelectionButton from "../common/SelectionButton";
import {DecorationPageCard} from "../Decorations/DecorationPageCard";
import MaterialOptionsPageCard from "../StylesAndMaterials/MaterialOptionsPageCard";
import OptionsStyles from "./Options.styles";
import HomeIcon from '@material-ui/icons/Home';
import {t} from "../../utils";
import {GlobalStateContext} from "../../store/globalState/GlobalStateProvider";
import {GlobalStateAction} from "../../store/globalState/interfaces";
import AppSettings from "../Settings/Settings";
import decoMenu from "../../images/menuImages/decoMenu.webp";
import stylesMenu from "../../images/menuImages/materialMenu.webp";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {DecoStateContext} from "../../store/decoState/DecoStateProvider";

interface props {
    OnContentSelected: (content?: JSX.Element) => void;
    createNewLayout: (id?: number) => void;
    selectTheme: (themeId: number) => void;
    saveDecorations?: () => void;
}

const Options: React.FC<props> = ({ OnContentSelected, selectTheme, saveDecorations, createNewLayout }: props) => {
    
    const classes = OptionsStyles();
    const {globalState, dispatch} = useContext(GlobalStateContext);
    const {decoState} = useContext(DecoStateContext);

    const showDecorating = globalState.tenantSettings.gb4dEnableDecorating 
        && (decoState.layoutOptions?.length); // Layout options tell us if the project has been established to deco DB, which it has to be.
    const showThemes =
        !globalState.tenantSettings.gb4dUxModes ||
        globalState.tenantSettings.gb4dUxModes.some((mode) => mode.showThemes);
    const showMaterials =
        !globalState.tenantSettings.gb4dUxModes ||
        globalState.tenantSettings.gb4dUxModes.some((mode) => mode.showMaterials) && globalState.token;
    
    useEffect(() => {

        dispatch({
            type: GlobalStateAction.SET_HEADER, data: {
                headerText: globalState.apartmentInfo.name ?? '',
                headerIcon: <HomeIcon/>
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return(
        <div className={classes.pageContainer} >
            {showThemes || showMaterials || showDecorating ? <div className={classes.contentOptions}>
                <Grid container spacing={2}>
                    {showThemes || showMaterials ?
                        <Grid item xs={12}>
                            <SelectionButton
                                className={classes.optionCard}
                                isCardButton={true}
                                isSelected={false}
                                selectionName={t('theming.styles')}
                                selectCaption={t('theming.selectRoomMaterials')}
                                selectionImageUrl={stylesMenu}
                                onSelect={() => {
                                    OnContentSelected(<MaterialOptionsPageCard
                                        selectTheme={selectTheme}/>)
                                }}
                            />
                        </Grid> : null}
                    {showDecorating ?
                        <Grid item xs={12}>
                            <SelectionButton
                                className={classes.optionCard}
                                isCardButton={true}
                                isSelected={false}
                                selectionName={t('decoration.decorate')}
                                selectCaption={t('decoration.info')}
                                selectionImageUrl={decoMenu}
                                onSelect={() =>
                                    OnContentSelected(<DecorationPageCard
                                        saveDecorations={saveDecorations}
                                        createNewLayout={createNewLayout}/>)
                                }/>
                        </Grid> : null
                    }
                </Grid>
            </div> : null}
            <Accordion className={classes.accordion} >
                <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon/>}>
                    <Typography className={classes.summaryText} variant={"body1"}>{t('settings.settings')}</Typography>
                </AccordionSummary>
                <AppSettings/>
            </Accordion>
        </div>
)
}

export default Options;