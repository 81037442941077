import { logEvent } from 'firebase/analytics';
import { analytics } from './analyticsUtils';
import { useContext } from 'react';
import { GlobalStateContext } from '../store/globalState/GlobalStateProvider';

const useEventLogger = () => {
  const { globalState } = useContext(GlobalStateContext);
  const logActions = (eventType: string, contentType: string, contentId: string) => {
    logEvent(analytics, eventType.toString(), {
      content_type: contentType,
      content_id: contentId,
      organisation: globalState.startParameters.company,
      project: globalState.startParameters.projectId,
      apartment: globalState.startParameters.apartmentId
    });
  };
  
  const logCustomEvent = (eventType: string, data: any) => {
    
    logEvent(analytics, eventType.toString(), {      
      organisation: globalState.startParameters.company,
      project: globalState.startParameters.projectId,
      apartment: globalState.startParameters.apartmentId,
      ...data
    });
  }

  return { logActions, logCustomEvent };
};

export default useEventLogger;
