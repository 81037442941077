import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import baseTheme from './base';
import { PaletteType } from './types';

const defaultTheme = (customPalette?: PaletteType) => {
  const themeProps: ThemeOptions = {
    ...baseTheme({
      palette: customPalette
    }),
    name: 'default'
  };
  return createTheme(themeProps);
};

export default defaultTheme;
