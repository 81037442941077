import React, { Dispatch, useMemo, useReducer } from "react"
import snackbarStateReducer from "./snackbarStateReducer"
import initialSnackbarState from "./initialSnackbarState"
import {
  SnackbarStateReducerType,
  SnackbarState,
  SnackbarStateActionType,
} from "./interfaces"

export const SnackbarStateContext = React.createContext<{
  snackbarState: SnackbarState
  dispatch: Dispatch<SnackbarStateActionType>
}>({
  snackbarState: initialSnackbarState,
  dispatch: () => initialSnackbarState,
})

const { Provider } = SnackbarStateContext

interface SnackbarStateProviderProps {
  children: React.ReactNode
}

const SnackbarStateProvider: React.FC<SnackbarStateProviderProps> = ({
  children,
}) => {
  const [snackbarState, dispatch] = useReducer<SnackbarStateReducerType>(
    snackbarStateReducer,
    initialSnackbarState
  )

  const contextValue = useMemo(() => ({ snackbarState, dispatch }), [
    snackbarState,
    dispatch,
  ])

  return <Provider value={contextValue}>{children}</Provider>
}

export default SnackbarStateProvider
