import {
  StylesState,
  StylesStateAction,
  StylesStateActionType,
} from "./interfaces"

const StylesStateReducer = (
  stylesState: StylesState,
  action: StylesStateActionType
): StylesState => {
  switch (action.type) {
    case StylesStateAction.SET_STYLES:
      return {
        ...stylesState,
        packageSets: action.data,
        stylesFetched: true,
      }

    case StylesStateAction.SELECT_PACKAGE:
      return {
        ...stylesState,
        packageSets:
          stylesState.packageSets?.map(packageSet => {
            const newPackages = packageSet.packages.map(pkg => {
              if (pkg.id === action.data.id) {
                return {
                  ...pkg,
                  selected: true,
                }
              }
              return {
                ...pkg,
                selected: false,
              }
            }) 
            return {
              ...packageSet,
              packages: newPackages,
            }
          }) || [],
        bundles: [],
        materialsFetched: false,
      }
    case StylesStateAction.SET_FETCHING_STYLES:
      return {
        ...stylesState,
        fetchingStyles: action.data,
      }
    case StylesStateAction.SET_MATERIALS:
      return {
        ...stylesState,
        bundles: action.data,
        materialsFetched: true,
      }
    case StylesStateAction.SET_FETCHING_MATERIALS:
      return {
        ...stylesState,
        fetchingMaterials: action.data,
      }
    case StylesStateAction.SET_FETCHING_ROOMS:
      return {
        ...stylesState,
        fetchingRooms: action.data,
      }
    case StylesStateAction.SET_ROOMS:
      return {
        ...stylesState,
        rooms: action.data,
        roomsFetched: true,
      }
    case StylesStateAction.SET_FETCHING_EXTRACOST:
      return {
        ...stylesState,
        fetchingExtraCost: action.data,
      }
    case StylesStateAction.SET_EXTRACOST:
      return {
        ...stylesState,
        extraCost: action.data
      }
    case StylesStateAction.SELECT_BUNDLE:
      return {
        ...stylesState,
        selectedBundle: action.data,
      }
    case StylesStateAction.SET_OPEN_ROOM:
      return {
        ...stylesState,
        openRoom: action.data,
      }
    case StylesStateAction.SELECT_MATERIAL:
      return {
        ...stylesState,
        bundles: stylesState.bundles?.map(bundle => {
          if (bundle.id === action.data.bundleId) {
            const material =
              bundle.materialOptions.find(
                material => material.id === action.data.materialId
              ) ?? null
            bundle.selectedMaterial = material
            bundle.previewedMaterial = material
          }
          return bundle
        }),
        selectedBundle: stylesState.selectedBundle
          ? {
              ...stylesState.selectedBundle,
              selectedMaterial:
                stylesState.selectedBundle.materialOptions.find(
                  material => material.id === action.data.materialId
                ) ?? null,
              previewedMaterial:
                stylesState.selectedBundle.materialOptions.find(
                  material => material.id === action.data.materialId
                ) ?? null,
            }
          : null,
      }
    case StylesStateAction.SET_ACTIVE_TAB:
      return {
        ...stylesState,
        activeTab: action.data,
      }
    case StylesStateAction.PREVIEW_MATERIAL:
      return {
        ...stylesState,
        bundles: stylesState.bundles?.map(bundle => {
          if (bundle.id === action.data.bundleId) {
            bundle.previewedMaterial =
              bundle.materialOptions.find(
                material => material.id === action.data.materialId
              ) ?? null
          }
          return bundle
        }),
        selectedBundle: stylesState.selectedBundle
          ? {
              ...stylesState.selectedBundle,
              previewedMaterial:
                stylesState.selectedBundle.materialOptions.find(
                  material => material.id === action.data.materialId
                ) ?? null,
            }
          : null,
      }
    case StylesStateAction.RESET_PREVIEW:
      return {
        ...stylesState,
        bundles: stylesState.bundles?.map(bundle => {
          bundle.previewedMaterial = bundle.selectedMaterial
          return bundle
        }),
        selectedBundle: stylesState.selectedBundle
          ? {
              ...stylesState.selectedBundle,
              previewedMaterial: stylesState.selectedBundle.selectedMaterial,
            }
          : null,
      }
    default:
      throw new Error(`Bad reducer action.`)
  }
}

export default StylesStateReducer
