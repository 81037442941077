import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import React from 'react';
import { t } from '../../utils';
import poweredByLabelStyles from './PoweredByLabel.styles';

interface PoweredByLabelProps {
  showIndicativeText?: boolean
}

/**
 * Primary UI component for user interaction
 */
export const PoweredByLabel: React.FC<PoweredByLabelProps> = ({showIndicativeText}) => {
  const GBLogo = 'https://gbgeneric.blob.core.windows.net/logo/GBuilder-Logo-Black.svg'
    const version = process.env.REACT_APP_VERSION_IDENTIFIER ?? undefined;
  const classes = poweredByLabelStyles();
 
  const showVersion = () => {      
      return version != undefined && !version.includes('REACT_APP')
  }
  
  return (
    <Card className={classes.root}>
      <CardContent className={classes.poweredByContent}>
        <Typography variant='body2' className={classes.poweredBy} align='right'>{t('poweredBy')}</Typography>
      </CardContent>
        { showVersion() 
        ?   <CardContent className={classes.versionContent}>
                <CardMedia className={classes.media} image={GBLogo}/>
                <Typography className={classes.versionIdentifier}>{version}</Typography>
            </CardContent>        
        : <CardMedia className={classes.media} image={GBLogo}/>
        }
      {showIndicativeText &&
        <CardContent className={classes.modelIsIndicativeContent}>
          <Typography variant='body2' className={classes.modelIsIndicative} align='center'>{t('theModelIsIndicative')}</Typography>
        </CardContent>
      }
    </Card>
  );
};
