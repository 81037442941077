import React, {useContext, useEffect, useState} from "react";
import decorationPageStyles from "./DecorationPageCard.styles";
import LayoutSelection from "./LayoutSelection"
import {DecorationSelection} from "./DecorationSelection";
import {DecoStateContext} from "../../store/decoState/DecoStateProvider";
import {GlobalStateContext} from "../../store/globalState/GlobalStateProvider";
import {DecoStateAction} from "../../store/decoState/interfaces";
import {getLayouts} from "../../axios/instances/tdApi";
import WeekendIcon from "@material-ui/icons/Weekend"
import {t} from "../../utils"
import NavigationBar from "../common/Navigation/NavigationBar";
import {GlobalStateAction} from "../../store/globalState/interfaces";
import useEventLogger from "../../analytics/useEventLogger";
import {Layout} from "../../axios/instances/interfaces";

interface props{
    saveDecorations?: () => void;
    createNewLayout: (id?: number) => void;
}

export const DecorationPageCard: React.FC<props> = ({saveDecorations, createNewLayout}) => {

    const { logActions } = useEventLogger();
    
    const classes = decorationPageStyles();
    const {decoState, dispatch: decoStateDispatch} = useContext(DecoStateContext)
    const {globalState, dispatch} = useContext(GlobalStateContext);
    const [selectedTab, setSelectedTab] = useState<number>(decoState.activeDecoTab);
    
    useEffect(() => {
        
        logActions('use_deco_mode','User triggered deco mode','deco_mode')
        
        dispatch({
            type: GlobalStateAction.SET_HEADER, data: {
                headerText: t('decoration.decorate'),
                headerIcon: <WeekendIcon/>
            }
        })
        
        if(!decoState.layoutOptions?.length)
        {
            fetchNewLayouts();
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    // Update layouts if we receive new layout id
    useEffect(() => {
        
        if(!decoState.newLayoutId)
        {
            return;
        }
        
        if(decoState.newLayoutId > 0) // Greater than zero means it's actual saved decoration
        {
            fetchNewLayouts();
        }
        else // Else its a pseudo ID, a public temp decoration layout
        {
            const layouts = decoState.layoutOptions;
            // Pseudo layout has to be added only once
            if(!layouts.find(l => l.id === decoState.newLayoutId))
            {
                const pseudoDecoration: Layout = {
                    id: decoState.newLayoutId,
                    groupId: 0,
                    userId: decoState.newLayoutId,
                    apartmentId: 0,
                    isDirty: false
                }
                layouts.push(pseudoDecoration);
                decoStateDispatch({type: DecoStateAction.LAYOUT_OPTIONS, data: layouts})
            }            

            // New layout set. No need to fetch anew, so set it undefined
            decoStateDispatch({type: DecoStateAction.LAYOUT_CREATED, data: undefined})
        }
    }, [decoState.newLayoutId])    
    
    const fetchNewLayouts = () => {
        const fetcher = async () => {
            const layouts = await getLayouts(globalState.startParameters);
            decoStateDispatch({type: DecoStateAction.LAYOUT_OPTIONS, data: layouts.data.data})

            // New layout set. No need to fetch anew, so set it undefined
            decoStateDispatch({type: DecoStateAction.LAYOUT_CREATED, data: undefined})
        }
        fetcher();
    }
    
    const changeTab = (tabIndex: number) => {
        
        setSelectedTab(tabIndex);
        decoStateDispatch({type: DecoStateAction.CHANGE_PAGE, data: tabIndex})
    }
    
     const pageContent = () => {
        
        if(selectedTab === 0)
        {
            return <LayoutSelection
                onContinueToDecorating={() => {changeTab(1)}}
                onCreateNewLayout={createNewLayout}
            />
        }
        else if (selectedTab === 1)
        {
            return <DecorationSelection
                goBack={() => {changeTab(0)}}
                saveDecorations={saveDecorations} onCreateNewLayout={createNewLayout}/>
        }
        
        return <></>
    }
    
    //{decoState.layoutOptions && decoState.layoutOptions?.length ? pageContent() : <></>}
    return(<>
        {selectedTab === 0 && // Display this nav bar only if we're at root page. Sub pages have their own
            <NavigationBar onReturn={() => dispatch({type: GlobalStateAction.OPEN_PAGE, data: undefined})} />
        }
        {pageContent()}
    </>)
}