import React, { useContext } from 'react';
import { DialogStateContext } from '../../../store/dialogState/DialogStateProvider';
import { DialogStateAction } from '../../../store/dialogState/interfaces';
import { t } from '../../../utils';
import { ReactComponent as OpenLink } from '../../../images/icons/open-link.svg';
import { ReactComponent as DownloadIcon } from '../../../images/icons/download.svg';
import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme
} from '@material-ui/core';
import { Dialog as MuiDialog } from '@material-ui/core';
import useEventLogger from '../../../analytics/useEventLogger';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 100
    },
    dialogActions: {
      margin: theme.spacing(2),
      justifyContent: 'space-between'
    }
  })
);

const Dialog: React.FC = () => {
  const { logActions } = useEventLogger();
  const { dialogState, dispatch } = useContext(DialogStateContext);
  const classes = useStyles();

  const handleClose = () => {
    dialogState.onCancel?.();
    dispatch({ type: DialogStateAction.CLOSE_DIALOG });
  };

  // TODO link should be link, download should be download
  return (
    <MuiDialog
      open={dialogState.show}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      className={classes.root}
    >
      <DialogTitle id="max-width-dialog-title">{dialogState.title}</DialogTitle>
      <DialogContent>
        {dialogState.text && <DialogContentText>{dialogState.title}</DialogContentText>}
        {dialogState.link && (
          <DialogContentText>
            <Button variant="outlined" href={dialogState.link.href}>
              {t('open')}&nbsp;
              <OpenLink />
            </Button>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" onClick={handleClose}>
          {dialogState.onCancelText || t('dialog.cancel')}
        </Button>
        {dialogState.file ? (
          <Button
            variant="outlined"
            href={`data:image/png;base64,${dialogState.file.file}`}
            download={dialogState.file.name}
            onClick={() => {
              dispatch({ type: DialogStateAction.CLOSE_DIALOG });
              logActions('screenshot_downloaded', 'Screenshot downloaded', 'screenshot_1');
            }}
          >
            {t('download')}&nbsp;
            <DownloadIcon />
          </Button>
        ) : null}
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
