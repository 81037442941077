
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const actionButtonContainerStyles = makeStyles((theme: Theme) =>  
createStyles({
  actionButtonContainer: {
    display: 'inline-flex',
    alignItems: 'end'
  },
  showFloors: {
    zIndex: 100
  },
  layoutView: {
    justifyContent: 'right',
    alignItems: 'center',
    display: 'flex', 
    flexGrow: 1
  }
}),
);

export default actionButtonContainerStyles
