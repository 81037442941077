import React, {useContext, useState} from "react";
import DecorationTutorialStyles from "./DecorationTutorial.styles";
import TutorialPage from "./TutorialPage";
import {DialogStateContext} from "../../../store/dialogState/DialogStateProvider";
import {DialogStateAction} from "../../../store/dialogState/interfaces";
import SettingsCheckbox from "../../Settings/SettingsCheckbox";
import {t} from "../../../utils";
import {SettingsStateContext} from "../../../store/settingsState/SettingsStateProvider";
import {SettingsStateAction} from "../../../store/settingsState/interfaces";
import {Button, Typography} from "@material-ui/core";
import ProgressBar from "../Instructions/ProgressBar";
import movingDeco from "../../../images/tutorial/moving_deco.png"
import chooseLayout from "../../../images/tutorial/choose_layout.png"
import welcomeToDeco from "../../../images/tutorial/welcomeToDecorate.png"
import placeDeco from "../../../images/tutorial/placeDecorate.png"
import useEventLogger from "../../../analytics/useEventLogger";
import {GlobalStateContext} from "../../../store/globalState/GlobalStateProvider";

// Container class for decoration tutorials
const DecorationTutorial: React.FC = () => {
    
    const { logActions } = useEventLogger();
    
    const {dispatch: settingsDispatch, settingsState} = useContext(SettingsStateContext);
    const {globalState} = useContext(GlobalStateContext);
    const {dispatch} = useContext(DialogStateContext);
    const [activePage, setActivePage] = useState(0);
    const classes = DecorationTutorialStyles();
    
    // Tutorial content pages
    const pages = [
        <TutorialPage key={0}
                      imageUrl={welcomeToDeco}
                      pageHeader={t('decoration.tutorial.welcomeHeader')}
                      pageContent={t('decoration.tutorial.welcomeContent')}/>,
        <TutorialPage key={1}
                      imageUrl={chooseLayout}
                      pageHeader={t('decoration.tutorial.selectLayoutHeader')}
                      pageContent={globalState.token ? t('decoration.tutorial.selectLayoutContent') : t('decoration.tutorial.selectLayoutContentPublic')}/>,
        <TutorialPage key={2}
                      imageUrl={placeDeco}
                      pageHeader={t('decoration.tutorial.placeHeader')}
                      pageContent={t('decoration.tutorial.placeContent')}/>,
        <TutorialPage key={3}
                      imageUrl={movingDeco}
                      pageHeader={t('decoration.tutorial.moveHeader')}
                      pageContent={t('decoration.tutorial.moveContent')}/>,
    ]
    
    // Close event
    const OnDialogActionFinished = () => {
        dispatch({type: DialogStateAction.CUSTOM_DIALOG, data: undefined});
    }
    
    const OnToggleFutureDisplay = () => {
        logActions('deco_tutorial','dont show again','deco_tutorial')
        settingsDispatch({type: SettingsStateAction.SET_IGNORE_DECORATION_TUTORIAL_STATE, data: !settingsState.ignoreDecorationTutorial})
    }
    
    const ShowPagination = () => {
        // No pages, or too few
        if(pages.length < 2) {
            return <></>
        }        
        return <div className={classes.paginationContainer}>
            <ProgressBar currentStep={activePage} amountOfSteps={pages.length} />
        </div>
    }
    
    const NavigationButtons = () => {
        
        // Not yet on last page
        if(pages.length && activePage < pages.length -1)
        {
            return <div className={classes.navigationButtonContainer}>
                <Button className={`${classes.secondaryButton} ${classes.navButton}`} onClick={  () =>
                    {
                        logActions('deco_tutorial', 'Tutorial skipped', 'deco_tutorial')
                        OnDialogActionFinished();
                    }
                }>
                    <Typography className={classes.navButtonText} variant={'h6'}>{t('decoration.tutorial.skip')}</Typography>
                </Button>
                <Button className={`${classes.primaryButton} ${classes.navButton}`} onClick={() => 
                    {
                        setActivePage(activePage+1)
                    }
                }>
                    <Typography className={classes.navButtonText} variant={'h6'}>{t('decoration.tutorial.next')}</Typography>
                </Button>
            </div>
        }
        // Last page
        return <Button className={`${classes.primaryButton} ${classes.navButton}`} onClick={ () =>{
                logActions('deco_tutorial', 'Tutorial finished', 'deco_tutorial')
                OnDialogActionFinished();
            }
        }>
            <Typography className={classes.navButtonText} variant={'h6'}>{t('decoration.tutorial.toDecorating')}</Typography>
        </Button>
    }
    
    const DontShow = () => {        
        return <SettingsCheckbox label={''} caption={t('decoration.tutorial.dontShow')} action={OnToggleFutureDisplay} checked={settingsState.ignoreDecorationTutorial} />
    }
    
    return(<div className={classes.root}>
        {pages && pages.length ? pages[activePage] : null }
        {ShowPagination()}
        {NavigationButtons()}
        {DontShow()}
    </div>)
}

export default DecorationTutorial;