import React, {useContext, useState} from "react";
import SettingsStyles from "./Settings.styles";
import SettingsCheckbox from "./SettingsCheckbox";
import {t} from "../../utils";
import {SettingsStateContext} from "../../store/settingsState/SettingsStateProvider";
import {SettingsStateAction} from "../../store/settingsState/interfaces";
import {Button, Typography} from "@material-ui/core";

const AppSettings: React.FC = () => {
        
    const {settingsState, dispatch} = useContext(SettingsStateContext);    
    const [moveKeyState, setMoveKeyState] = useState<boolean>(settingsState.movementKeysVisible);
    
    const classes = SettingsStyles();        
        
    const toggleMoveKeyState = () => {
        dispatch({type: SettingsStateAction.SET_MOVEMENT_KEY_STATE, data: !moveKeyState})
        setMoveKeyState(!moveKeyState);
    }
    const resetTutorial = () => {
        dispatch({type: SettingsStateAction.SET_IGNORE_DECORATION_TUTORIAL_STATE, data: false})
    }
    
    return(<div className={classes.accordionItem}>
        <SettingsCheckbox 
            label={t('settings.movementButtons')} 
            caption={t('settings.movementButtonsCaption')}
            action={toggleMoveKeyState} 
            checked={moveKeyState} />
        {settingsState.ignoreDecorationTutorial && 
        <Button className={classes.settingsButton} onClick={resetTutorial}>
            <Typography>{t('settings.resetTutorial')}</Typography>
        </Button>
        }
    </div>)
}

export default AppSettings;