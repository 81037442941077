import React, {useContext, useEffect, useState} from "react";
import {StylesAndMaterialsBar} from "./OptionsBar/StylesAndMaterialsBar";
import {StylesStateAction, Tab} from "../../store/stylesState/interfaces";
import StylesAndMaterials from "./StylesAndMaterials";
import {Grid, Typography} from "@material-ui/core";
import {t} from "../../utils";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import BrushIcon from "@material-ui/icons/Brush";
import StyleIcon from "@material-ui/icons/Style";
import {StylesStateContext} from "../../store/stylesState/StylesStateProvider";
import {GlobalStateContext} from "../../store/globalState/GlobalStateProvider";
import useStylesFetcher from "../../hooks/useStylesFetcher";
import useExtraCostFetcher from "../../hooks/useExtraCostFetcher";
import triggerFetchMaterials from "../../hooks/useMaterialsFetcher";
import MaterialOptionsPageStyles from "./MaterialOptionsPageCard.styles";
import NavigationBar from "../common/Navigation/NavigationBar";
import {GlobalStateAction} from "../../store/globalState/interfaces";

interface MaterialsMenuProps{
    selectTheme: (themeId: number) => void;
}

const MaterialOptionsPageCard: React.FC<MaterialsMenuProps> = ({ selectTheme}) => {

    useStylesFetcher();
    useExtraCostFetcher();

    const { stylesState, dispatch: stylesStateDispatch } = useContext(StylesStateContext);
    const { globalState, dispatch } = useContext(GlobalStateContext);
    
    //const [menuOpen, setMenuOpen] = useState(false);
    const [materialsTabVisible, setMaterialsTabVisible] = useState(false); // Required to keep material tab on UI when selecting package
    
    const classes = MaterialOptionsPageStyles();
    const amountOfExtraCosts = stylesState.extraCost;
    const showExtraCosts = Number(amountOfExtraCosts) > 0;
    const showStylesTab = stylesState.packageSets && stylesState.packageSets?.length > 0;
    const showMaterialsTab = stylesState.bundles && stylesState.bundles?.length > 0;
    //const showOptionsMenu = showStylesTab || showMaterialsTab;


    const currencySymbol = globalState.tenantSettings.currencySymbol;
    const currencyCode = globalState.tenantSettings.currencyCode;
    const tokenExists = !!globalState.token;

    // Set header
    useEffect(() => {
        dispatch({
            type: GlobalStateAction.SET_HEADER, data: { 
                headerText: t('theming.selections'), 
                headerIcon: <StyleIcon/> 
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    if (tokenExists) triggerFetchMaterials();

    useEffect(() => {
        if (!showStylesTab && showMaterialsTab) {
            stylesStateDispatch({
                type: StylesStateAction.SET_ACTIVE_TAB,
                data: Tab.MATERIALS
            });
        }
        if (tokenExists && stylesState.materialsFetched) {
            setMaterialsTabVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stylesState.materialsFetched, stylesState.stylesFetched]);
    
    const materialTabs = [
        {
            id: Tab.STYLES,
            caption: t('theming.styles'),
            isVisible: showStylesTab,
            icon: <BrushIcon className={classes.icon}/>
        },
        {
            id: Tab.MATERIALS,
            caption: t('theming.materials'),
            isVisible: materialsTabVisible,
            icon: <StyleIcon className={classes.icon}/>
        }
    ];
    
    return(<>
        <NavigationBar onReturn={() => dispatch({type: GlobalStateAction.OPEN_PAGE, data: undefined})} />
        <StylesAndMaterialsBar tabs={materialTabs}
                               onClick={(n) =>
                               {
                                   stylesStateDispatch({type: StylesStateAction.SET_ACTIVE_TAB, data: n})
                               }} />
        <StylesAndMaterials selectTheme={selectTheme} />
        {showExtraCosts && (
            <Grid container className={classes.extraCost}>
                <Grid item>
                    <Typography variant="h5" className={classes.extraCostText}>
                        {t('theming.extraCost')}:
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" className={classes.extraCostPrice}>
                        {currencyCode === 'GBP' ? currencySymbol : ''} {amountOfExtraCosts}{' '}
                        {currencyCode !== 'GBP' ? currencySymbol : ''}
                    </Typography>
                </Grid>
                <Grid item>
                    <ErrorOutlineRoundedIcon className={classes.extraCostIcon} />
                </Grid>
            </Grid>
        )}
    </>)
    
}

export default MaterialOptionsPageCard;