import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const MaterialOptionsPageStyles = makeStyles((theme: Theme) => {
    return createStyles({
        pageCard: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',

            background: 'white',
            border: '1px solid #EAECF0',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderRadius: '8px',
            padding: '6px',

            height: '100%',
            overflow:"hidden",
            pointerEvents: 'all'
        },
        pageContent:{
          overflowY: 'auto'  
        },
        accordion: {
            zIndex: 50,
            overflow: 'none',
            display: 'block',
            width: '10vw',
            minWidth: '250px',
            borderRadius: theme.spacing(2),
            position: "absolute",
            left: theme.spacing(2),
            top: theme.spacing(2),
            justifySelf: "start",
            boxShadow: 'none',
            '&.Mui-expanded': {
                width: '100%',
                maxWidth: '600px',
                overflow: 'none',
                marginTop: '0px',
                '@media screen and (max-height: 600px) and (orientation: landscape)': {
                    width: '100%',
                    height: '100%',
                    position: "absolute",
                    left: 0,
                    top: 0,
                    borderRadius: 0
                }
            },
            '&:before': {
                display: 'none' // This removes the extra line on top of accordion
            },
            '&:last-child': {
                borderBottomLeftRadius: theme.spacing(2),
                borderBottomRightRadius: theme.spacing(2),
            },
            [theme.breakpoints.down('xs')]: {
                position: "absolute",
                left: theme.spacing(1),
                top: theme.spacing(1),
                width: 'auto',
                marginRight: theme.spacing(1),
                boxShadow: 'none',
                '&.Mui-expanded': {
                    height: 'auto',
                    position: "absolute",
                    left: theme.spacing(0),
                    top: theme.spacing(0),
                    margin: 0,
                    width: '100%',
                    borderRadius: theme.spacing(0),
                    overflow: 'none',
                    marginTop: '0px',
                },
            },
        },
        icon: {
            height: '2rem',
            width: '2rem',
            position: 'relative',
            top: '50%',
        },
        extraCost: {
            position: "relative",
            bottom: '0',
            backgroundColor: '#d6d6d6',
            //borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '6px',
            padding: 0,
            marginLeft: `-${theme.spacing(2)}px`,
            marginBottom: `-${theme.spacing(2)}px`,
            width: `calc(100% + ${theme.spacing(4)}px)`,
        },
        extraCostPrice: {
            display: 'flex',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('xs')]:{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                fontSize: '1rem',              
            }
        },
        extraCostText: {
            color: theme.palette.text.secondary,
            margin: theme.spacing(3),
            [theme.breakpoints.down('xs')]:{
                margin: theme.spacing(1),
                fontSize: '1rem',
            }
        },
        extraCostIcon: {
            margin: theme.spacing(1),
            fontSize: '2rem',
            position: 'relative',
            top: '50%',
            marginTop: '-1rem', //Half of the icon size
            [theme.breakpoints.down('xs')]:{
                margin: theme.spacing(1),
                top: '0',
                fontSize: '1rem',
            }
        },
        menuButton: {
            display: 'flex',
            height: '100%',
            flexDirection: 'row',
            padding: '4px',
            marginRight: '16px',

            width: 'fit-content',
            minWidth: '100px',

            background: 'white',
            border: '1px solid #EAECF0',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderRadius: '8px',

            '&:hover': {
                background: theme.palette.secondary.main,
                color: 'white'//'#667085'
            }
        },
        menuGrid: {
            marginBottom: '16px',
            marginTop: '16px'
        },
    })
});

export default MaterialOptionsPageStyles;