import {SettingsKey, SettingsState, SettingsStateAction, SettingsStateActionType} from "./interfaces";


const SettingsStateReducer = (
    settingsState: SettingsState, 
    action: SettingsStateActionType): SettingsState => {
    
    switch (action.type){
        case SettingsStateAction.SET_IGNORE_DECORATION_TUTORIAL_STATE:
            localStorage.setItem(String(SettingsKey.SET_IGNORE_DECORATION_TUTORIAL_STATE), String(action.data));
            return {
                ...settingsState,
                ignoreDecorationTutorial: action.data
            }
        case SettingsStateAction.SET_MOVEMENT_KEY_STATE:
            localStorage.setItem(String(SettingsKey.MOVEMENT_KEY_STATE), String(action.data));
            return {
                ...settingsState,
                movementKeysVisible: action.data
            }
    }
    
    throw new Error('Bad settings action!')
}

export default SettingsStateReducer;