import { GlobalState } from "./interfaces"

const initialGlobalState: GlobalState = {
  cameraMode: undefined,
  hasThreeDStarted: false,
  startParameters: {},
  tenantSettings: {},
  token: null,
  applicationError: null,
  apartmentInfo: {},
  activePage: undefined,
  isPanelHidden: true
}

export default initialGlobalState
