import React, {useState} from 'react';
import useStyles from './ProductButton.styles';
import {Button, Grid, Typography} from '@material-ui/core';
import {Image} from "@material-ui/icons"
//import Icon, { IconKeys } from '../Icon';

interface ProductInterface {
  className?: string;
  productName: string;
  productDescription?: string;
  productImageUrl?: string;
  onClicked?: () => void;
  onItemDrag?: () => void;
  onManualDrag?: (y: number) => void; // Relay drag control
  icon?: JSX.Element;
  adjective?: string;
}

const ProductButton: React.FC<ProductInterface> = ({
  className,
  productName,
  productDescription,
  productImageUrl,
  onClicked,
  onItemDrag,
  onManualDrag,
  icon,
  adjective,
  ...rest
}: ProductInterface) => {
  const [canSelect, setCanSelect] = useState<boolean>(false);
  const [dragStarted, setDragStarted] = useState<boolean>(false);
  const [dragThreshold, setCurrentThreshold] = useState<number>(0);
  const [vertical, setVertical] = useState<number>(0)
  const classes = useStyles({isTouchOverride: onManualDrag !== undefined})();
  const triggerThreshold = 100; // If horizontal drag exceeds this, consider it a selection
  const verticalThreshold = 100; // If vertical drag exceeds this, consider it a menu drag
  const props = rest as any;

  const dragSelect = (event: React.PointerEvent) => {
    if(onManualDrag && dragStarted )
    {
      onManualDrag(event.movementY);
    }
    if(!onItemDrag || !canSelect || Math.abs(vertical) > verticalThreshold)
    {
      return
    }
    if(dragThreshold >= triggerThreshold) {
      setCanSelect(false);
      onItemDrag();
    }
    else {
      setCurrentThreshold(dragThreshold + event.movementX);
      setVertical(vertical + event.movementY);

    }
  }
  
  const enableDragFeatures = () => {
    setCurrentThreshold(0);
    setVertical(0);
    setCanSelect(true);
    setDragStarted(true);
  }
  const disableDragFeatures = () => {
      setCanSelect(false);
      setDragStarted(false);
  }
  
  return (
    <div {...props} className={`${classes.root} ${className ?? ''}`}>
      <div
        className={classes.productButton}
        onPointerMove={dragSelect}
        onPointerDown={enableDragFeatures}
        onPointerUp={disableDragFeatures}
        onPointerLeave={disableDragFeatures}        
        onClick={onClicked ? onClicked : undefined}
      >
        <Grid container style={{maxHeight: '100px'}}>
          <Grid className={classes.gridItem} item xs={4}>
            {productImageUrl ? (
                <img className={classes.productImage}  src={`${productImageUrl}`} alt={'product image'} />
            ) : (
                <Image className={classes.productImage} />
            )}
          </Grid>
          <Grid className={classes.gridItem} item xs={(icon ? 6 : 8)}>
            <div className={classes.productTextContainer}>
              <Typography className={classes.productCaption}>{productName}</Typography>
              <Typography className={classes.productDescription}>{productDescription}</Typography>
              {adjective && <Typography className={classes.adjective}>{adjective}</Typography>}
            </div>
          </Grid>
          {icon &&
            <Grid className={classes.gridItem} item xs={2}>
              {icon}
            </Grid>
          }
        </Grid>
      </div>
    </div>
  );
};

export default ProductButton;
