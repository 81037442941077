import { useContext, useEffect } from "react"
import { getApartmentInfo } from "../axios"
import { GetApartmentInfoParams } from "../axios/instances/interfaces"
import { GlobalStateContext } from "../store/globalState/GlobalStateProvider"
import { GlobalStateAction, GlobalStateActionType } from "../store/globalState/interfaces"

interface ApartmentInfoFetcherReturnValue {
  fetchApartmentInfo: () => void
}

export const composeDoFetch =
  (params: GetApartmentInfoParams, dispatch: React.Dispatch<GlobalStateActionType>) =>
  async (): Promise<void> => {
    const response = await getApartmentInfo(params)
    dispatch({
      type: GlobalStateAction.SET_APARTMENT_INFO,
      data: response.data.data
    })
}

export const useApartmentInfoFetcher = (
): ApartmentInfoFetcherReturnValue => {
  
  const { globalState, dispatch } = useContext(GlobalStateContext)

  const params: GetApartmentInfoParams = {
    apartmentId: globalState.startParameters.apartmentId,
    apiUrl: globalState.startParameters.api,
    organisationId: globalState.startParameters.company,
    projectId: globalState.startParameters.projectId,
    tenantId: globalState.startParameters.schema,
    token: globalState.token || undefined,
  }

  const doFetch = composeDoFetch(params, dispatch)

  useEffect(() => {
    if (!globalState.apartmentInfo.name &&
      globalState.startParameters.apartmentId &&
      globalState.startParameters.api &&
      globalState.startParameters.company &&
      globalState.startParameters.projectId &&
      globalState.startParameters.schema) doFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState])

  return { fetchApartmentInfo: () => doFetch() }
}


export default useApartmentInfoFetcher
