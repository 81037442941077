import { AxiosRequestConfig } from "axios"

interface ConfigOptions {
  token?: string | null
  timeout?: number
  //  eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers: any
}

export const createConfig: (options: ConfigOptions) => AxiosRequestConfig = ({
  token,
  timeout,
  headers,
}) => {
  return {
    headers: {
      ...(headers || {}),
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    timeout,
  }
}
