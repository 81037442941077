import styled from "styled-components"

export const ThreeDContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media not all and (min-resolution:.001dpcm) and @supports (-webkit-touch-callout: none) and (max-width: 600px)
    height: calc(var(--vh, 1vh) * 90);
  }
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Video = styled.video<{ apartmentReady: boolean, instructionsDismissed: boolean }>`
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  @media not all and (min-resolution:.001dpcm) and @supports (-webkit-touch-callout: none) and (max-width: 600px) {
    height: calc(var(--vh, 1vh) * 90);
  }
  min-width: 100%;
  aspect-ratio: 16 / 9;
  display: ${({ apartmentReady, instructionsDismissed }) => (apartmentReady && instructionsDismissed ? "block" : "none")};
`