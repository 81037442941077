import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const DecorationTutorialStyles = makeStyles((theme: Theme) => {
    return  createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',

            backgroundColor: 'white',
            border: '1px solid #EAECF0',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderRadius: '8px',
            padding: '16px',


            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            textAlign: 'center',

            maxWidth: '40vw',
            minWidth: '30vw',
            minHeight: '25vh',
            [theme.breakpoints.down('sm')]:{
                maxWidth: undefined,
                minWidth: '70vw',
            },        
            [theme.breakpoints.down('xs')]:{
                maxWidth: undefined,
                minWidth: '95vw',
            },
        },
        secondaryButton: {
            background: 'white',
            marginRight: '16px'
        },
        primaryButton: {
            background: `${theme.palette.primary.main} !important`,
            color: "white"
        },
        navButton:{
            display: 'flex',
            flexDirection: 'row',

            width: '100%',
            height: 'fit-content',

            border: '1px solid #EAECF0',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderRadius: '8px',

            marginBottom: theme.spacing(2),

            padding: '0px',
            paddingRight: '6px',
            paddingLeft: '6px',

            '&:hover': {
                filter: 'brightness(0.9)',
            }
        },
        navButtonText:{
            padding: '8px',
            textTransform: 'capitalize',
            //fontWeight: 'bold'
        },
        navigationButtonContainer: {
            display: "flex",
            flexDirection: "row",
            width: '100%'
        },
        image: {
            borderRadius: '8px',
            width: '100%'
            //height: '200px'
        },
        headerText:{
            marginTop: '16px',
            marginBottom: '8px',
            //fontWeight: 'bold',
        },
        contentText:{
            color: theme.palette.text.secondary,
            textAlign: 'center'
        },
        paginationContainer:{
            margin: '8px'
        }
    })
});

export default DecorationTutorialStyles;