import { Package, PackageSet } from "../../../axios/instances/interfaces"

export const getSubPackagesForPackage = (themePackageId?: number, packageSets?: PackageSet[] ): Package[] => {
  const subPackages = packageSets?.filter(subPackage => subPackage.isSubPackageSet) 

  const subPackagesForPackage: Package[] = []
  
  subPackages?.map((subPackageSet) => (
    subPackageSet.packages.map((subPackage) => (
      subPackage.preRequisites?.map((preRequisite) => {
        if (preRequisite.id === themePackageId) {
          return subPackagesForPackage.push(subPackage)
        } else return null
      }) 
    ))
  ))
  return subPackagesForPackage
}