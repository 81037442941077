import React from "react";
declare global {
    interface Window {
        hbspt: any;
    }        
}

const HubspotContactForm = () => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js?pre=1';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
                      portalId: '6621789',
                      formId: '214674e4-7f7f-4eb4-be98-06ffab995be8',
          target: '#hubspotForm'
        })
      }
    });

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    );

}

export default HubspotContactForm;