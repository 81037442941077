export interface SendCandidateData {
  sdpMLineIndex?: number | null
  sdpMid?: string | null
  candidate?: string
}

export interface SendOfferData {
  sdp: string
  type: RTCSdpType | undefined
  requestURL: string
  UIScale: number
}

export interface GetStylesParams {
  apiUrl: string
  organisationId: string
  tenantId: string
  projectId: string
  apartmentId: string
  token?: string
}

export interface GetApartmentInfoParams {
  apiUrl?: string
  organisationId?: string
  tenantId?: string
  projectId?: string
  apartmentId?: string
  token?: string
}

export interface GetMaterialsParams {
  apiUrl: string
  organisationId: string
  tenantId: string
  projectId: string
  apartmentId: string
  token: string
  roomIds?: number[]
  includeMaterialOptions?: boolean
  includeApartmentBundles?: boolean
  includeRoomBundles?: boolean
}

export enum SelectionMode {
  MANDATORY = "MANDATORY",
  OPTIONAL = "OPTIONAL",
}

export enum PublishState {
  NOT_DEFINED = "NOT_DEFINED",
  IN_PROGRESS = "IN_PROGRESS",
  READY = "READY",
  CANCELED = "CANCELED",
}

export enum BundleState {
  OPEN = "OPEN",
  LOCKED = "LOCKED",
  CONFIRMED = "CONFIRMED",
  DISABLED = "DISABLED",
  NOT_DEFINED = "NOT_DEFINED",
}

/** TODO add more fields to this if needed
 * */
export interface PackageImageTag {
  name: string
}

export interface PackageImage {
  description: string | null
  filename: string
  imageDisplayFilename: string
  name: string
  sortNo: number
  tags: PackageImageTag[]
  thumbnailUrl: string
  url: string
}

export interface Package {
  brochureFileName: null | string
  brochureName: null | string
  canPreview: boolean
  canSelect: boolean
  costVsDefaults: null | string
  costVsSelected: null | string
  description: null | string
  displayName: null | string
  id: number
  images: PackageImage[]
  name: string
  packageSetId: number
  publishState: PublishState
  roomIds: Array<null | number>
  selected: boolean
  preRequisites?: SubPackage[]
}

export interface SubPackage {
  id: number
  isDefault: 0 | 1
  name: string
  packageSetId: number
  packageSetName: string
}

export enum MaterialSelectionMode {
  LOOSE = "LOOSE",
  FREE = "FREE",
  STRICT = "STRICT",
}

export interface PackageSet {
  canBeIgnored: boolean
  displayName: string | null
  id: number
  isSubPackageSet: 1 | 0
  materialSelectionMode: MaterialSelectionMode
  name: string
  packages: Package[]
  projectId: number
  publishState: PublishState
  selectionMode: SelectionMode
}

export interface GetStylesResponse {
  data: {
    hasSelectedAllMandatoryPackages: boolean
    packageSets: PackageSet[]
    error: null | string
    warnings: string[]
  }
}

export interface Representation {
  assetDbUuid: string
  keepAspectRatio: boolean
  legacy3dId: null | string
}

export interface Material {
  id: number
  bundleId: number
  projectMaterialId: number
  bundleMaterialId: number
  description: string
  imageName: string
  imageUrl: string
  imageUrlLarge: string
  imageUrlSmall: string
  isDefault: boolean
  isDefaultInProject: boolean
  manufacturer: string
  materialId: number
  name: string
  representation: Representation
  sku: string
  tenantId: string
  totalCost: string
  url: string
}

export interface Bundle {
  allowNegativePrice: boolean
  amount: string
  amountUnit: string
  apartmentId: number
  canIgnore: boolean
  deadline: string
  defaultMaterial: Material | null
  description: null | string
  id: number
  ignoreIsDefault: boolean
  ignoreText: string
  isApartmentLevel: boolean
  isIgnored: boolean
  isPartOfOffer: boolean
  materialCategoryId: number
  materialCategoryName: string
  materialOptions: Material[]
  materialPriceTagCategories: []
  materialTagCategories: []
  name: "Lattiat"
  offers: []
  orderNumber: 1
  ownerCanSeeBundle: boolean
  priceTagCategories: []
  roomIds: number[]
  rooms: Array<{ roomId: number; roomName: string }>
  selectedMaterial: Material | null
  previewedMaterial: Material | null
  selectionModified: string
  selectionOrigin: string
  showInApartmentLevel: boolean
  showInRoomLevel: boolean
  state: BundleState
  status: string
  userCanSeeBundle: boolean
  userCanSelectMaterial: boolean
  visibilityLvl: 1
}

export interface GetMaterialsResponse {
  data: Bundle[]
}

export interface Room {
  name: string
  id: number
  apartment_id: number
  comment: null | string
  cost: string
}

export interface LoginResponse {
  data: {
    userID: number
    name: string
    userLevel: number
    canUpload: boolean
    email: string
  }
  error: null | string
  token: string
}

export interface TokenExchangeResponse {
  data: {
    token: string
    user: {
      auth0Id: string
      email: string
      name: string
      userId: number
      userLevel: number
    }
  }
}

export interface GetRoomsParams {
  organisationId: string
  tenantId: string
  projectId: string
  apartmentId: string
  token: string
  apiUrl: string
}

export interface GetRoomsResponse {
  data: {
    rooms: Room[]
    apartmentId: number
    cost: string
    ifcGuid: string
    name: string
    offersCost: string
    officeNote: null | string
    projectId: number
    projectIfcguid: string
    worksiteNote: null | string
  }
}
export interface GetExtraCostParams {
  organisationId: string
  tenantId: string
  projectId: string
  apartmentId: string
  token: string | null
  apiUrl: string
}

export interface GetExtraCostResponse {
  data: {
    cost: string
  }
}

export interface GetApartmentInfoResponse {
  data: {
    name: string
    apartmentId: number
    cost: string
    id: number
    ifcGuid: string
    offersCost: string
    projectId: number
    projectIfcguid: string
  }
}

export interface SelectPackageParams {
  apiUrl: string
  organisationId: string
  tenantId: string
  projectId: string
  apartmentId: string
  packageId: string
  token: string
}

export interface SelectPackageResponse {
  data: string
}

interface APIEnum {
  id: string
  name: string
}

interface VisibilityLevel {
  name: string
  id: number
}

interface OrganisationTracking {
  isDefault: boolean
  organisationTextId: string
  trackingId: string
  type: string
}

interface QuantityTypeUnit {
  abbreviation: string
  id: string
}

interface QuantityType {
  qtyTypeId: string
  name: string
  displayUnitId: string
  units: QuantityTypeUnit[]
  dbUnitId: string
}

interface UnitQuantityType {
  dbUnitId: string
  id: string
  roundTo: number
  units: QuantityTypeUnit[]
}

interface Unit {
  dbUnitId: string
  displayUnitId: string
  pricePrecision: number
  qtyType: UnitQuantityType
  qtyTypeId: string
}

interface GB4DUxMode {
  cameraMode: number
  uiMode: number
  showUi: boolean
  showThemes: boolean
  showMaterials: boolean
  showDecos: boolean
  showFirstPerson: boolean
  showDive: boolean
  enableTutorial: boolean
  enableOnClickBimSelection: boolean
  enableObjectHighlight: boolean
  showPrimaryPanel: boolean
  showInputPanel: boolean
  showZoomButtons: boolean
  showStoreySelection: boolean
  nextUiMode: number
}

export interface TenantSettings {
  gb4dAllowEndUserDecorating: boolean
  gb4dEnableDecorating: boolean
  GB4dConfigName: string
  bimVaultApiUrl: string
  ccUseNotes: boolean
  cjCompanyResourcesFolder: string
  cjMaterialSelectionImage: string
  coreApiUrl: string
  coreClientUrl: string
  currencyCode: string
  currencySymbol: string
  customerJourney2_alwaysShowConfirmAllSelections: boolean
  customerJourney2_isActive: boolean
  customerJourney2_isLockingByBundleEnabled: boolean
  customerJourney2_isLockingByDeadlineEnabled: boolean
  customerJourney2_mainImage: string
  customerJourney2_showEventsBox: boolean
  customerJourney2_showFooter: boolean
  customerJourney2_showHeader: boolean
  customerJourney2_showOnlyPackageBundles: boolean
  customerJourney2_showPackageBasedSummary: boolean
  customerJourney2_showRoomBasedSummary: boolean
  customerJourney2_showRoomPrices: boolean
  customerJourney2_showRooms: boolean
  customerJourney2_url: string
  customerJourney2_useGiosg: boolean
  customerJourney2_usePackageBundlesConfirm: boolean
  customerJourney2_usePackageSets: boolean
  displayUnitArea: string
  displayUnitLength: string
  displayUnitMass: string
  displayUnitTime: string
  displayUnitVolume: string
  enums: {
    bundle: {
      status: APIEnum[]
      visibleIn: APIEnum[]
    }
    esign: {
      status: APIEnum[]
      type: APIEnum[]
    }
    materialCategory: {
      selection: APIEnum[]
    }
    publishState: APIEnum[]
  }
  environmentLocale: string
  environmentUrl: string
  esign_requireForMaterialLocking: boolean
  gb4dHdUrl: string
  gb4dIsActive: boolean
  gb4dPublicCanSeeMaterialDetails: boolean
  gb4dUrl: string
  gb4dUsePublicLinks: boolean
  gb4dUxModes: null | GB4DUxMode[]
  gb4dShowBranding: boolean
  loginImageUrl: string
  login_useDirectLogin: boolean
  login_useSso: boolean
  logoUrls: {
    organisationLogoDarkUrl: string
    organisationLogoLightUrl: string
  }
  materialPriceCommission: string
  materialPriceVat: string
  mobileHasCustomApp: boolean
  organisationId: string
  organisationName: string
  organisationTracking: OrganisationTracking[]
  portal_RequireLogin: boolean
  pricePrecision: number
  publicCoreApiUrl: string
  quantityTypes: QuantityType[]
  regionName: string
  sendgridPasswordChangeTemplateId: string
  storageAccount: string
  storageContainer: string
  supportEmail: string
  themeCustomizationSettings: ThemeCustomizationSettings;
  timeZone: string
  units: {
    AREA: Unit
    LENGTH: Unit
    MASS: Unit
    PCS: Unit
    TIME: Unit
    VOLUME: Unit
  }
  useAuth0: boolean
  useBundlePrices: boolean
  useBundleSystem2: boolean
  useDigitalSignatures: boolean
  useMessaging: boolean
  useTheme: boolean
  userlaneCc: boolean
  userlaneCj2: boolean
  userlaneCore: boolean
  userlaneGb4d: boolean
  userlaneKey: null | string
  visibilityLevels: VisibilityLevel[]
}

interface ThemeCustomizationSettings {
  palette: {
    main: string;
    secondary: string;
    navigationBar: string;
    navigationBarText: string;
    accordionBackground: string;
    sectionHeaderText: string;
  };
  assets: {
    logo: string;
    headerImg: string;
  };
}

export interface GetTenantSettingsResponse {
  data: TenantSettings
}

export interface SelectMaterialResponse {
  test: string
}

export interface SelectMaterialParams {
  apiUrl: string
  organisationId: string
  tenantId: string
  projectId: string
  apartmentId: string
  token: string
  bundleId: string
  materialId: string
}

export interface DecorationResponse{
  data: Decoration[]
}

export interface LayoutResponse{
  data: Layout[]
}

export enum SystemLabel{
  DECO_ANCHOR_FLOOR = "DECO_ANCHOR_FLOOR",
  DECO_ANCHOR_WALL = "DECO_ANCHOR_WALL",
  DECO_ANCHOR_CEILING = "DECO_ANCHOR_CEILING"
}

export interface Decoration{
  id: number,
  localizations: { [id: string]: localization },
  thumbnailSource: string,
  assetGuid: string,
  systemLabels: SystemLabel[]
}

export interface DecoData extends DecoPosition{
  materialId?: number,
  identifier?: string
}

export interface DecoPosition{
  x: number,
  y: number  
}
// layout is pre made if it has either group id or apartment id. User is is the only user defined layout
export interface Layout{
  id: number,
  groupId: number,
  apartmentId: number,
  userId: number,
  isDirty: boolean
}

export interface localization{
  categoryId: number,
  category: string,
  name: string,
  description: string
}