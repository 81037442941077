import { CircularProgress, IconButton, Paper } from '@material-ui/core';

import React from 'react';
import controlButtonStyles from './ControlButton.styles';

interface ControlButtonProps {
  icon: React.ReactNode;
  inProgress?: boolean;
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const ControlButton: React.FC<ControlButtonProps> = ({
    icon,
    inProgress,
    onClick,
  }) => {
  ControlButton.displayName = 'ControlButton';

  const classes = controlButtonStyles();

  return (
    <Paper className={classes.buttonBackground}>
      <IconButton className={classes.button} onClick={onClick} >
        {inProgress ? <CircularProgress /> : icon}
      </IconButton>
    </Paper>
  );
}

