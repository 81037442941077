import React, {useContext, useEffect, useMemo, useState} from "react";
import useDecorationControlStyles from "./decorationControls.styles";
import {Button, Grid} from "@material-ui/core";
import {DecoStateContext} from "../../store/decoState/DecoStateProvider";
import {DecorationInputState, DecoStateAction} from "../../store/decoState/interfaces";
import {Delete, OpenWith, RotateRight} from "@material-ui/icons";
import DecorationRotationTool from "./DecorationRotationTool";
import DecorationPositionTool from "./DecorationPositionTool";
import useEventLogger from "../../analytics/useEventLogger";

interface decorationInterface{
    rotateDecoration: (angle: number) => void;
    moveDecoration: (x: number, y: number) => void;
}

function getDimensions(){
    const {innerWidth: width, innerHeight: height} = window;
    return {width, height}
}
function useWindowDimensions(){
    const [dimensions, setDimensions] = useState(getDimensions)
    useEffect(() => {
        const handleResize = () => {
            setDimensions(getDimensions);
        }
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        
    },[])
    
    return dimensions;
}

const DecorationControls: React.FC<decorationInterface> = ({rotateDecoration, moveDecoration}: decorationInterface) => {
    
    const { logActions, logCustomEvent } = useEventLogger();
     
    const [selectedDeco, setDeco] = useState<boolean>(false)
    const [pointerPos, setPosition] = useState<{x: number,y: number}>({x: 0, y:0})

    const classes = useDecorationControlStyles({posX: pointerPos.x, posY: pointerPos.y})();

    const {decoState, dispatch: decoStateDispatch} = useContext(DecoStateContext)
    const dimensions = useWindowDimensions();

    const fixPosition = (x: number, y: number) => {
        let fx = x;
        let fy = y;        
        
        // Adjust X
        if(fx > dimensions.width - 100)
        {
            fx = dimensions.width - 100;
        }
        else if(fx < 100)
        {
            fx = 100
        }
        // Adjust Y
        if(fy > dimensions.height - 30)
        {
            fy = dimensions.height - 30
        }
        else if(fy < 30)
        {
            fy = 30;
        }
        
        return {x: fx, y: fy}
    }
    
    useMemo(() => {        
        const x = decoState.decoPositionX;
        const y = decoState.decoPositionY;
        if(y && x && x + y !== 0) 
        {
            setPosition(fixPosition(x,y))
        }
    },[decoState.decoPositionY, decoState.decoPositionX])
    
    useEffect(() => {
        setDeco(decoState.isDecorationSelected && decoState.decorationInput === DecorationInputState.IDLE);
    },[decoState.isDecorationSelected, decoState.decorationInput])
    
    return(
        selectedDeco && decoState.decorationInput === DecorationInputState.IDLE ?
            <div className={classes.floatingContainer} onContextMenu={event => {
                event.preventDefault()
                event.stopPropagation()
            }}>
                <Grid container spacing={0}>
                    <Grid className={classes.gridItem} item xs={3}>
                        <Button className={classes.sideButton} onPointerDown={() =>{
                            logCustomEvent('deco_manipulation', { deco_manipulation: 'rotate_decoration' })
                            //logActions('deco_manipulation', 'user rotated decoration','rotate_decoration');
                            decoStateDispatch({type: DecoStateAction.DECO_INPUT, data: DecorationInputState.ROTATE})
                        } }>
                            <RotateRight className={classes.buttonIcon} />
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button className={classes.centralButton} onPointerDown={() => {
                            logCustomEvent('deco_manipulation', { deco_manipulation: 'move_decoration' })
                            //logActions('deco_manipulation', 'move_decoration', 'user moved decoration');
                            decoStateDispatch({type: DecoStateAction.DECO_INPUT, data: DecorationInputState.MOVE})
                        }}
                        >
                            <OpenWith className={classes.buttonIcon} />
                        </Button>
                    </Grid>
                    <Grid className={classes.gridItem} item xs={3}>
                        <Button className={classes.sideButton}  onClick={() =>{
                            logCustomEvent('deco_manipulation', { deco_manipulation: 'delete_decoration' })
                            //logActions('deco_manipulation', 'delete_decoration', 'user deleted decoration');
                            setDeco(false);
                            decoStateDispatch({type: DecoStateAction.DECO_INPUT, data: DecorationInputState.DELETE})
                        } }>
                            <Delete className={classes.buttonIcon} />
                        </Button>
                    </Grid>
                </Grid>
            </div> 
            : 
            decoState.decorationInput === DecorationInputState.ROTATE ?
                <DecorationRotationTool posX={pointerPos.x} posY={pointerPos.y} rotateDecoration={rotateDecoration}/>
                : decoState.decorationInput === DecorationInputState.MOVE || decoState.decorationInput === DecorationInputState.LOADING ? 
                <DecorationPositionTool sendPosition={moveDecoration}/>
            : null
        )
}

export default DecorationControls;