import {
  ApplicationTheme,
  BundleTheme,
  ButtonTheme,
  ColorsTheme,
  LogoTheme,
  PaperTheme,
  SnackbarTheme,
  SpinnerTheme,
  TextTheme,
  ThemeInterface,
} from "./interfaces"
import { rgba, tint } from "polished"

const colors: ColorsTheme = {
  main: "#25B4ED",
  error: "#ed3225",
  border: "#DCDCDC",
  backgroundGray: "rgb(240, 240, 240)",
  text: tint(0.15, "#000"),
  streamBackground: "#000000",
  background: "#FFFFFF",
}

const application: ApplicationTheme = {
  colors,
  mainBackgroundImage: "demo_background.png",
}

const button: ButtonTheme = {
  background: "#FFFFFF",
  backgroundHover: "rgb(245, 245, 245)",
  padding: "0.625rem 1.25rem",
  paddingMobile: "0.4rem 0.8rem",
  spacing: "0.625rem",
  fontSize: "1.563rem",
  borderColor: colors.border,
  borderColorHover: colors.main,
  borderRadius: "1.25rem",
  borderRadiusMobile: "1rem",
  color: colors.text,
  colorHover: colors.main,
  borderStyle: "solid",
  borderWidth: "3px",
  cursor: "pointer",
  fontWeight: "500",

  variants: {
    controlButton: {
      size: "4.5rem",
      borderRadius: "0",
      borderRadiusMobile: "0",
      padding: "0.3125rem",
      paddingMobile: "0.3125rem",
      background: "rgba(255, 255, 255, 0.8)",
      borderWidth: "1px",
      contentCenter: true,
      colorHover: colors.main,
    },
    controlButton1: {
      borderRadius: "1.25rem 0 0 0",
      borderRadiusMobile: "1rem 0 0 0",
    },
    controlButton2: {},
    controlButton3: {
      borderRadius: "0 1.25rem 0 0",
      borderRadiusMobile: "0 1rem 0 0",
    },
    controlButton4: {
      borderRadius: "0 0 0 1.25rem",
      borderRadiusMobile: "0 0 0 1rem",
    },
    controlButton5: {},
    controlButton6: {
      borderRadius: "0 0 1.25rem 0",
      borderRadiusMobile: "0 0 1rem 0",
    },
    rounded: {
      borderRadius: "1.25rem",
      borderRadiusMobile: "1rem",
    },
    large: {
      padding: "1.25rem 2.5rem",
      paddingMobile: "0.625rem 1.25rem",
      fontSize: "1.25rem",
    },
    small: {
      fontSize: "1rem",
      fontWeight: "300",
    },
    smallPadding: {
      padding: "0.3125rem 0.625rem",
      paddingMobile: "0.2rem 0.4rem",
    },
    noRounding: {
      borderRadius: "0",
      borderRadiusMobile: "0",
    },
    transparent: {
      background: "rgba(255, 255, 255, 0.8)",
    },
    icon: {
      borderRadius: "1000px",
      borderRadiusMobile: "1000px",
      padding: "2px",
      paddingMobile: "2px",
      size: "2.5rem",
      borderWidth: "1px",
      contentCenter: true,
      colorHover: colors.main,
    },
    tab: {
      borderRadius: "0",
      borderRadiusMobile: "0",
      contentCenter: true,
      onlyBottomBorder: true,
      colorHover: colors.main,
      background: "none",
      backgroundHover: "none",
    },
    text: {
      borderRadius: "0",
      borderWidth: "0",
      borderRadiusMobile: "0",
      contentCenter: true,
      background: "none",
      backgroundHover: "none",
      padding: "0",
      color: colors.text,
      colorHover: colors.main,
    },
    bold: {
      fontWeight: "700",
    },
    submenu: {
      fontSize: "1.25rem",
      fontWeight: "700",
      borderWidth: "0",
      background: "none",
      backgroundHover: "none",
      spacing: "0.625rem",
    },
    smallSubmenu: {
      padding: "0.315rem 0.625rem",
      paddingMobile: "0.2rem 0.4rem",
      fontSize: "1rem",
      borderWidth: "0",
      background: "none",
      backgroundHover: "none",
      spacing: "0.3125rem",
    },
  },
}

const spinner: SpinnerTheme = {
  color: colors.main,
  size: "2.5rem",
  background: colors.background,

  variants: {
    xs: {
      size: "1rem",
    },
    s: {
      size: "1.5rem",
    },
    m: {
      size: "2rem",
    },
    l: {
      size: "3rem",
    },
    xl: {
      size: "4rem",
    },
    transparent: {
      background: "none",
    },
  },
}

const text: TextTheme = {
  fontSize: "1.563rem",
  fontWeight: "400",
  color: colors.text,
  textAlign: "left",

  variants: {
    withMarginBottom: {
      marginBottom: "1.25rem",
    },
    small: {
      fontSize: "0.8rem",
    },
    xs: {
      fontSize: "1rem",
    },
    s: {
      fontSize: "1.25rem",
    },
    m: {
      fontSize: "1.563rem",
    },
    l: {
      fontSize: "1.953rem",
    },
    xl: {
      fontSize: "2.441rem",
    },
    xxl: {
      fontSize: "3.052rem",
    },
    stacktrace: {
      fontSize: "0.5rem",
    },
    heading: {
      fontWeight: "700",
    },
    bold: {
      fontWeight: "700",
    },
    regular: {
      fontWeight: "500",
    },
    thin: {
      fontWeight: "300",
    },
    center: {
      textAlign: "center",
    },
    colorMain: {
      color: colors.main,
    },
    withHoverEffect: {
      hoverEffect: true,
      colorHover: colors.main,
    },
    unscaled: {
      unscaledFont: true,
    },
  },
}

const paper: PaperTheme = {
  padding: "1.25rem",
  borderColor: colors.border,
  borderWidth: "3px",
  borderStyle: "solid",
  background: colors.background,
  fullscreenBackground: colors.background,
  borderRadius: "1.25rem",

  variants: {
    mobileFullScreen: {
      mobileFullScreen: true,
    },

    smallerRadius: {
      borderRadius: "0.625rem",
    },

    contentCenter: {
      contentCenter: true,
    },
    transparent: {
      background: "rgba(255, 255, 255, 0.9)",
      fullscreenBackground: colors.background,
    },
    appear: {
      appearAnimation: true,
    },
    noPadding: {
      padding: "0",
    },
  },
}

const snackbar: SnackbarTheme = {
  spacing: "0.3125rem",
  borderColor: rgba(colors.main, 0.9),
  background: rgba(tint(0.75, colors.main), 0.9),
  borderWidth: "2px",
  borderStyle: "solid",
  borderRadius: "0",

  variants: {
    error: {
      borderColor: tint(0.33, colors.error),
      background: rgba(tint(0.75, colors.error), 0.9),
    },
  },
}

const logo: LogoTheme = {
  width: "8rem",
  visible: true,

  variants: {
    hideOnDesktop: {
      visible: false,
    },
    hideOnMobile: {
      visible: false,
    },
  },
}

const bundle: BundleTheme = {
  backgroundColor: rgba(255, 255, 255, 0),
  backgroundColorHover: tint(0.5, colors.main),
  variants: {
    empty: {},
  },
}

const mainTheme: ThemeInterface = {
  application,
  button,
  spinner,
  text,
  paper,
  logo,
  snackbar,
  bundle,

  animations: {
    fastTransition: "0.2s all ease-in-out",
  },
}

export default mainTheme
