import React, { useContext, useEffect } from "react"

import { GlobalStateContext } from "../../store/globalState/GlobalStateProvider"
import { StylesStateAction, Tab } from "../../store/stylesState/interfaces"
import { StylesStateContext } from "../../store/stylesState/StylesStateProvider"
import { StyleCardSelectionMenu } from "./StylesList/StyleCardSelectionMenu"

import { MaterialSelectionMenu } from "./MaterialsList/MaterialSelectionMenu"
import stylesAndMaterialsStyles from "./StylesAndMaterials.styles"

interface StylesAndMaterialsProps {
  selectTheme: (themeId: number) => void
}

const StylesAndMaterials: React.FC<StylesAndMaterialsProps> = ({
  selectTheme,
}) => {
  const classes = stylesAndMaterialsStyles();
  const { globalState } = useContext(GlobalStateContext)
  const { stylesState, dispatch: stylesStateDispatch } =
    useContext(StylesStateContext)
  const tokenExists = !!globalState.token;
  
  const showThemes =
    !globalState.tenantSettings.gb4dUxModes ||
    globalState.tenantSettings.gb4dUxModes.some(mode => mode.showThemes)
  const showMaterials =
    !globalState.tenantSettings.gb4dUxModes ||
    globalState.tenantSettings.gb4dUxModes.some(mode => mode.showMaterials)
  
  useEffect(() => {
    if (stylesState.activeTab === null) {
      if (showThemes)
        stylesStateDispatch({
          type: StylesStateAction.SET_ACTIVE_TAB,
          data: Tab.STYLES,
        })
      else if (showMaterials && globalState.token)
        stylesStateDispatch({
          type: StylesStateAction.SET_ACTIVE_TAB,
          data: Tab.MATERIALS,
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.containerDiv}>
      {stylesState.activeTab === Tab.STYLES && (
        <StyleCardSelectionMenu {...{selectTheme, stylesState }}/>
      )}
      {stylesState.activeTab === Tab.MATERIALS && globalState.token &&
        <MaterialSelectionMenu {...{stylesState, tokenExists }} />
      }
      </div>
  )
}

export default StylesAndMaterials
