import { createStyles, makeStyles } from '@material-ui/core/styles'

const backgroundStyles = makeStyles(() => {
  return createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      justifyItems: "center",
      backgroundRepeat: "no-repeat",

      userSelect: 'none',
      WebkitTouchCallout: 'none',
      touchAction: 'none',
      pointerEvents: 'none',
    }
  })
})

export default backgroundStyles;