import {
  Bundle,
  Package,
  PackageSet,
  Room,
} from "../../axios/instances/interfaces"

export enum StylesStateAction {
  SET_STYLES = "SET_STYLES",
  SET_FETCHING_STYLES = "SET_FETCHING_STYLES",
  SET_MATERIALS = "SET_MATERIALS",
  SET_FETCHING_MATERIALS = "SET_FETCHING_MATERIALS",
  SET_FETCHING_ROOMS = "SET_FETCHING_ROOMS",
  SET_ROOMS = "SET_ROOMS",
  SET_EXTRACOST = "SET_EXTRACOST",
  SET_FETCHING_EXTRACOST = "SET_FETCHING_EXTRACOST",
  SELECT_BUNDLE = "SELECT_BUNDLE",
  SET_OPEN_ROOM = "SET_OPEN_ROOM",
  SELECT_MATERIAL = "SELECT_MATERIAL",
  SET_ACTIVE_TAB = "SET_ACTIVE_TAB",
  SELECT_PACKAGE = "SELECT_PACKAGE",
  SELECT_SUBPACKAGE = "SELECT_SUBPACKAGE",
  PREVIEW_MATERIAL = "PREVIEW_MATERIAL",
  RESET_PREVIEW = "RESET_PREVIEW",
}

export interface StylesState {
  packageSets?: PackageSet[]
  fetchingStyles: boolean
  bundles?: Bundle[]
  selectedBundle: null | Bundle
  rooms?: Room[]
  openRoom: null | number
  fetchingRooms: boolean
  fetchingMaterials: boolean
  activeTab: Tab | null
  materialsFetched: boolean
  stylesFetched: boolean
  roomsFetched: boolean
  selectedPackage: Package | null
  selectedSubPackage: Package | null
  extraCost: null | string
  fetchingExtraCost: boolean
}

export enum Tab {
  STYLES,
  MATERIALS,
}

export interface SetStylesAction {
  type: StylesStateAction.SET_STYLES
  data: PackageSet[]
}

export interface SetStylesAction {
  type: StylesStateAction.SET_STYLES
  data: PackageSet[]
}

export interface SetFetchingStylesAction {
  type: StylesStateAction.SET_FETCHING_STYLES
  data: boolean
}

export interface SetMaterialsAction {
  type: StylesStateAction.SET_MATERIALS
  data: Bundle[]
}

export interface SetFetchingMaterialsAction {
  type: StylesStateAction.SET_FETCHING_MATERIALS
  data: boolean
}

export interface SetFetchingRoomsAction {
  type: StylesStateAction.SET_FETCHING_ROOMS
  data: boolean
}

export interface SetRoomsAction {
  type: StylesStateAction.SET_ROOMS
  data: Room[]
}

export interface SetExtraCostAction {
  type: StylesStateAction.SET_EXTRACOST
  data: string | null
}

export interface SetFetchingExtraCostAction {
  type: StylesStateAction.SET_FETCHING_EXTRACOST
  data: boolean
}

export interface SelectBundleAction {
  type: StylesStateAction.SELECT_BUNDLE
  data: Bundle | null
}

export interface SetOpenRoomAction {
  type: StylesStateAction.SET_OPEN_ROOM
  data: number | null
}

export interface SelectPackageAction {
  type: StylesStateAction.SELECT_PACKAGE
  data: Package
}

export interface SelectSubPackageAction {
  type: StylesStateAction.SELECT_SUBPACKAGE
  data: Package
}

export interface SelectMaterialAction {
  type: StylesStateAction.SELECT_MATERIAL
  data: {
    bundleId: number
    materialId: number
  }
}

export interface SetActiveTabAction {
  type: StylesStateAction.SET_ACTIVE_TAB
  data: Tab
}

export interface PreviewMaterialAction {
  type: StylesStateAction.PREVIEW_MATERIAL
  data: {
    bundleId: number
    materialId: number
  }
}

export interface ResetPreviewAction {
  type: StylesStateAction.RESET_PREVIEW
}

export type StylesStateActionType =
  | SetStylesAction
  | SetFetchingStylesAction
  | SetMaterialsAction
  | SetFetchingMaterialsAction
  | SetFetchingRoomsAction
  | SetRoomsAction
  | SetExtraCostAction
  | SetFetchingExtraCostAction
  | SelectBundleAction
  | SetOpenRoomAction
  | SelectMaterialAction
  | SetActiveTabAction
  | SelectPackageAction
  | SelectSubPackageAction
  | PreviewMaterialAction
  | ResetPreviewAction

export type StylesStateReducerType = (
  state: StylesState,
  action: StylesStateActionType
) => StylesState
