import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

interface props {
    isHidden: boolean
}

const  ContentMenuStyles = ({isHidden}: props) => makeStyles((theme: Theme) => {
    return createStyles({
        menuRoot:{            
            display: 'flex',
            position: 'absolute',
            left: '0',
            top: '0',
            height: '100%',
            width: 'fit-content',
            pointerEvents: 'none',
            touchAction: 'none'
        },
        earButton:{
            zIndex: 50,
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            
            borderRadius: '0',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            
            transition: '0.3s ease-in-out',
            
            position: 'relative',
            top: '5%',
            background: 'white',
            height: '50px',
            width: 'max-content',
            left: `calc(100% + ${theme.spacing(2)}px)`, // + panel padding,
            padding: `0 ${theme.spacing(2)}px`,
            
            pointerEvents: 'all',
            
            '&:hover':{
                backgroundColor: 'white',
                filter: 'brightness(0.9)',
                // background: theme.palette.secondary.main,
                // color: 'white'
            }
        },
        panel: {
            zIndex: 50,
            display: 'flex',
            flexDirection: 'column',
            transition: '0.3s ease-in-out',
            width: '30vw',
            height: '100%',
            background: 'white',
            maxWidth: '400px',
            minWidth: '350px',
            position: 'relative',
            right: isHidden ? '100%' : '0',
            padding: theme.spacing(2),
            paddingTop: '0',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            pointerEvents: 'all',
            [theme.breakpoints.down('xs')]: {
                width: 'calc(100vw - 64px)',
                minWidth: '100%',
                maxWidth: '100%',
            }
        },
        buttonContainer: {
            position: 'relative',
            top: '5%',
            float: 'inline-end',
            width: '100%',
            height: '0',
        },
        contentContainer: {
            display: 'flex',
            flexDirection: 'column',
            
        },
        startButtonText:{
            padding: '0 8px',
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            padding: `${theme.spacing(2)}px 0`,
        },
        headerText: {
            paddingLeft: theme.spacing(1),
            fontWeight: 'bold'
        }
    })
})

export default ContentMenuStyles;