import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const controlButtonStyles = makeStyles((theme: Theme) =>
createStyles({
  buttonBackground: {
    width: '70px',
    height: '70px',
    margin: theme.spacing(1),
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '60px',
      height: '60px',
      margin: theme.spacing(0.5),
      marginBottom: theme.spacing(0)
    },
    borderRadius: theme.spacing(2)
  },
  button: {
    width: '100%',
    height: '100%',
    borderRadius: '15%',
    display: 'inline-table',
    backgroundColor: 'white',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: 'white',
      filter: 'brightness(0.7)'
    },
  },
}),
);

export default controlButtonStyles