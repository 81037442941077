import { useCallback, useContext } from "react"
import { selectMaterial as apiSelectMaterial } from "../axios"
import { Material } from "../axios/instances/interfaces"
import { GlobalStateContext } from "../store/globalState/GlobalStateProvider"
import { SnackbarStateAction } from "../store/snackbarState/interfaces"
import { SnackbarStateContext } from "../store/snackbarState/SnackbarStateProvider"
import { StylesStateAction } from "../store/stylesState/interfaces"
import { StylesStateContext } from "../store/stylesState/StylesStateProvider"
import { t } from "../utils"

export const useMaterialSelect: () =>
  | ((materialId: Material, bundleId: string) => Promise<boolean>)
  | null = () => {
  const { globalState } = useContext(GlobalStateContext)
  const { dispatch } = useContext(SnackbarStateContext)
  const { dispatch: stylesDispatch } = useContext(StylesStateContext)

  const selectingAllowed = !!(
    globalState.startParameters.apartmentId &&
    globalState.startParameters.api &&
    globalState.startParameters.company &&
    globalState.startParameters.projectId &&
    globalState.startParameters.schema &&
    globalState.token
  )

  const selectMaterial = useCallback(
    async (material: Material, bundleId: string) => {
      try {
        await apiSelectMaterial({
          apiUrl: `${globalState.startParameters.api}`,
          organisationId: `${globalState.startParameters.company}`,
          tenantId: `${globalState.startParameters.schema}`,
          projectId: `${globalState.startParameters.projectId}`,
          apartmentId: `${globalState.startParameters.apartmentId}`,
          materialId: material.projectMaterialId.toString(),
          bundleId,
          token: `${globalState.token}`,
        })
        stylesDispatch({
          type: StylesStateAction.SELECT_MATERIAL,
          data: {
            bundleId: Number(bundleId),
            materialId: material.id,
          },
        })

        return true
      } catch (e) {
        dispatch({
          type: SnackbarStateAction.SHOW_SNACKBAR_ALERT,
          data: {
            isError: true,
            message: t("error.selectMaterialError"),
            time: 5000,
          },
        })
        return false
      }
    },
    [
      globalState.startParameters.api,
      globalState.startParameters.company,
      globalState.token,
      globalState.startParameters.schema,
      globalState.startParameters.projectId,
      globalState.startParameters.apartmentId,
      dispatch,
      stylesDispatch,
    ]
  )

  return selectingAllowed ? selectMaterial : null
}
