import { logActionsLegacy } from '../../analytics/analyticsUtils';

enum InputEvent {
  Keyboard = 0,
  Mouse = 1,
  MouseWheel = 2,
  Touch = 3,
  ButtonClick = 4
}

enum KeyboardEventType {
  Up = 0,
  Down = 1
}

enum PointerPhase {
  None = 0,
  Began = 1,
  Moved = 2,
  Ended = 3,
  Canceled = 4,
  Stationary = 5
}

export const Keymap: { [x: string]: number } = {
  KeyA: 15,
  KeyD: 18,
  KeyE: 19,
  KeyQ: 31,
  KeyS: 33,
  KeyW: 37,
  KeyR: 32,
  KeyT: 34,
  ArrowLeft: 61,
  ArrowRight: 62,
  ArrowUp: 63,
  ArrowDown: 64,
  Numpad0: 84,
  Numpad1: 85,
  Numpad2: 86,
  Numpad3: 87,
  Numpad4: 88,
  Numpad5: 89,
  Numpad6: 90,
  Numpad7: 91,
  Numpad8: 92,
  Numpad9: 93,
  Digit2: 42
};

export const registerMouseEvents = (
  send: (buffer: ArrayBuffer) => void,
  videoElement: HTMLVideoElement
): void => {
  function sendTouch(event: TouchEvent, phase: PointerPhase) {
    const changedTouches = Array.from(event.changedTouches);
    const touches = Array.from(event.touches);
    const phrases = [];

    for (let i = 0; i < changedTouches.length; i++) {
      if (
        touches.find(function (t) {
          return t.identifier === changedTouches[i].identifier;
        }) === undefined
      ) {
        touches.push(changedTouches[i]);
      }
    }

    for (let i = 0; i < touches.length; i++) {
      phrases[i] =
        changedTouches.find(function (ct) {
          return ct.identifier === touches[i].identifier;
        }) === undefined
          ? PointerPhase.Stationary
          : phase;
    }

    const data = new DataView(new ArrayBuffer(2 + 13 * touches.length));
    data.setUint8(0, InputEvent.Touch);
    data.setUint8(1, touches.length);

    let byteOffset = 2;
    for (let i = 0; i < touches.length; i++) {
      const clientRect = videoElement.getBoundingClientRect();
      const scale = clientRect.width / 1920;
      const originX = clientRect.left;
      const originY = clientRect.top;

      const x = (touches[i].pageX - originX) / scale;
      const y = videoElement.videoHeight - (touches[i].pageY - originY) / scale;

      data.setInt32(byteOffset, touches[i].identifier, true);
      byteOffset += 4;
      data.setUint8(byteOffset, phrases[i]);
      byteOffset += 1;
      data.setInt16(byteOffset, x, true);
      byteOffset += 2;
      data.setInt16(byteOffset, y, true);
      byteOffset += 2;
      data.setFloat32(byteOffset, touches[i].force, true);
      byteOffset += 4;
    }
    send(data.buffer);
  }

  function sendTouchMove(event: TouchEvent) {
    sendTouch(event, PointerPhase.Moved);
    event.preventDefault();
  }

  function sendTouchStart(event: TouchEvent) {
    sendTouch(event, PointerPhase.Began);
    event.preventDefault();
  }

  function sendTouchEnd(event: TouchEvent) {
    sendTouch(event, PointerPhase.Ended);
    event.preventDefault();
  }

  function sendTouchCancel(event: TouchEvent) {
    sendTouch(event, PointerPhase.Canceled);
    event.preventDefault();
  }

  function sendMouse(event: MouseEvent) {
    const clientRect = videoElement.getBoundingClientRect();
    const scale = clientRect.width / 1920;

    const originX = clientRect.left;
    const originY = clientRect.top;

    const x = (event.clientX - originX) / scale;
    const y = videoElement.videoHeight - (event.clientY - originY) / scale;

    const data = new DataView(new ArrayBuffer(6));
    data.setUint8(0, InputEvent.Mouse);
    data.setInt16(1, x, true);
    data.setInt16(3, y, true);
    data.setUint8(5, event.buttons);

    send(data.buffer);
  }

  function sendMouseWheel(event: WheelEvent) {
    const data = new DataView(new ArrayBuffer(9));
    data.setUint8(0, InputEvent.MouseWheel);
    const x = event.deltaX * -1;
    const y = event.deltaY * -1;
    data.setFloat32(1, x, true);
    data.setFloat32(5, y, true);
    send(data.buffer);
  }

  videoElement.addEventListener('click', sendMouse, false);
  videoElement.addEventListener('mousedown', sendMouse, false);
  videoElement.addEventListener('mouseup', sendMouse, false);
  videoElement.addEventListener('mousemove', sendMouse, false);
  videoElement.addEventListener('wheel', sendMouseWheel, false);
  videoElement.addEventListener('touchend', sendTouchEnd, false);
  videoElement.addEventListener('touchstart', sendTouchStart, false);
  videoElement.addEventListener('touchcancel', sendTouchCancel, false);
  videoElement.addEventListener('touchmove', sendTouchMove, false);
};

export const registerKeyboardEvents = (send: (buffer: ArrayBuffer) => void): void => {
  let userMovesWithKeyboard = false;
  const sendKeyUp = (event: KeyboardEvent) => {
    sendKey(event, KeyboardEventType.Up);
  };

  const sendKeyDown = (event: KeyboardEvent) => {
    sendKey(event, KeyboardEventType.Down);
    if (event.code in Keymap && !userMovesWithKeyboard) {
      logActionsLegacy('move_with_keyboard', 'User moves using keyboard', 'movement_1');
      userMovesWithKeyboard = true;
    }
  };

  const sendKey = (event: KeyboardEvent, type: KeyboardEventType) => {
    const key = Keymap[event.code];
    const character = event.key.length === 1 ? event.key.charCodeAt(0) : 0;
    send(new Uint8Array([InputEvent.Keyboard, type, Number(event.repeat), key, character]).buffer);
  };

  document.addEventListener('keyup', sendKeyUp, false);
  document.addEventListener('keydown', sendKeyDown, false);
};
