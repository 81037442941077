import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

interface controlProps{
    posX: number,
    posY: number,
    iconX: number,
    iconY: number,
    radius: number,
}

const useDecorationRotationToolStyles = ({posX, posY, iconX, iconY, radius}: controlProps) =>
    makeStyles((theme: Theme) => 
        createStyles({
            container:{
                display: "flex",
                position: "absolute",
                left: `calc(${posX}px - ${radius}px)`,
                bottom: `calc(${posY}px - ${radius}px)`,
                width: `calc(${radius}px * 2)`,
                height: `calc(${radius}px * 2)`,
                
                backgroundColor: 'transparent',
                borderColor: theme.palette.primary.main,
                borderRadius: '100%',
                borderWidth: '4px',
                borderStyle: 'solid',
                touchAction: 'none',
                WebkitTouchCallout: 'none',
                userSelect: 'none',

                zIndex: 50,
            },
            icon:{
                position: 'absolute',
                left: `calc(${iconX}px - ${posX}px + ${radius}px - 15px)`,
                bottom: `calc(${iconY}px - ${posY}px + ${radius}px - 15px)`,
                
                width: '30px',
                height: '30px',
                margin: '0',
                padding: '0',
                aspectRatio: '1/1',
                borderRadius: '100%',
                background: 'white',//theme.palette.secondary.main
                touchAction: 'none',
                WebkitTouchCallout: 'none',
                userSelect: 'none',
            },
            test: {
                position: 'absolute',
                left: `calc(${posX}px - 15px)`,
                bottom: `calc(${posY}px - 15px)`,
                
                width: '30px',
                height: '30px',
                margin: '0',
                padding: '0',
                aspectRatio: '1/1',
                borderRadius: '100%',
                background: 'white',//theme.palette.secondary.main
                touchAction: 'none',
                zIndex: 50,
                WebkitTouchCallout: 'none',
                userSelect: 'none',
            }
        })
    );

export default useDecorationRotationToolStyles;