import { Button, Grid } from "@material-ui/core"
import React from "react"
import { logActionsLegacy } from "../../../analytics/analyticsUtils";
import { t } from "../../../utils";
import instructionButtonsStyles from "./InstructionButtons.styles";

interface InstructionButtonsProps {
  currentStep: number;
  disabled?: boolean;
  handleDismissInstructions: (lastStep: boolean) => void
  handleProceedToNextStep: () => void
}

const InstructionButtons: React.FC<InstructionButtonsProps> = ({currentStep, disabled, handleDismissInstructions, handleProceedToNextStep}) => {
  const classes = instructionButtonsStyles();

  const lastStep = currentStep >= 2;

  return (
    <Grid container className={classes.buttonContainer}>    
      {!lastStep &&
      <Grid item xs={6} className={classes.buttonGridItem} >
        <Button
          disabled={disabled}
          variant='outlined' 
          className={classes.secondaryButton}
          onClick={() => {handleDismissInstructions(lastStep)}}
        >{t('instructions.skipButtonText')}</Button>
      </Grid>
      }
      <Grid item xs={!lastStep ? 6 : 12} className={classes.buttonGridItem}>
        <Button
          disabled={lastStep && disabled}
          variant='contained' 
          color='primary' 
          className={classes.primaryButton}
          onClick={lastStep ? () => {handleDismissInstructions(lastStep)} : handleProceedToNextStep}
        >{lastStep ? t('instructions.letsGetStarted') : t('instructions.nextButtonText')}</Button>
      </Grid>
    </Grid>
  )
}

export default InstructionButtons
