import { DialogState } from "./interfaces"

const initialDialogState: DialogState = {
  title: null,
  text: null,
  show: false,
  link: null,
  onCancel: null,
  onAccept: null,
  onCancelText: null,
  onAcceptText: null,
  file: null,
}

export default initialDialogState
