import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Button, Grid, List, ListItem, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore' 
import { t } from '../../../utils';
import { StylesState } from '../../../store/stylesState/interfaces';
import { StyleCard } from './StyleCard';
import { Package, PackageSet } from '../../../axios/instances/interfaces';
import { getSubPackagesForPackage } from './utils';
import { ChevronLeft } from '@material-ui/icons';
import { SubPackageCard } from './SubPackageCard';
import styleAccordionStyles from './StyleAccordion.styles';
import SelectionButton from "../../common/SelectionButton";
import ProductButton from "../../common/ProductButton";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

interface StyleAccordionProps {
  menuLevel: number;
  packageSet: PackageSet;
  stylesState: StylesState;
  isOpen: boolean;
  handleOnChange: (packageSetId: number) => void;
  handleMenuLevelChange: (menuLevel: number) => void;
  doSelectPackage: (theme: Package) => void;
}

export enum MENULEVEL { MAIN = 0, SUBPACKAGE_LEVEL = 1}

export const StyleAccordion: React.FC<StyleAccordionProps> = ({menuLevel, packageSet, stylesState, isOpen, handleOnChange, handleMenuLevelChange, doSelectPackage }: StyleAccordionProps) => {

  const { packageSets } = stylesState;
  const classes = styleAccordionStyles();
  const [selectedMainPackage, setSelectedMainPackage] = useState<Package>();

  useEffect(() => {
    const chosenMainPackage = packageSet?.packages.find(themePackage => themePackage.selected === true)
    if(chosenMainPackage) {
      setSelectedMainPackage(chosenMainPackage)
    }
  },[stylesState, packageSet.packages])

  const subPackagesForPackage = getSubPackagesForPackage(selectedMainPackage?.id, packageSets)

  return (
    menuLevel === MENULEVEL.MAIN ? ( 
      <Accordion className={classes.accordion} expanded={isOpen} onChange={() => handleOnChange(packageSet.id)}>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <Typography className={classes.accordionHeading} >{packageSet.name}</Typography> 
            </ListItem>
            <ListItem className={classes.listItem}>
            <Typography className={classes.secondaryHeading} >{t("theming.selected")}: {selectedMainPackage?.displayName}</Typography>  
            </ListItem>
          </List>
          </AccordionSummary>
          <Grid container spacing={1} className={classes.grid}>
            {
            packageSet.packages.map((themePackage) => {
              const subPackages = getSubPackagesForPackage(themePackage.id, stylesState.packageSets )
              const isSelected = themePackage.id === selectedMainPackage?.id
              return (
                <Grid key={themePackage.id} item xs={12}>
                  <SelectionButton 
                      onSelect={() => {doSelectPackage(themePackage)}}
                      isSelected={isSelected}
                      selectionName={themePackage.name}
                      selectCaption={t('theming.choose')} 
                      selectedCaption={t('theming.selected')}
                      selectionImageUrl={themePackage.images[0]?.thumbnailUrl}
                      subSelection={subPackages.length ? {
                        subHeader: t('theming.subThemes'),
                        buttonCaption: t('theming.selectSubTheme'),
                        selectionAction: () => handleMenuLevelChange(MENULEVEL.SUBPACKAGE_LEVEL)                        
                      } : undefined}
                  />
                  {/**
                    <StyleCard key={themePackage.id} selectPackage={doSelectPackage} {...{
                    themePackage,
                    isSelected,
                    subPackages,
                    handleMenuLevelChange
                  }}/>
                  /**/}
                </Grid>
              )
            })}
          </Grid>
      </Accordion>) : 
      (isOpen ? (
        <div className={classes.subPackageViewContainer}>
          <Button className={classes.returnButton} onClick={() => {
            handleMenuLevelChange(MENULEVEL.MAIN)
            }}
            size='large'
            startIcon={<ChevronLeft />}
          > 
              {t("theming.returnToPackageSet")} {packageSet.displayName}
          </Button>
          {subPackagesForPackage &&
              <div className={classes.accordion} style={{padding: '4px 0'}}>
                {
                subPackagesForPackage.map((subPackage) => {
                /**/
                return <ProductButton key={subPackage.id}
                productImageUrl={subPackage.images[0].thumbnailUrl}
                productName={subPackage.name}
                adjective={subPackage.selected ? t('theming.selected') : undefined}
                icon={subPackage.selected ? <CheckCircleRoundedIcon className={classes.checkMark}/> : undefined}
                onClicked={() => {
                  doSelectPackage(subPackage)
                }}
                />
                /**/
                //return <SubPackageCard key={subPackage.id} subPackage={subPackage} isSelected={subPackage.selected} selectPackage={doSelectPackage}/>
              })}
            </div>
          }
        </div>
      ) : null
        
      )
  )
}