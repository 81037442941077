import { enGB, fi, ru, de, et, nb, cs, sk, sv, nl } from 'date-fns/locale';
import i18n from 'i18next';

export interface LanguageType {
  code: string;
  title: string;
  dateFnsLocaleKey: string;
  dateFnsLocaleValue: Locale;
}

export const languages: LanguageType[] = [
  // {
  //   code: 'en-US',
  //   title: 'English (US)',
  //   dateFnsLocaleKey: 'enUS',
  //   dateFnsLocaleValue: enUS
  // },
  {
    code: "en-GB",
    title: "English (GB)",
    dateFnsLocaleKey: 'enGB',
    dateFnsLocaleValue: enGB
  },
  {
    code: "fi-FI",
    title: "Suomi",
    dateFnsLocaleKey: 'fi',
    dateFnsLocaleValue: fi
  },
  {
    code: 'ru-RU',
    title: 'Russian',
    dateFnsLocaleKey: 'ru',
    dateFnsLocaleValue: ru
  },
  {
    code: 'de-DE',
    title: 'German',
    dateFnsLocaleKey: 'de',
    dateFnsLocaleValue: de
  },
  {
    code: 'et-EE',
    title: 'Estonian',
    dateFnsLocaleKey: 'et',
    dateFnsLocaleValue: et
  },
  {
    code: 'nb-NO',
    title: 'Norwegian',
    dateFnsLocaleKey: 'nb',
    dateFnsLocaleValue: nb
  },
  // {
  //   code: 'es-ES',
  //   title: 'Spanish',
  // },
  {
    code: 'cs-CZ',
    title: 'Czech',
    dateFnsLocaleKey: 'cs',
    dateFnsLocaleValue: cs
  },
  {
    code: 'sk-SK',
    title: 'Slovak',
    dateFnsLocaleKey: 'sk',
    dateFnsLocaleValue: sk
  },
  {
    code: 'sv-SE',
    title: 'Swedish',
    dateFnsLocaleKey: 'sv',
    dateFnsLocaleValue: sv
  },
  // {
  //   code: 'lt-LT',
  //   title: 'Lithuanian',
  // },
  // {
  //   code: 'fr-FR',
  //   title: 'French',
  // },
  {
    code: 'nl-NL',
    title: 'Dutch',
    dateFnsLocaleKey: 'nl',
    dateFnsLocaleValue: nl
  }
];

export const getLocales = (): { [index: string]: Locale } => {
  const locales: { [index: string]: Locale } = {};
  languages.forEach((language) => {
    locales[language.code] = language.dateFnsLocaleValue;
  });
  return locales;
};

export const getDateFnsLocale = (): Locale => {
  return getLocales()[i18n.language || 'en'];
};
