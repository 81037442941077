import React, {useContext, useEffect, useState} from "react";
import useDecorationRotationToolStyles from "./DecorationRotationTool.styles";
import {RotateRight} from "@material-ui/icons"
import {DecoStateContext} from "../../store/decoState/DecoStateProvider";
import {DecorationInputState, DecoStateAction} from "../../store/decoState/interfaces";

function dimensions(){
    const {innerWidth: x, innerHeight: y} = window;
    return({x, y});
}

function useWindowDimensions() {
    
    const [windowDimensions, setDimensions] = useState(dimensions());
    
    useEffect(() =>{
        
        const handle = () => {
            setDimensions(dimensions());
        }
        
        window.addEventListener('resize', handle);
        return () => {
            window.removeEventListener('resize', handle);
        }}, [])
    
    return windowDimensions;
}

interface rotationProps{
    posX: number,
    posY: number,
    rotateDecoration: (angle: number) => void,
}

const DecorationRotationTool: React.FC<rotationProps> = ({posX, posY, rotateDecoration}:rotationProps) => {
    
    const radius = 100;
    const [iconPosition , setIconPosition] = useState<{x: number, y: number}>({x:posX - radius,y:posY});
    const [windowDimensions] = useState<{x: number, y: number}>(useWindowDimensions())
    const classes = useDecorationRotationToolStyles({posX, posY, iconX: iconPosition.x, iconY: iconPosition.y, radius})();
    const {dispatch} = useContext(DecoStateContext)
    
    // Find point on the radius of the circle at a direction of the pointer
    const pointOnRadius = (mPosX: number, mPosY: number) => {
        const distance = Math.sqrt(
            Math.pow(mPosX - posX,2)
            + Math.pow(mPosY - posY, 2)
        );
        const dr = radius / distance;
        
        const x = (1-dr)*posX + dr*mPosX;
        const y = (1-dr)*posY + dr*mPosY;
        
        return {x, y}
    }
    
    const aTan = (x: number, y: number) => {
        
        // Vector from center to cursor direction
        const x1 = x - posX;
        const y1 = y - posY;
        // The second vector (start vector) is the default icon position.
        const x2 = -radius;
        const y2 = 0;
        // Dot product and determinant
        const dot = x1 * x2 + y1 * y2;
        const det = x1 * y2 - y1 * x2;
        // Angle in degrees
        const a = Math.atan2(det, dot) * (180/Math.PI);
        rotateDecoration(a);
    }
        
    useEffect(() => {
        
        const pointerMove = (event: PointerEvent) => {
            // Note: pointer position comes from top, while deco element comes from bottom
            const point = pointOnRadius( event.x, (windowDimensions.y - event.y))
            setIconPosition(point);
            aTan(point.x, point.y);
        };
        
        const pointerUp = (event: PointerEvent) => {
            dispatch({type: DecoStateAction.DECO_INPUT, data: DecorationInputState.IDLE})
        }
        
        window.addEventListener('pointermove', pointerMove);
        window.addEventListener('pointerup', pointerUp);
        return () => {
            window.removeEventListener('pointermove', pointerMove);
            window.removeEventListener('pointerup', pointerUp);
        }
        
    }, [])
    
    return(<>
        <div className={classes.container}>
            <RotateRight className={classes.icon} />
        </div>
        <RotateRight className={classes.test} />
    </>)
}

export default DecorationRotationTool;