import deepmerge from "deepmerge"
import initialDialogState from "./initialDialogState"
import {DialogState, DialogStateAction, DialogStateActionType,} from "./interfaces"

const DialogStateReducer = (
  dialogState: DialogState,
  action: DialogStateActionType
): DialogState => {
  switch (action.type) {
    case DialogStateAction.OPEN_DIALOG:
      return deepmerge(dialogState, action.data)
    case DialogStateAction.CLOSE_DIALOG:
      return initialDialogState
    case DialogStateAction.CUSTOM_DIALOG:
      return { 
        ...dialogState, 
        customDialog: action.data 
      }
    default:
      throw new Error(`Bad reducer action.`)
  }
}

export default DialogStateReducer
