import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "normalize.css"
import GlobalStateProvider from "./store/globalState/GlobalStateProvider"
import { ThemeProvider } from "styled-components"
import mainTheme from "./styled/theme"
import GlobalStyle from "./styled/createGlobalStyle"

import "./i18n"

import DialogStateProvider from "./store/dialogState/DialogStateProvider"
import Dialog from "./components/common/Dialog/Dialog"
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      <GlobalStateProvider>
        <DialogStateProvider>
          <App />
          <Dialog />
        </DialogStateProvider>
      </GlobalStateProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
