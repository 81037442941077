import React from "react"
import styled from "styled-components"
import {
  mediaHeightMDown,
  mediaMediumUp,
  mediaQuery,
} from "../../../styled/mediaQueries"
import { t } from "../../../utils"
import Background from "../Background"
import Paper from "../Paper/Paper"
import { H6 } from "../texts"
import Pre from "../texts/Pre"

const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 28rem;
  max-width: calc(100% - 3.75rem);

  ${mediaQuery(mediaHeightMDown)} {
    width: 100%;
  }

  ${mediaQuery(mediaMediumUp)} {
    width: 60rem;
  }

  overflow: hidden;
`

interface ErrorFallbackProps {
  error: Error
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error }) => {
  return (
    <Background>
      <Paper variants="mobileFullScreen contentCenter">
        <ErrorContent role="alert">
          <H6 variants="withMarginBottom">{t("unknownErrorUserMessage")}</H6>
          <Pre variants="withMarginBottom">{error.message}</Pre>
          <Pre variants="stacktrace">{error.stack}</Pre>
        </ErrorContent>
      </Paper>
    </Background>
  )
}

export default ErrorFallback
