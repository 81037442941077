import styled from "styled-components"
import {
  PaperTheme,
  ThemeInterface,
  Variants,
} from "../../../styled/interfaces"
import {
  mediaHeightLDown,
  mediaMediumDown,
  mediaQuery,
} from "../../../styled/mediaQueries"
import { getVariantStyles } from "../../../styled/utils"
import React, { CSSProperties, useLayoutEffect, useState } from "react"

const PaperStyled = styled.div(
  (props: {
    theme: ThemeInterface
    variants?: Variants
    children: React.ReactNode
    anchored: boolean
  }) => {
    const paperStyles = getVariantStyles<PaperTheme>(
      props.theme.paper,
      props.variants
    )

    return `
      max-height: 100vh;
      overflow: hidden;
      padding: calc(${paperStyles.padding} * var(--size-factor));
      border-radius: ${paperStyles.borderRadius};
      border-style: ${paperStyles.borderStyle};
      border-width: ${paperStyles.borderWidth};
      background-color :${paperStyles.background};
      border-color: ${paperStyles.borderColor};
      opacity: 1;

      ${
        paperStyles.mobileFullScreen
          ? `
          ${mediaQuery(`${mediaMediumDown}, ${mediaHeightLDown}`)} {
            min-height: 100%;
            width: 100%;
            padding: 0;
            border-width: 0;
            border-radius: 0;
            background-color: ${paperStyles.fullscreenBackground};
          }
      `
          : ""
      }

      ${
        paperStyles.contentCenter
          ? `
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      `
          : ""
      }

      ${
        paperStyles.appearAnimation
          ? `
        animation: .6s appear-animation;
      `
          : ""
      }

      ${
        props.anchored
          ? `
        position: fixed;

        ${
          paperStyles.mobileFullScreen
            ? `
            ${mediaQuery(`${mediaMediumDown}, ${mediaHeightLDown}`)} {
              // when paper is anchored and fullscreen, it should override any height/width settings.
              min-height: 100%!important;
              min-width: 100%!important;
              max-height: 100%!important;
              max-width: 100%!important;
              left: 0!important;
              top: 0!important;
              padding: 0;
              border-width: 0;
              border-radius: 0;
              z-index: 100;
              background-color: ${paperStyles.fullscreenBackground};
            }
        `
            : ""
        }
      `
          : ""
      }
    `
  }
)

const Paper: React.FC<{
  anchorRef?: React.RefObject<HTMLElement | null>
  variants?: Variants
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
  offset?: {
    top?: number
    left?: number
    width?: number
    height?: number
  }
  className?: string
  forceHeight?: boolean
  mobileClosebutton?: boolean
  style?: CSSProperties
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}> = ({
  anchorRef,
  variants,
  children,
  offset,
  forceHeight,
  style,
  mobileCloseButton,
  ...rest
}) => {
  const [top, setTop] = useState(0)
  const [height, setHeight] = useState(0)
  const [left, setLeft] = useState(0)
  const [width, setWidth] = useState(0)

  const updatePosition = () => {
    const anchorPosition = anchorRef?.current?.getBoundingClientRect()
    setTop(anchorPosition?.bottom || 0)
    setHeight(window.innerHeight - (anchorPosition?.bottom || 0))
    setLeft(anchorPosition?.left || 0)
    setWidth(window.innerWidth - (anchorPosition?.left || 0))
  }

  useLayoutEffect(() => {
    updatePosition()
    window.addEventListener("resize", updatePosition)
    return () => window.removeEventListener("resize", updatePosition)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PaperStyled
      style={{
        top: anchorRef ? `calc(${top}px + ${offset?.top || 0}px)` : undefined,
        left: anchorRef
          ? `calc(${left}px + ${offset?.left || 0}px)`
          : undefined,
        maxHeight: anchorRef
          ? `calc(${height}px + ${offset?.height || 0}px - ${
              offset?.top || 0
            }px)`
          : undefined,
        height:
          anchorRef && forceHeight
            ? `calc(${height}px + ${offset?.height || 0}px - ${
                offset?.top || 0
              }px)`
            : undefined,
        maxWidth: anchorRef
          ? `calc(${width}px + ${offset?.width || 0}px - ${
              offset?.left || 0
            }px)`
          : undefined,
        ...(style || {}),
      }}
      anchored={!!anchorRef}
      variants={variants}
      {...rest}
    >
      {children}
    </PaperStyled>
  )
}

export default Paper
