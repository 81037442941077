import React, {Dispatch, useMemo, useReducer} from "react";
import {SettingsState, SettingsStateActionType, SettingsStateReducerType} from "./interfaces";
import initialState from "../settingsState/InitialState";
import SettingsStateReducer from "./SettingsStateReducer";


export const SettingsStateContext = React.createContext<{
    settingsState: SettingsState,
    dispatch: Dispatch<SettingsStateActionType>
}>({
    settingsState: initialState,
    dispatch: () => initialState
})

const {Provider} = SettingsStateContext;

interface props{
    children: React.ReactNode
}

const SettingsStateProvider: React.FC<props> = ({children}:props) => {
    
    const [settingsState, dispatch] = useReducer<SettingsStateReducerType>(
        SettingsStateReducer,
        initialState
    )
    
    const contextValue = useMemo(() => 
        ({settingsState, dispatch}), 
        [settingsState, dispatch]);
    
    return<Provider value={contextValue}>
        {children}
    </Provider>
}

export default SettingsStateProvider;