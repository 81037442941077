import React, { Dispatch, useMemo, useReducer } from "react"
import threeDStateReducer from "./threeDStateReducer"
import initialThreeDState from "./initialThreeDState"
import {
  ThreeDStateReducerType,
  ThreeDState,
  ThreeDStateActionType,
} from "./interfaces"

export const ThreeDStateContext = React.createContext<{
  threeDState: ThreeDState
  dispatch: Dispatch<ThreeDStateActionType>
}>({ threeDState: initialThreeDState, dispatch: () => initialThreeDState })

const { Provider } = ThreeDStateContext

interface ThreeDStateProviderProps {
  children: React.ReactNode
}

const ThreeDStateProvider: React.FC<ThreeDStateProviderProps> = ({
  children,
}) => {
  const [threeDState, dispatch] = useReducer<ThreeDStateReducerType>(
    threeDStateReducer,
    initialThreeDState
  )

  const contextValue = useMemo(() => ({ threeDState, dispatch }), [
    threeDState,
    dispatch,
  ])

  return <Provider value={contextValue}>{children}</Provider>
}

export default ThreeDStateProvider
