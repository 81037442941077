import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


const NavigationBarStyles = makeStyles((theme: Theme) => createStyles({
    bar:{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: "wrap",
    },
    navButton:{
        display: 'flex',
        flexDirection: 'row',

        width: 'fit-content',
        height: 'fit-content',

        background: 'white',
        border: '1px solid #EAECF0',
        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        borderRadius: '8px',
        
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
        
        padding: '4px',
        paddingRight: '6px',
        paddingLeft: '6px',

        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(0.9)',
        }
    }
}))

export default NavigationBarStyles;