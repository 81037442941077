import { useContext, useEffect } from "react"
import { getMaterials } from "../axios"
import { GetMaterialsParams } from "../axios/instances/interfaces"
import { GlobalStateContext } from "../store/globalState/GlobalStateProvider"
import {
  StylesStateAction,
  StylesStateActionType,
} from "../store/stylesState/interfaces"
import { StylesStateContext } from "../store/stylesState/StylesStateProvider"

interface MaterialsFetcherReturnValue {
  fetchMaterials: () => void
  triggerFetchMaterials: () => void
}

export const composeDoFetch =
  (
    params: GetMaterialsParams,
    dispatch: React.Dispatch<StylesStateActionType>
  ) =>
  async (): Promise<void> => {
    dispatch({ type: StylesStateAction.SET_FETCHING_MATERIALS, data: true })
    const response = await getMaterials(params)
    dispatch({
      type: StylesStateAction.SET_MATERIALS,
      data: response.data.data,
    })
    dispatch({ type: StylesStateAction.SET_FETCHING_MATERIALS, data: false })
  }

export const useMaterialsFetcher = (): MaterialsFetcherReturnValue => {
  const { globalState } = useContext(GlobalStateContext)
  const { stylesState, dispatch } = useContext(StylesStateContext)

  if (
    !globalState.startParameters.apartmentId ||
    !globalState.startParameters.api ||
    !globalState.startParameters.company ||
    !globalState.startParameters.projectId ||
    !globalState.startParameters.schema
  )
    throw new Error("Start parameters are missing in materials fetcher.")
  if (!globalState.token) throw new Error("Token missing in materials fetcher.")

  const params: GetMaterialsParams = {
    apartmentId: globalState.startParameters.apartmentId,
    apiUrl: globalState.startParameters.api,
    organisationId: globalState.startParameters.company,
    projectId: globalState.startParameters.projectId,
    tenantId: globalState.startParameters.schema,
    token: globalState.token,
    includeApartmentBundles: true,
    includeRoomBundles: true,
    includeMaterialOptions: true,
  }

  const doFetch = composeDoFetch(params, dispatch)
  const triggerFetchMaterials = () => {
    if (globalState.token)
      doFetch()
  }

  useEffect(() => {
    if (!stylesState.materialsFetched && globalState.token) {
      doFetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { fetchMaterials: () => doFetch(), triggerFetchMaterials }
}

export default useMaterialsFetcher
