import React, {useContext, useEffect, useState} from 'react';
import {FloorChangeDirection, MoveType} from './threeDStreaming';
import {MovementButtonContainer} from './MovementButtonContainer';
import {ControlButton} from './ControlButton';
import {Grid} from '@material-ui/core';
import {ActionButtonContainer} from './ActionButtonContainer';
import {ReactComponent as LayoutIcon} from '../../images/icons/layout_icon.svg';
import {ReactComponent as ScreenshotIcon} from '../../images/icons/screenshot_icon.svg';
import DetailsRoundedIcon from '@material-ui/icons/DetailsRounded';
import ChangeHistoryRoundedIcon from '@material-ui/icons/ChangeHistoryRounded';
import controlsStyles from './Controls.styles';
import useEventLogger from '../../analytics/useEventLogger';
import {GlobalStateContext} from "../../store/globalState/GlobalStateProvider";
import {GlobalStateAction} from "../../store/globalState/interfaces";
import {SettingsStateContext} from "../../store/settingsState/SettingsStateProvider";

interface ControlsProps {
  onHold: (type: MoveType, stop?: boolean) => void;
  showControls: boolean;
  inProgress: boolean;
  floors: number;
  isLayoutView: boolean;
  isSmallScreen: boolean;
  instructionsDismissed: boolean;
  selectFloor: (floorChangeDirection: FloorChangeDirection) => void;
  takePicture: () => void;
  resetCamera: () => void;
}

const Controls: React.FC<ControlsProps> = ({
  onHold,
  showControls,
  inProgress,
  floors,
  isLayoutView,
  isSmallScreen,
  instructionsDismissed,
  selectFloor,
  takePicture,
  resetCamera
}) => {
  const { logActions } = useEventLogger();
  const isMultiFloor = floors && floors > 1;

  const {dispatch} = useContext(GlobalStateContext)
  const {settingsState} = useContext(SettingsStateContext);
  
  const [sc, setShowControls] = useState(showControls)
  const [movementKeys, setShowMovementKeys] = useState(settingsState.movementKeysVisible)
  useEffect(() => { setShowControls(showControls) }, [showControls])
  useEffect(() => { setShowMovementKeys(settingsState.movementKeysVisible)}, [settingsState.movementKeysVisible])

  const classes = controlsStyles({movementKeysVisible: settingsState.movementKeysVisible})();
    
  const handleScreenShot = () => {
    takePicture();
  };
  const handleLayoutChange = () => {
    logActions('layout_button_clicked', 'Layout button clicked', 'Layoutbutton_1');
    dispatch({type: GlobalStateAction.SET_CAMERA_MODE, data: 0 })
    //resetCamera();
  };

  const handleFloorChange = (floorChangeDirection: FloorChangeDirection) => {
    selectFloor(floorChangeDirection);
    logActions(
      'floor_changed',
      'User changed floor with floor button ' + floorChangeDirection,
      floorChangeDirection === 1 ? 'FloorChange_1' : 'FloorChange_2'
    );
  };

  const actionButtons = [
    <ControlButton
      key="takeScreenShot"
      icon={<ScreenshotIcon className={classes.iconStyle} />}
      inProgress={inProgress}
      onClick={() => handleScreenShot()}
    />,
    <ControlButton
      key="resetCameraView"
      icon={<LayoutIcon className={classes.iconStyle} />}
      onClick={() => handleLayoutChange()}
    />
  ];

  const layoutViewButtons = [
    <Grid item key="screenshotGrid">
      <ControlButton
        key="takeScreenShot"
        icon={<ScreenshotIcon className={classes.iconStyle} />}
        inProgress={inProgress}
        onClick={() => handleScreenShot()}
      />
    </Grid>,
    isMultiFloor && (
      <Grid item key="floorChangeGrid">
        <ControlButton
          key="floorUp"
          icon={<ChangeHistoryRoundedIcon className={classes.iconStyle} />}
          onClick={() => handleFloorChange(FloorChangeDirection.UP)}
        />
        <ControlButton
          key="floorDown"
          icon={<DetailsRoundedIcon className={classes.iconStyle} />}
          onClick={() => handleFloorChange(FloorChangeDirection.DOWN)}
        />
      </Grid>
    )
  ];

  // ABOUT WEIRD ROTATE: https://stackoverflow.com/questions/40363916/svg-transform-rotate-by-90-180-or-270-degrees-not-working-on-circle-in-safari-i
  return (
    <>
      {sc && !isLayoutView ? (
        isSmallScreen ? (
          <Grid container direction="row" className={classes.smallScreenControls}>
            <Grid item className={classes.smallScreenGridItemLeft} style={{pointerEvents: 'auto'}}>
              <ControlButton
                key="takeScreenShot"
                icon={<ScreenshotIcon className={classes.iconStyle} />}
                inProgress={inProgress}
                onClick={() => handleScreenShot()}
              />
            </Grid>
            {movementKeys && <Grid item style={{pointerEvents: 'auto'}}>
              <MovementButtonContainer onHold={onHold}/>
            </Grid>}
            <Grid item className={classes.smallScreenGridItemRight} style={{pointerEvents: 'auto'}}>
              <ControlButton
                key="resetCameraView"
                icon={<LayoutIcon className={classes.iconStyle} />}
                onClick={() => handleLayoutChange()}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" className={classes.controls}>
            <Grid item className={classes.actions} style={{pointerEvents: 'auto'}}>
              <ActionButtonContainer
                buttons={actionButtons}
                {...{ sc, floors, isLayoutView }}
              />
            </Grid>
            {movementKeys && <Grid item style={{pointerEvents: 'auto'}}>
              <MovementButtonContainer onHold={onHold}/>
            </Grid>}
          </Grid>
        )
      ) : instructionsDismissed ? (
        <Grid item className={classes.layoutViewControls} style={{pointerEvents: 'auto'}}>
          <ActionButtonContainer
            buttons={layoutViewButtons}
            {...{ sc, floors, isLayoutView, selectFloor }}
          />
        </Grid>
      ) : null}
    </>
  );
};

export default Controls;
