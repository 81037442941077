import { Dispatch, useEffect } from "react"
import {
  GlobalStateAction,
  GlobalStateActionType,
  StartUrlParametersInterface,
} from "../store/globalState/interfaces"

const useSetupApp = (
  dispatch: Dispatch<GlobalStateActionType>,
  getStartParameters: () => StartUrlParametersInterface
): void => {
  useEffect(() => {
    dispatch({ type: GlobalStateAction.SETUP, data: getStartParameters() })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useSetupApp
