import { CircularProgress, Typography } from '@material-ui/core';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import snackBarStyles from './SnackBar.styles';
import {SnackbarStateContext} from "../../store/snackbarState/SnackbarStateProvider";
import {ms} from "date-fns/locale";

interface InfoProps{
    isVisible: boolean;
    text?: string;
    allowClose?: boolean;
}

export const LoadingInfoBar: React.FC = () => {
    
    const classes = snackBarStyles();
    const [error, setError] = useState<string | undefined>();
    const [info, setInfo] = useState<string | undefined>();
    const [timeoutId, setTimeoutId] = useState<number | undefined>();
    // Set visibility with a boolean for smooth transition
    const [visible, setVisible] = useState(false);
    const {snackbarState} = useContext(SnackbarStateContext);
    
    useEffect(() => {
        if(snackbarState.snackbars.length)
        {
            const msg = snackbarState.snackbars[0];
            if(msg.isError)
            {
                setError(msg.message);
            }
            else if(!error && msg.message)
            {
                setInfo(msg.message)
            }
            else{
                setInfo(undefined)
            }
            
            if(timeoutId)
            {
                window.clearTimeout(timeoutId);
                setTimeoutId(undefined);
            }
            
            setVisible(Boolean(error || msg.message));
            if(msg.time) {
                setTimeoutId(
                window.setTimeout(() => {
                    setError(undefined);
                    setInfo(undefined);
                    setVisible(false);
                }, msg.time));
            }
        }
    },[snackbarState.snackbars])
    
    return(<>
        <Snackbar className={classes.root} 
                  open={visible}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
            <Alert className={classes.root} 
                   variant={error ? 'filled' : 'standard'}
                   style={{backgroundColor: error ? undefined : 'white'}}
                   severity={error ? 'error' : 'info'}>
                <Typography variant={'body1'}>
                    {error ? error : info}
                </Typography>
                {!error && info && 
                    <CircularProgress size='24px' className={classes.spinner} />}
            </Alert>
        </Snackbar>
    </>);
};

// Purely informational box. Therefore made more simple
export const TopInfoBar: React.FC<InfoProps> = ({
    isVisible,
    text,
    allowClose,
    }) => {
    
    const classes = snackBarStyles();
    const [visible, setVisibility] = useState(isVisible);
        
    return(
        <>
            <Snackbar className={classes.root} open={isVisible && visible} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert className={classes.root} 
                       style={{backgroundColor: "white"}}
                       severity={'info'}
                       onClose={allowClose ? () => setVisibility(false) : undefined}>
                    <Typography variant={'body1'}>
                        {text}
                    </Typography>
                </Alert>
            </Snackbar>
        </>);
};