import { createStyles, makeStyles, Theme } from "@material-ui/core"

const stylesAndMaterialsStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerDiv: {
      width: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        height: '100%'
    },
    icon: {
      height: '2rem',
      width: '2rem',
      position: 'relative',
      top: '50%',
    },
    materialIcon: {
      height: '2rem',
      width: '2rem',
      position: 'relative',
      top: '50%',
      transform: 'rotate(180deg)'
    }
  }),
);

export default stylesAndMaterialsStyles