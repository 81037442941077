import React, { useContext } from "react"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "./components/common/ErrorFallback/ErrorFallback"
import ThreeDStream from "./components/ThreeDStream/ThreeDStream"
import Welcome from "./components/Tutorial/Welcome/Welcome"
import useSetupApp from "./hooks/useSetupApp"
import useTenantSettingsFetcher from "./hooks/useTenantSettingsFetcher"
import { GlobalStateContext } from "./store/globalState/GlobalStateProvider"
import {
  GlobalStateAction,
  GlobalStateActionType,
} from "./store/globalState/interfaces"
import ThreeDStateProvider from "./store/threeDState/ThreeDStateProvider"
import { getStartParameters } from "./utils/StartUrlParameters"
import useLogin from "./hooks/useLogin"
import StylesStateProvider from "./store/stylesState/StylesStateProvider"
import { useGetCustomizationSettings } from './hooks/useGetCustomizationSettings'
import defaultTheme from './store/themes/default';
import Background from "./components/common/Background"
import useApartmentInfoFetcher from "./hooks/useApartmentInfoFetcher"
import LanguageSelect from "./components/LanguageSelect/LanguageSelect"
import { ThemeProvider } from "@material-ui/core"
import DecoStateProvider from "./store/decoState/DecoStateProvider";
import SnackbarStateProvider from "./store/snackbarState/SnackbarStateProvider";
import DialogController from "./components/Dialog/DialogController";
import SettingsStateProvider from "./store/settingsState/SettingsStateProvider";

export const createOnError = (
  dispatch: React.Dispatch<GlobalStateActionType>
): ((
  error: Error,
  info?: {
    componentStack: string
  }
) => void) => error =>
  dispatch({ type: GlobalStateAction.SET_APPLICATION_ERROR, data: error })

const App: React.FC = () => {
  const { globalState, dispatch } = useContext(GlobalStateContext)


  useSetupApp(dispatch, getStartParameters)
  useLogin(dispatch, globalState)
  useTenantSettingsFetcher(dispatch, globalState)
  useApartmentInfoFetcher()

  const isRunningInIframe = window.self !== window.top

  const customizationSettings = useGetCustomizationSettings();
  const theme = defaultTheme(customizationSettings.parsedSettings);

  return Object.keys(globalState.tenantSettings).length && customizationSettings ? (
    <div style={{width: '100%', height: '100%', }} onContextMenu={ event => {
      event.preventDefault()
      event.stopPropagation()
    } }>
      <ThemeProvider theme={theme}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={createOnError(dispatch)}
        >
          <Background
            background={globalState.tenantSettings.customerJourney2_mainImage}
          >
            <div style={{touchAction: 'auto', pointerEvents: 'auto'}}>
              {globalState.hasThreeDStarted ? (
                <ThreeDStateProvider>
                  <SnackbarStateProvider >
                  <StylesStateProvider>
                    <SettingsStateProvider>
                      <DecoStateProvider>
                        <DialogController />
                        <ThreeDStream {...{isRunningInIframe}}/>
                      </DecoStateProvider>
                    </SettingsStateProvider>
                  </StylesStateProvider>
                  </SnackbarStateProvider>
                </ThreeDStateProvider>
              ) : (
                <>
                  <Welcome />
                  {!isRunningInIframe && <LanguageSelect /> }
                </>
              )}
            </div>
          </Background>
        </ErrorBoundary>
    </ThemeProvider>
    </div>
  ) : null
}

export default App
