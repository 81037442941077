import React, { useMemo } from "react"
import backgroundStyles from './BackGround.styles'
interface BackgroundProps {
  children: React.ReactNode
  background?: string
}

const Background: React.FC<BackgroundProps> = ({
  children,
  background,
}) => {
  const classes = backgroundStyles()
  return useMemo(
    () => (
      <div className={classes.root} style={{
        backgroundImage: `url(${background})`}}
      >
        {children}
      </div>
    ),
    [background, children]
  )
}

export default Background
