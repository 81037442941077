import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {th} from "date-fns/locale";

interface props{
  isTouchOverride: boolean;
}
const useStyles = ({isTouchOverride}:props) =>
  makeStyles((theme: Theme) => {
    return createStyles({
      root: {
        display: 'flex',
        flexDirection: 'row',
        padding: `0 ${theme.spacing(1)}px`,
        height: `calc(100px)`,
        touchAction: isTouchOverride ? 'none' : '',
        width: '100%',
        marginBottom: theme.spacing(1),
      },
      productButton: {
        display: 'inline-flex',
        flexDirection: 'row',

        background: 'transparent',
        width: '100%',
        padding: '5px 0 5px 0 !important',
        borderRadius: '10px',

        border: 'none',

        cursor: 'pointer',

        '&:hover': {
          background: 'white',
          color: 'black'
        },
        touchAction:  isTouchOverride ? 'none' : '',
      },
      gridItem:{
        maxHeight: '90px',
        display: 'grid'
      },
      productTextContainer: {
        marginLeft: '10px',
        display: 'inline-flex',
        flexDirection: 'column',
        width: '100%',
        maxHeight: '90px'
      },
      productCaption: {
        width: '100%',
        textAlign: 'left',
        fontWeight: 'bold'
      },
      adjective: {
        color: theme.palette.textColor.main,
        fontSize: '0.8rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.8em'
        }
      },
      productDescription: {
        display: 'inline-block',
        width: '100%',
        maxHeight: '100%',
        height: '70px',
        textAlign: 'left',
        color: 'grey',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      },
      productImage: {
        margin: '0',
        alignSelf: 'center',
        justifySelf: 'center',

        padding: '0px !important',
        maxWidth: '95%',
        maxHeight: '86px',
        //aspectRatio: '1/1',
        borderRadius: '10%',
        color: 'grey',
        
        pointerEvents: 'none',
        touchAction: 'none',
      }
    });
  });

export default useStyles;
