import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'


const styleCardSelectionMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      padding: theme.spacing(0),
    },
    paper: {
      borderStyle: 'none !important',
      boxShadow: 'none !important',
      borderRadius: '10px !important'
    },
  }),
);

export default styleCardSelectionMenuStyles;