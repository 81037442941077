import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const LayoutDialogStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root:{
            display: 'flex',
            flexDirection: 'column',
            
            backgroundColor: 'white',
            border: '1px solid #EAECF0',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderRadius: '8px',
            padding: '16px',

            minWidth: '30vw',
            minHeight: '25vh',
            
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        },
        text: {
            marginBottom: '10px',
            textAlign: 'center'
        },
        closeButton:{
            marginTop: '10px',
            
            background: 'white',
            border: '1px solid #EAECF0',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderRadius: '8px',

            '&:hover': {
                background: theme.palette.secondary.main,
                color: 'white'//'#667085'
            }
        }
    })
})

export default LayoutDialogStyles;