import {GlobalState, GlobalStateAction, GlobalStateActionType,} from "./interfaces"

const globalStateReducer = (
  globalState: GlobalState,
  action: GlobalStateActionType
): GlobalState => {
  switch (action.type) {
    case GlobalStateAction.SET_HAS_THREE_D_STARTED_ACTION:
      return {
        ...globalState,
        hasThreeDStarted: action.data,
      }
    case GlobalStateAction.SETUP:
      return {
        ...globalState,
        startParameters: action.data,
      }
    case GlobalStateAction.SET_APPLICATION_ERROR:
      return {
        ...globalState,
        applicationError: action.data,
      }
    case GlobalStateAction.SET_TENANT_SETTINGS:
      return {
        ...globalState,
        tenantSettings: action.data,
      }
    case GlobalStateAction.SET_TOKEN:
      return {
        ...globalState,
        token: action.data,
      }
    case GlobalStateAction.SET_APARTMENT_INFO:
      return { ...globalState, apartmentInfo: action.data }
    case GlobalStateAction.SET_CAMERA_MODE:
        return {
          ...globalState, 
          cameraMode: action.data, 
          // Remember the camera mode we were in when jumping from overhead view in to the apartment
          previousCameraMode: action.data === 0 ? globalState.previousCameraMode : action.data,
        }
    case GlobalStateAction.HIDE_PANEL:
      return {...globalState, isPanelHidden: action.data}
    case GlobalStateAction.OPEN_PAGE:
      return {...globalState, activePage: action.data }
    case GlobalStateAction.SET_HEADER:
      return {
        ...globalState, 
        headerText: action.data?.headerText,
        headerIcon: action.data?.headerIcon
      }
    default:
      throw new Error(`Bad reducer action.`)
  }
}

export default globalStateReducer
