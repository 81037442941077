import styled from "styled-components"
import { TextTheme, ThemeInterface, Variants } from "../../../styled/interfaces"
import { getVariantStyles } from "../../../styled/utils"
import createTextStyles from "./createTextStyles"

export const Small = styled.small(
  (props: {
    theme: ThemeInterface
    variants?: Variants
    children?: React.ReactChildren | string
  }) => {
    const variants: Variants =
      typeof props.variants !== "string"
        ? {
            small: true,
            thin: true,
            ...(props.variants || {}),
          }
        : `small thin ${props.variants}`

    const textStyles = getVariantStyles<TextTheme>(props.theme.text, variants)

    const styles = createTextStyles(textStyles)

    return styles
  }
)

export default Small
