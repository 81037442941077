import React, { useEffect, useState } from 'react';
import useStyles from './SelectionButton.styles';
//import Icon, { IconKeys } from '../Icon';
import {Image} from "@material-ui/icons";
import {Button, CardMedia, Typography} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

interface SelectionProps {
  className?: string;
  isSelected: boolean;
  selectionName?: string;
  selectedCaption?: string;
  selectCaption: string;
  selectionImageUrl?: string;
  disabled?: boolean;
  allowReselect?: boolean;
  isCardButton?: boolean; // If the whole card functions as a button
  onSelect?: () => void;
  subSelection?: subSelection;
}

interface subSelection{
  subHeader?: string;
  buttonCaption: string;
  selectionAction: () => void;
}

/*
 * Leaving out optional values change how the component behaves
 * Leaving out:
 *  - selectionName => The select button takes the entire width of the component
 *  - selectionName & selectedCaption => An icon indicates selection when full width button has been clicked
 * 
 *  - isCardButton set to true => considers the whole card to be a button. Name and caption are only descriptive
 */
const SelectionButton: React.FC<SelectionProps> = ({
  className,
  isSelected,
  selectionName,
  selectedCaption,
  selectCaption,
  selectionImageUrl,
  disabled,
  onSelect,
  allowReselect,
  isCardButton,
  subSelection,
  ...rest
}: SelectionProps) => {
  const classes = useStyles({isCardButton})();
  const [hovering, setHovering] = useState(false);
  const [selected, setSelected] = useState<boolean>(isSelected);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const onClickSelect = () => {
    if (onSelect) {
      onSelect();
    }
    setSelected(true);
  };
  
  const onCardClick = () => {
    if(isCardButton && onSelect)
    {
      onSelect();
    }
  }
  
  const renderImage = () => {
    
    if(selectionImageUrl)
    {
      return <CardMedia className={classes.selectionImage} component="img" src={selectionImageUrl}/>
    }    
    return <Image className={classes.selectionImage} />    
  }
  
  const renderButton = () => {    
    // if this is selected and de/re-select has been disabled
    if(selected && !allowReselect)
    {
      // No description, replace with check icon
      if(selectionName || !selectedCaption)
      {
        return <CheckIcon className={classes.selectedIcon} />
      }
      else
      {
        //Replace button if caption is specified
        return <div className={classes.replacement}>
          <Typography className={classes.replacementText}>{selectedCaption}</Typography>
        </div>
      }
    }
    else // Render button
    {
      return <button
          disabled={disabled}
          className={`${classes.selectButton} ${isSelected ? classes.selectedButton : ''}`}
          onClick={onClickSelect}
      >
        <Typography className={classes.captionText}>{selectCaption}</Typography>
      </button>
    }    
  }
  
  const renderDescription = () => {
    return <div className={`${classes.captionContainer}`}>
      <Typography className={classes.captionText} variant={"body1"}>{selectionName}</Typography>
      <Typography variant={"body2"}>{selectCaption}</Typography>
    </div>
  }
  
  return (
    <div {...rest} onClick={onCardClick}
         onPointerEnter={() => setHovering(true)}
         onPointerLeave={() => setHovering(false)}
         className={`${className ?? ''} ${classes.root} ${selected ? classes.selectedCard : ''}`}>
      {renderImage()}
      <div className={`${classes.selectionContent} ${hovering && isCardButton ? classes.hover : ''}`}>
        {selectionName && !isCardButton && (
          <div className={classes.captionContainer}>
            <Typography className={classes.captionText}>{selectionName}</Typography>
            {selected ? (
              <Typography className={classes.selectedText}>{selectedCaption}</Typography>
            ) : (
              <></>
            )}
          </div>
        )}
        <>
          { isCardButton ?  renderDescription() : renderButton()}
        </>
      </div>
      {subSelection && isSelected && 
        <div className={classes.subSelection}>
          {subSelection.subHeader && <Typography className={classes.subSelectionHeader}>{subSelection.subHeader}</Typography>}
          <Button className={classes.subSelectionButton} onClick={subSelection.selectionAction}>{subSelection.buttonCaption}</Button>
        </div>
      }
    </div>
  );
};

export default SelectionButton;
