import { TenantSettings } from "./../../axios/instances/interfaces"
import StartUrlParameters from "../../utils/StartUrlParameters"

export enum GlobalStateAction {
  SET_HAS_THREE_D_STARTED_ACTION = "SET_HAS_THREE_D_STARTED_ACTION",
  SETUP = "SETUP",
  SET_TENANT_SETTINGS = "SET_TENANT_SETTINGS",
  SHOW_SNACKBAR_ALERT = "SHOW_SNACKBAR_ALERT",
  HIDE_SNACKBAR_ALERT = "HIDE_SNACKBAR_ALERT",
  SET_APPLICATION_ERROR = "SET_APPLICATION_ERROR",
  SET_TOKEN = "SET_TOKEN",
  SET_APARTMENT_INFO = "SET_APARTMENT_INFO",
  SET_CAMERA_MODE = "SET_CAMERA_MODE",
  OPEN_PAGE = "OPEN_PAGE",
  SET_HEADER = "SET_HEADER",
  HIDE_PANEL = "HIDE_PANEL"
}

export interface StartUrlParametersInterface {
  [StartUrlParameters.apartmentId]?: string
  [StartUrlParameters.api]?: string
  [StartUrlParameters.bim]?: string
  [StartUrlParameters.company]?: string
  [StartUrlParameters.lan]?: string
  [StartUrlParameters.projectId]?: string
  [StartUrlParameters.schema]?: string
  [StartUrlParameters.stage]?: string
  [StartUrlParameters.accessToken]?: string
  [StartUrlParameters.authToken]?: string
  [StartUrlParameters.username]?: string
  [StartUrlParameters.password]?: string
  [StartUrlParameters.isMobile]?: string
}

export interface ApartmentInfoInterface {
  name?: string
  apartmentId?: number
  id?: number
  ifcGuid?: string
  offersCost?: string
  projectId?: number
  projectIfcguid?: string
}

export interface SnackbarOptions {
  message: string
  isError?: boolean
  time?: number
  dismissable?: boolean
  id: string
}

export interface GlobalHeaderInterface {
  headerText: string;
  headerIcon?: JSX.Element;
}

export interface GlobalState {
  hasThreeDStarted: boolean
  startParameters: StartUrlParametersInterface
  tenantSettings: Partial<TenantSettings>
  applicationError: Error | null
  token: null | string
  apartmentInfo: ApartmentInfoInterface
  cameraMode?: number,
  previousCameraMode?: number,
  activePage?: JSX.Element,
  headerText?: string,
  headerIcon?: JSX.Element,
  isPanelHidden: boolean,
}

export interface SetHasThreeDStartedAction {
  type: GlobalStateAction.SET_HAS_THREE_D_STARTED_ACTION
  data: GlobalState["hasThreeDStarted"]
}

export interface SetupAction {
  type: GlobalStateAction.SETUP
  data: StartUrlParametersInterface
}
export interface SetApplicationErrorAction {
  type: GlobalStateAction.SET_APPLICATION_ERROR
  data: Error | null
}

export interface SetTokenAction {
  type: GlobalStateAction.SET_TOKEN
  data: null | string
}

export interface SetTenantSettingsAction {
  type: GlobalStateAction.SET_TENANT_SETTINGS
  data: TenantSettings
}

export interface SetApartmentInfoAction {
  type: GlobalStateAction.SET_APARTMENT_INFO
  data: ApartmentInfoInterface
}

export interface SetApplicationErrorAction {
  type: GlobalStateAction.SET_APPLICATION_ERROR
  data: Error | null
}

export interface SetTokenAction {
  type: GlobalStateAction.SET_TOKEN
  data: null | string
}

export interface SetCameraMode{
  type: GlobalStateAction.SET_CAMERA_MODE,
  data?: number
}

export interface OpenPage{
  type: GlobalStateAction.OPEN_PAGE,
  data?: JSX.Element
}

export interface SetHeader{
  type: GlobalStateAction.SET_HEADER,
  data?: GlobalHeaderInterface
}

export interface HidePanel{
  type: GlobalStateAction.HIDE_PANEL,
  data: boolean
}

export type GlobalStateReducerType = (
  state: GlobalState,
  action: GlobalStateActionType
) => GlobalState

export type GlobalStateActionType =
  | SetHasThreeDStartedAction
  | SetupAction
  | SetApplicationErrorAction
  | SetTokenAction
  | SetTenantSettingsAction
  | SetApartmentInfoAction
  | SetCameraMode
  | OpenPage
  | SetHeader
  | HidePanel
