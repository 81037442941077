import { useCallback, useContext } from "react"
import { selectPackage as apiSelectPackage } from "../axios"
import { GlobalStateContext } from "../store/globalState/GlobalStateProvider"
import { SnackbarStateAction } from "../store/snackbarState/interfaces"
import { SnackbarStateContext } from "../store/snackbarState/SnackbarStateProvider"
import { t } from "../utils"

export const usePackageSelect: () =>
  | ((packageId: string) => Promise<boolean>)
  | null = () => {
  const { globalState } = useContext(GlobalStateContext)
  const { dispatch } = useContext(SnackbarStateContext)

  const selectingAllowed =
    globalState.startParameters.apartmentId &&
    globalState.startParameters.api &&
    globalState.startParameters.company &&
    globalState.startParameters.projectId &&
    globalState.startParameters.schema &&
    globalState.token

  const selectPackage = useCallback(
    async (packageId: string) => {
      try {
        await apiSelectPackage({
          apiUrl: `${globalState.startParameters.api}`,
          organisationId: `${globalState.startParameters.company}`,
          tenantId: `${globalState.startParameters.schema}`,
          projectId: `${globalState.startParameters.projectId}`,
          apartmentId: `${globalState.startParameters.apartmentId}`,
          packageId,
          token: `${globalState.token}`,
        })
        return true
      } catch (e) {
        dispatch({
          type: SnackbarStateAction.SHOW_SNACKBAR_ALERT,
          data: {
            isError: true,
            message: t("error.selectPackageError"),
            time: 5000,
          },
        })
        return false
      }
    },
    [
      globalState.startParameters.api,
      globalState.startParameters.company,
      globalState.token,
      globalState.startParameters.schema,
      globalState.startParameters.projectId,
      globalState.startParameters.apartmentId,
      dispatch,
    ]
  )

  return selectingAllowed ? selectPackage : null
}
