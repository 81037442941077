import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const roomAccordionStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
        padding: theme.spacing(0),
        margin: theme.spacing(0)
    },
    listItem: {
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(0),
        paddingTop: '4px',
    },
    accordion: {
      width: '100%',
      backgroundColor: theme.palette.accordionBackground.default,
      borderStyle: 'none !important',
      boxShadow: 'none !important',
      borderRadius: '10px !important',
      '&:before': {
          display: 'none' // This removes the extra line on top of accordion
        },
        '&.Mui-expanded': {
          //display: 'none', // This removes the extra line on top of accordion
            paddingBottom: theme.spacing(1),
        },
    },
    accordionSummary: {
        backgroundColor: theme.palette.accordionBackground.default,
        margin: theme.spacing(1),
        '& .MuiAccordionSummary-content': {
            marginTop: theme.spacing(1),
            margin: '0'
        }
    },
    accordionHeading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: 'auto',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    grid: {
      flexGrow: 1,
      justifyContent: 'left',
      marginRight: '0'
    },
      chevron: {
          fontSize: '2.5rem',
          position: 'relative',
          top: '50%',
          marginTop: '-1.25rem', //Half of the icon size
          alignContent: 'right',
          color: theme.palette.checkIcons.default
      },
      lockIcon: {
        fontSize: '2.5rem',
        position: 'relative',
        top: '50%',
        paddingTop: theme.spacing(1),
        marginTop: '-1.25rem', //Half of the icon size
        alignContent: 'right',
        color: theme.palette.lockIcon.default,
        textAlign: 'right'
        }
  }),
);

export default roomAccordionStyles