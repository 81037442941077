import React, {JSX, useContext, useEffect, useState} from "react";
import DialogStyles from "./DialogController.styles";
import {DialogStateContext} from "../../store/dialogState/DialogStateProvider";

// Takes JSX elements as dialogs and overlays the entire screen with it. 
// At the moment, the dialog it self handles the closing action
const DialogController: React.FC = () => {
    
    const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);
    const {dialogState} = useContext(DialogStateContext)
    const classes = DialogStyles();
    
    useEffect(() => {
        setDialog(dialogState.customDialog)
    }, [dialogState.customDialog])
    
    return(
        dialog ? <div className={classes.dialogRoot}>
            {dialog}
        </div> : null
    )
    
}

export default DialogController;