import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const decorationPageStyles = makeStyles((theme: Theme) =>{
    return createStyles({
        root:{
            display: 'flex',
            flexDirection: 'column',
            //flex: '1 1 auto',
            
            background: theme.palette.accordionBackground.default,
            border: '1px solid #EAECF0',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderRadius: '8px',
            padding: '6px',
            
            height: '100%',
            maxHeight: `calc(100vh - (2* ${theme.spacing(2)}px + 64px))`,
            
            //overflowY: 'auto',
            overflow:"hidden",
            pointerEvents: 'all'
        },
        content:{
            maxHeight: '100%',
            overflow: 'hidden'
        },
        tabs:{
            width: '100%',
            pointerEvents: 'all'
        },
        menuButton: {
            display: 'flex',
            height: 'fit-content',
            flexDirection: 'row',
            padding: '4px',
            marginRight: '16px',

            width: 'fit-content',
            minWidth: '100px',

            background: 'white',
            border: '1px solid #EAECF0',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderRadius: '8px',

            '&:hover': {
                background: theme.palette.secondary.main,
                color: 'white'//'#667085'
            }
        },
        menuGrid: {
            marginBottom: '16px',
            marginTop: '16px'
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
    })
})

export default decorationPageStyles;