export enum SnackbarStateAction {
  SHOW_SNACKBAR_ALERT = "SHOW_SNACKBAR_ALERT",
  HIDE_SNACKBAR_ALERT = "HIDE_SNACKBAR_ALERT",
  UPDATE_MESSAGE = "UPDATE_MESSAGE"
}

export interface SnackbarOptions {
  message?: string
  isError?: boolean
  time?: number
  dismissible?: boolean
  id: string
}

export interface SnackbarState {
  snackbars: Array<SnackbarOptions>
  errorMessage?: string
  infoMessage?: string
}

export interface ShowSnackbarAlertAction {
  type: SnackbarStateAction.SHOW_SNACKBAR_ALERT
  data: Omit<SnackbarOptions, "id">
}

export interface HideSnackbarAlertAction {
  type: SnackbarStateAction.HIDE_SNACKBAR_ALERT
  data: string
}

export interface UpdateMessageAction {
  type: SnackbarStateAction.UPDATE_MESSAGE
  data: { errorMessage?: string, infoMessage?: string}
}

export type SnackbarStateReducerType = (
  state: SnackbarState,
  action: SnackbarStateActionType
) => SnackbarState

export type SnackbarStateActionType =
  | ShowSnackbarAlertAction
  | HideSnackbarAlertAction
  | UpdateMessageAction
