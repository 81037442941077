import React from 'react';
import {IconButton, Typography, useMediaQuery, useTheme} from '@material-ui/core';
import { languages } from '../../i18n';
import i18next from 'i18next';
import LanguageIcon from '@material-ui/icons/Language';
import { Select } from '@groupbuilderoy/gb-components-library';
import languageSelectStyles from './LanguageSelect.styles';
import useEventLogger from '../../analytics/useEventLogger';

const LanguageSelect: React.FC = () => {
  const { logActions } = useEventLogger();
  const changeLanguage = (code: string) => {
    logActions('language_changed', 'User changed language', 'language_1');
    i18next.changeLanguage(code).then(() => {
      window.location.reload();
    });
  };


  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
    // Icon iconKey={IconKeys.languages}
  const classes = languageSelectStyles();
  return (
    <>
      <Select
        className={classes.languageSelect}
        caption={''}
        onChange={changeLanguage}
        itemList={languages}
        identifierKey={'dateFnsLocaleKey'}
        renderKey={'title'}
        customRenderValue={
          <div className={classes.root}>
            <IconButton className={classes.icon}>
              <LanguageIcon style={{color: 'grey'}} />
            </IconButton>
            {!isSmallScreen ? (
              <Typography className={classes.langName}>
                {languages.find((l) => l.dateFnsLocaleKey === i18next.language)?.title}
              </Typography>
            ) : null}
          </div>
        }
        hideIcon={false}
        initialValue={localStorage.getItem('i18nextLng') || 'en'}
      />
    </>
  );
};

export default LanguageSelect;
