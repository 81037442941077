import React, { Dispatch, useMemo, useReducer } from "react"
import globalStateReducer from "./globalStateReducer"
import initialGlobalState from "./initialGlobalState"
import {
  GlobalStateReducerType,
  GlobalState,
  GlobalStateActionType,
} from "./interfaces"

export const GlobalStateContext = React.createContext<{
  globalState: GlobalState
  dispatch: Dispatch<GlobalStateActionType>
}>({ globalState: initialGlobalState, dispatch: () => initialGlobalState })

const { Provider } = GlobalStateContext

interface GlobalStateProviderProps {
  children: React.ReactNode
}

const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({
  children,
}) => {
  const [globalState, dispatch] = useReducer<GlobalStateReducerType>(
    globalStateReducer,
    initialGlobalState
  )

  const contextValue = useMemo(() => ({ globalState, dispatch }), [
    globalState,
    dispatch,
  ])

  return <Provider value={contextValue}>{children}</Provider>
}

export default GlobalStateProvider
