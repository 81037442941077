import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const movementButtonContainerStyles = makeStyles((theme: Theme) => {
  return createStyles({
    buttonContainer: {
      display: 'inline-flex',
      width: 'calc(3*(70px + 16px))', // amount of buttons * button width + margins
      [theme.breakpoints.down('sm')]: {
        width: 'calc(3*(60px + 4px))', // amount of buttons * button width + margins
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5)
      },
      zIndex: 100
    },
    iconStyle: {
      height: "50px", 
      width: "50px",
      color: 'black',
      [theme.breakpoints.down('sm')]: {
        height: '40px',
        width: '40px'
      }
    },
  })
});

export default movementButtonContainerStyles