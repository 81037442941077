import { Paper } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { StylesState, StylesStateAction } from '../../../store/stylesState/interfaces';
import { StylesStateContext } from '../../../store/stylesState/StylesStateProvider';
import { Package } from '../../../axios/instances/interfaces';
import { usePackageSelect } from '../../../hooks/usePackageSelect';
import { StyleAccordion } from './StyleAccordion';
import useStylesFetcher from '../../../hooks/useStylesFetcher';
import styleCardSelectionMenuStyles from './StyleCardSelectionMenu.styles';
import useEventLogger from '../../../analytics/useEventLogger';

interface StyleCardSelectionMenuProps {
  selectTheme: (themeId: number) => void;
  stylesState: StylesState;
}

/**
 * Primary UI component for user interaction
 */
export const StyleCardSelectionMenu: React.FC<StyleCardSelectionMenuProps> = ({
  selectTheme,
  stylesState
}: StyleCardSelectionMenuProps) => {
  const { logActions } = useEventLogger();
  const classes = styleCardSelectionMenuStyles();
  const apiSelectPackage = usePackageSelect();
  const { dispatch } = useContext(StylesStateContext);
  const { packageSets } = stylesState;
  const { triggerFetchStyles } = useStylesFetcher();
  const [menuLevel, setMenuLevel] = useState(0);
  const [selectedPackageSet, setSelectedPackageSet] = useState<null | number>(null);

  const doSelectPackage = async (theme: Package) => {
    dispatch({ type: StylesStateAction.SELECT_PACKAGE, data: theme });
    theme.canSelect && (await apiSelectPackage?.(theme.id.toString()));
    selectTheme(theme.id);
    logActions('select_theme', 'Theme ' + theme.displayName + ' Selected', 'Theme_1');
    triggerFetchStyles();
  };

  const handleOnChange = (packageSetId: number) => {
    if (packageSetId === selectedPackageSet) setSelectedPackageSet(null);
    else setSelectedPackageSet(packageSetId);
  };

  const handleMenuLevelChange = (level: number) => {
    setMenuLevel(level);
  };

  useEffect(() => {
    if (packageSets?.length && selectedPackageSet === null) {
      setSelectedPackageSet(packageSets?.[0]?.id || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageSets]);

  return (
    <Paper className={classes.paper}>
      {packageSets?.map((packageSet) => {
        const isVisible = packageSet.isSubPackageSet !== 1;
        const isOpen = packageSet.id === selectedPackageSet;

        return isVisible ? (
          <StyleAccordion
            key={packageSet.id}
            {...{
              menuLevel,
              packageSet,
              stylesState,
              isOpen,
              handleOnChange,
              handleMenuLevelChange,
              doSelectPackage
            }}
          />
        ) : null;
      })}
    </Paper>
  );
};
